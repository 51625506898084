export const ArraySchede = [
    {
        "nome": "Comunicazione e Multimedialità",
        "Area": "Scienze della Comunicazione",
        "H1": "Corso di Laurea in Comunicazione e Multimedialità, Università Digitale Mercatorum",
        "tag 1": "Laurea triennale",
        "tag 2": "Durata: 3 anni",
        "tag 3": "180 cfu",
        "tag 4": "Anno accademico 2023/2024",
        "tag 5": "L-20",
        "Descrizione": "La laurea in Comunicazione e Multimedialità (L-20) dell’Università telematica Mercatorum mira a formare professionisti capaci di operare nei vari settori della comunicazione, sia in ambito pubblico che privato. Questo corso combina l'insegnamento delle tecniche di Comunicazione Visiva e Multimediale proprie del marketing digitale , a un approccio umanistico con materie che attengono all'area letteraria e sociologica, offrendo agli studenti una solida preparazione teorica e pratica nel mondo della comunicazione digitale e non.",
        "Piano di studi 1": "Semiotica Generale M-FIL/05\nStoria Contemporanea M-STO/04\nMetodologia della ricerca nella società digitale SPS/07\nSociologia della comunicazione SPS/08\nSemiotica dei Media L-ART/06\nComunicazione interna nell'organizzazione aziendale SECS-P/10\nAbilità informatiche e telematiche INF/01",
        "Piano di studi 2": "Processi economici e produttivi delle Media Company SPS/09\nSearch Engine Optimization ING-INF/05\nTecniche della rappresentazione digitale ICAR/17\nArti visuali e nuove tecnologie rappresentative L-ART/04\nTecnologie digitali e processi cognitivi M-PED/04\nNarratologia e storytelling L-FIL-LET/14\nCommunity management M-PSI/07",
        "Piano di studi 3": "Audiovisivi digitali L-ART/06\nElementi di Marketing e Digital Advertising Strategy SECS-P/08\nDigital and Social Media Management SPS/08\nDiritto pubblico, dell'informazione e della comunicazione IUS/09\nInsegnamento a scelta\nUlteriori conoscenze linguistiche\nPer la conoscenza di almeno una lingua straniera\nProva Finale",
        "operatore 1": "Mercatorum",
        "operatore 2": "Comparacorsi",
        "operatore 3": "Altri operatori",
        "prezzo 1": "3.400€",
        "prezzo 2": "1.700€",
        "prezzo 3": "4.000€",
        "Domanda 1": "Qual è l'obiettivo del corso?",
        "Risposta 1": "Il corso mira a fornire una solida preparazione culturale e padronanza dei metodi di analisi delle informazioni e dei dati nell'ambito della comunicazione, con particolare attenzione ai nuovi media e alla digitalizzazione dei processi comunicativi.",
        "Domanda 2": "Quali sono le opportunità lavorative dopo il completamento del corso?",
        "Risposta 2": "I laureati possono lavorare in diversi settori della comunicazione, sia in ambito pubblico che privato, includendo ruoli come responsabili della comunicazione, digital media strategists, e posizioni in aziende, editorie, agenzie creative, e media.",
        "Domanda 3": "Quali sono i requisiti di ammissione?",
        "Risposta 3": "È richiesto un diploma di scuola media superiore o un titolo equivalente, un'adeguata preparazione di base in lingua italiana, una buona conoscenza della cultura generale e conoscenze di base di una seconda lingua dell'Unione Europea.",
        "Domanda 4": "Qual è la modalità di erogazione delle lezioni?",
        "Risposta 4": "Le lezioni del corso in Comunicazione e Multimedialità sono erogate online, permettendo agli studenti di accedere al materiale didattico e partecipare alle attività formative in modo flessibile, adattandosi alle loro esigenze di tempo e luogo. Questo include lezioni in video, materiali di studio, e la possibilità di interagire con docenti e compagni di corso attraverso piattaforme digitali.",
        "Domanda 5": "Qual è la durata e la struttura del corso?",
        "Risposta 5": "Il corso ha una durata di 3 anni e include insegnamenti teorici e pratici in ambiti come la semiotica, la sociologia della comunicazione, le tecnologie digitali, e il digital marketing.",
        "Sbocchi": [
            "Specialista in Comunicazione Digitale",
            "Produttore Multimediale",
            "Social Media Manager",
            "SEO Specialist",
            "Giornalista Digitale",
            "Copywriter",
            "Stratega di Contenuto",
            "Analista di Dati e Metriche Digitali",
            "Responsabile PR Digitali"
        ]
    },
    {
        "nome": "Scienze della Comunicazione (Digital Entertainment and Gaming)",
        "Area": "Scienze della Comunicazione",
        "H1": "Corso di Laurea in Digital Entertainment and Gaming, Università Digitale eCampus",
        "tag 1": "Laurea triennale",
        "tag 2": "Durata: 3 anni",
        "tag 3": "180 cfu",
        "tag 4": "Anno accademico 2023/2024",
        "tag 5": "L-20",
        "Descrizione": "L'università digitale eCampus propone un affascinante corso di laurea triennale in Digital Entertainment and Gaming. Questo corso si concentra sulla comprensione, l'analisi e l'utilizzo degli strumenti di comunicazione moderna, enfatizzando aree come la pubblicità, i multimedia , il marketing, la comunicazione d'impresa e l'e-commerce. A differenza di altri programmi legati al mondo digitale, la sua unicità risiede nell'intenso focus sul digital entertainment, il gaming e l'industria dei videogiochi, integrando competenze come il game development, il game design e la gamification.",
        "Piano di studi 1": "Semiotica e filosofia dei linguaggi, Informatica, Organizzazione aziendale, Sociologia dei processi economici, Tecnica, storia e linguaggio dei mezzi audiovisivi, Estetica della comunicazione",
        "Piano di studi 2": "Diritto dell'informazione e della comunicazione, Metodologia della ricerca sociale, Lingua inglese, Sociologia della comunicazione, Psicologia della moda, Sociologia della moda, Progettazione, processi e comportamenti organizzativi",
        "Piano di studi 3": "Etica della comunicazione, Linguaggi dei nuovi media, Lingua spagnola, Laboratori di scrittura istituzionale, pubblicitaria e lettura dell'immagine, Materie a scelta dello studente, Tirocini formativi e di orientamento",
        "operatore 1": "eCampus",
        "operatore 2": "Comparacorsi",
        "operatore 3": "Altri operatori",
        "prezzo 1": "5.900€",
        "prezzo 2": "2.600€",
        "prezzo 3": "6.000€",
        "Domanda 1": "Qual è l'obiettivo principale del corso di laurea in Digital Entertainment and Gaming all'università eCampus?",
        "Risposta 1": "Il corso mira a formare professionisti nel campo del digital marketing e dell'intrattenimento online, con un focus particolare sul digital entertainment, il gaming e l'industria dei videogiochi",
        "Domanda 2": "Quali materie sono incluse nel piano di studi di questo corso di laurea?",
        "Risposta 2": "Il piano di studi include materie come Semiotica, Informatica, Organizzazione aziendale, Diritto dell'informazione, Psicologia della moda, Linguaggi dei nuovi media, e molte altre, oltre a laboratori e materie a scelta dello studente.",
        "Domanda 3": "Che tipo di competenze pratiche svilupperanno gli studenti in questo corso?",
        "Risposta 3": "Gli studenti acquisiranno competenze in aree come SEO, branding digitale, email marketing, content marketing, web analytics, mobile marketing, oltre a strategie di marketing online e analisi dati",
        "Domanda 4": "Quali sono i principali sbocchi lavorativi dopo aver completato questo corso?",
        "Risposta 4": "I laureati possono lavorare nel settore del digital entertainment and gaming, e-commerce, social media, video marketing, e mobile marketing, con ruoli che variano dal game design al branding digitale e alla gestione dei social media.",
        "Domanda 5": "Quali sono i requisiti di ammissione per il corso di laurea in Digital Entertainment and Gaming all'università eCampus?",
        "Risposta 5": "Gli studenti devono avere un diploma di scuola secondaria superiore o un titolo equivalente ottenuto all'estero. È inoltre essenziale avere una buona conoscenza della lingua italiana e dell'inglese. La valutazione per l'ammissione avviene tramite un test d'ingresso.",
        "Sbocchi": [
            "Analista dati per videogiochi",
            "Esperto di branding digitale per giochi e piattaforme",
            "Specialista in SEO localizzato",
            "Specialista in email marketing",
            "Specialista in content marketing",
            "Specialista in conversioni e ROI",
            "Social media manager",
            "Influencer marketing",
            "Sviluppatore di campagne di storytelling digitale",
            "Esperto di mobile marketing"
        ]
    },
    {
        "nome": "Scienze della Comunicazione (Influencer)",
        "Area": "Scienze della Comunicazione",
        "H1": "Corso di Laurea in Influencer, Università Digitale eCampus",
        "tag 1": "Laurea triennale",
        "tag 2": "Durata: 3 anni",
        "tag 3": "180 cfu",
        "tag 4": "Anno accademico 2023/2024",
        "tag 5": "L-20",
        "Descrizione": "Nell'era del marketing digitale, dove i social media regnano sovrani, nasce un innovativo corso universitario dedicato all'influencer presso l'ateneo eCampus. Questa laurea triennale in Scienze della Comunicazione si focalizza sull'importanza crescente del ruolo dell'influencer nella comunicazione online. L'influencer è un leader d'opinione che può diventare ambasciatore di se stesso e dei brand con cui collabora. Gli influencer, con il loro forte personal branding, hanno il potere di influenzare le decisioni d'acquisto e le opinioni del loro pubblico attraverso strategie di influencer ben studiate.",
        "Piano di studi 1": "Semiotica e filosofia dei linguaggi\nInformatica\nEstetica della comunicazione\nSociologia dei processi economici\nTecnica, storia e linguaggio dei mezzi audiovisivi\nOrganizzazione aziendale",
        "Piano di studi 2": "Diritto dell'informazione e della comunicazione\nMetodologia della ricerca sociale\nLingua inglese\nSociologia della comunicazione e dell'informazione\nPsicologia della moda\nSociologia della moda\nProgettazione, processi e comportamenti organizzativi\nA scelta dello studente",
        "Piano di studi 3": "Linguaggi dei nuovi media\nEtica della comunicazione\nSocial media marketing\nLingua spagnola\nLaboratorio di scrittura istituzionale e pubblicitaria\nLaboratorio di scrittura\nLaboratorio di lettura dell'immagine\nA scelta dello studente\nTirocini formativi e di orientamento\nProva finale",
        "operatore 1": "eCampus",
        "operatore 2": "Comparacorsi",
        "operatore 3": "Altri operatori",
        "prezzo 1": "5.900€",
        "prezzo 2": "2.600€",
        "prezzo 3": "6.000€",
        "Domanda 1": "Qual è l'obiettivo del corso di laurea in Influencer presso l'Università eCampus?",
        "Risposta 1": "Il corso si concentra sulla formazione di influencer competenti nel marketing digitale, con un'enfasi sul personal branding, la gestione dei social media e la creazione di contenuti efficaci.",
        "Domanda 2": "Quali materie sono incluse nel piano di studi?",
        "Risposta 2": "Il piano di studi comprende Semiotica, Informatica, Estetica della comunicazione, Diritto dell'informazione, Psicologia e Sociologia della moda, Social media marketing, tra gli altri.",
        "Domanda 3": "Quali competenze pratiche acquisiranno gli studenti?",
        "Risposta 3": "Gli studenti acquisiranno competenze in gestione dei social media, creazione di contenuti, pubblicità digitale, analisi delle tendenze, e strategie di influencer marketing.",
        "Domanda 4": "Quali opportunità lavorative sono disponibili per i laureati in questo corso?",
        "Risposta 4": "I laureati possono lavorare come tecnici del marketing, specialisti della pubblicità, professionisti nell'editoria e nell'entertainment, esperti di social media marketing e collaborazioni influencer.",
        "Domanda 5": "Quali sono i requisiti di ammissione?",
        "Risposta 5": "È richiesto un diploma di scuola secondaria superiore o equivalente, una buona conoscenza dell'italiano e dell'inglese.",
        "Sbocchi": [
            "Tecnici del Marketing",
            "Specialisti della Pubblicità",
            "Professionisti dell'Editoria",
            "Esperti nell'Entertainment",
            "Esperti di Social Media Marketing",
            "Gestori di Collaborazioni Influencer"
        ]
    },
    {
        "nome": "Scienze della Comunicazione (Istituzionale e d'impresa)",
        "Area": "Scienze della Comunicazione",
        "H1": "Corso di Laurea in Comunicazione Istituzionale e d'Impresa, Università Digitale eCampus",
        "tag 1": "Laurea triennale",
        "tag 2": "Durata: 3 anni",
        "tag 3": "180 cfu",
        "tag 4": "Anno accademico 2023/2024",
        "tag 5": "L-20",
        "Descrizione": "Il Corso Universitario in 'comunicazione istituzionale e d'impresa' offerto dall'Ateneo eCampus è un viaggio attraverso il vasto mondo della comunicazione contemporanea. Questo programma, parte integrante delle Scienze della comunicazione, tratta aspetti fondamentali delle telecomunicazioni, dei media e, ovviamente, della comunicazione istituzionale e d'impresa. Con una durata di tre anni, al suo completamento, gli studenti saranno preparati a comprendere le sfide aziendali, giuridico-istituzionali e sociali legate alla comunicazione istituzionale e d'impresa.",
        "Piano di studi 1": "Semiotica e filosofia dei linguaggi\nInformatica\nOrganizzazione aziendale\nSociologia dei processi economici\nTecnica, storia e linguaggio dei mezzi audiovisivi\nEstetica della comunicazione",
        "Piano di studi 2": "Sociologia della comunicazione e dell'informazione\nDiritto dell'informazione e della comunicazione\nSociologia urbana, del turismo e del territorio\nLingua inglese\nMetodologia della ricerca sociale\nProgettazione, processi e comportamenti organizzativi\nA scelta dello studente",
        "Piano di studi 3": "Analisi del linguaggio politico\nEtica della comunicazione\nLinguaggi dei nuovi media\nLingua spagnola\nLaboratorio di scrittura istituzionale e pubblicitaria\nLaboratorio di scrittura\nLaboratorio di lettura dell'immagine\nA scelta dello studente\nTirocini formativi e di orientamento\nProva finale",
        "operatore 1": "eCampus",
        "operatore 2": "Comparacorsi",
        "operatore 3": "Altri operatori",
        "prezzo 1": "5.900€",
        "prezzo 2": "2.600€",
        "prezzo 3": "6.000€",
        "Domanda 1": "Qual è l'obiettivo del corso di laurea in Comunicazione Istituzionale e d'Impresa offerto da eCampus?",
        "Risposta 1": "Il corso mira a preparare gli studenti a comprendere e gestire le sfide della comunicazione istituzionale e d'impresa, con un focus sulle telecomunicazioni, i media e le dinamiche aziendali e istituzionali.",
        "Domanda 2": "Quali materie sono incluse nel piano di studi di questo corso?",
        "Risposta 2": "Il piano di studi comprende Semiotica, Informatica, Organizzazione aziendale, Sociologia della comunicazione, Diritto dell'informazione, Etica della comunicazione, e molte altre, oltre a laboratori pratici.",
        "Domanda 3": "Che tipo di approccio e strumenti pratici offre questo corso?",
        "Risposta 3": "Il corso adotta un approccio multidisciplinare, combinando discipline semiotiche, linguistiche, informatiche e sociologiche, garantendo agli studenti gli strumenti pratici necessari per eccellere nel settore della comunicazione.",
        "Domanda 4": "Quali opportunità lavorative sono disponibili per i laureati in questo corso?",
        "Risposta 4": "I laureati possono intraprendere carriere nel marketing, nella pubblicità, nelle pubbliche relazioni, nell'organizzazione di eventi culturali, nella comunicazione d'impresa, nell'editoria e nel giornalismo.",
        "Domanda 5": "Quali sono i requisiti di ammissione per il corso?",
        "Risposta 5": "È necessario un diploma di scuola secondaria superiore o un titolo equivalente riconosciuto.",
        "Sbocchi": [
            "Tecnici del Marketing",
            "Specialisti della Pubblicità",
            "Professionisti delle Pubbliche Relazioni",
            "Organizzatori di Eventi Culturali",
            "Esperti in Comunicazione d'Impresa",
            "Professionisti nell'Editoria Tradizionale e Multimediale",
            "Giornalisti Pubblicisti"
        ]
    },
    {
        "nome": "Scienze della Comunicazione (Digital marketing)",
        "Area": "Scienze della Comunicazione",
        "H1": "Corso di Laurea in Digital Marketing, Università Telematica eCampus",
        "tag 1": "Laurea triennale",
        "tag 2": "Durata: 3 anni",
        "tag 3": "180 cfu",
        "tag 4": "Anno accademico 2023/2024",
        "tag 5": "L-20",
        "Descrizione": "Il digital marketing è diventato un pilastro fondamentale per le imprese. L’Università eCampus offre una laurea triennale online in Scienze della Comunicazione con un particolare focus sul Digital Marketing. Questo corso, inserito nella Classe di Laurea L-20, ha come obiettivo primario la formazione di studenti nelle dinamiche aziendali, giuridico-istituzionali e sociali, dotandoli delle competenze necessarie per operare efficacemente nel mondo della comunicazione online.",
        "Piano di studi 1": "Semiotica e filosofia dei linguaggi\nInformatica, Organizzazione aziendale\nSociologia dei processi economici\nTecnica, storia e linguaggio dei mezzi audiovisivi\nEstetica della comunicazione",
        "Piano di studi 2": "Tecniche di marketing digitale\nMarketing digitale avanzato\nDiritto dell'informazione e della comunicazione\nMetodologia della ricerca sociale\nSociologia della comunicazione e dell'informazione\nLingua inglese\nA scelta dello studente",
        "Piano di studi 3": "Etica della comunicazione\nComunicazione 2.0\nLinguaggi dei nuovi media\nLingua spagnola\nLaboratorio di scrittura istituzionale e pubblicitaria\nLaboratorio di scrittura\nLaboratorio di lettura dell'immagine\nA scelta dello studente\nTirocini formativi e di orientamento\nProva finale",
        "operatore 1": "eCampus",
        "operatore 2": "Comparacorsi",
        "operatore 3": "Altri operatori",
        "prezzo 1": "5.900€",
        "prezzo 2": "2.600€",
        "prezzo 3": "6.000€",
        "Domanda 1": "Qual è l'obiettivo principale del corso di laurea triennale in Digital Marketing all'Università eCampus?",
        "Risposta 1": "Il corso mira a formare studenti nelle dinamiche aziendali, giuridico-istituzionali e sociali del digital marketing, fornendo le competenze per operare efficacemente nella comunicazione online.",
        "Domanda 2": "Quali sono i contenuti del piano di studi in Digital Marketing?",
        "Risposta 2": "Il piano di studi include corsi come Semiotica, Informatica, Tecniche di marketing digitale, Diritto dell'informazione, Sociologia della comunicazione, Etica della comunicazione, e molti altri, oltre a laboratori pratici.",
        "Domanda 3": "Che tipo di approccio e strumenti pratici offre questo corso?",
        "Risposta 3": "Gli studenti imparano attraverso un approccio pratico, padroneggiando le strategie di marketing online, digital advertising, marketing sui motori di ricerca, social media marketing e web analytics.",
        "Domanda 4": "Quali opportunità lavorative sono disponibili per i laureati in questo corso?",
        "Risposta 4": "I laureati possono intraprendere carriere come Digital Marketing Specialist, SEO Manager, Content Strategist, Social Media Manager, E-mail Marketing Specialist, Web/Data Analyst, E-commerce Manager, Brand Manager Digital, Digital PR Specialist e Affiliate Marketing Manager.",
        "Domanda 5": "Quali sono i requisiti di ammissione?",
        "Risposta 5": "È richiesto un diploma di scuola secondaria superiore o equivalente e una buona conoscenza dell'italiano e dell'inglese.",
        "Sbocchi": [
            "Digital Marketing Specialist",
            "SEO Manager",
            "Content Strategist",
            "Social Media Manager",
            "E-mail Marketing Specialist",
            "Web/Data Analyst",
            "E-commerce Manager",
            "Brand Manager Digital",
            "Digital PR Specialist",
            "Affiliate Marketing Manager"
        ]
    },
    {
        "nome": "Scienze della Comunicazione (Comunicazione Digitale d'Impresa)",
        "Area": "Scienze della Comunicazione",
        "H1": "Corso di Laurea in Comunicazione Digitale D'Impresa, Università Digitale Uninettuno",
        "tag 1": "Laurea triennale",
        "tag 2": "Durata: 3 anni",
        "tag 3": "180 cfu",
        "tag 4": "Anno accademico 2023/2024",
        "tag 5": "L-20",
        "Descrizione": "L'Università Uninettuno ha compreso l'importanza di preparare i futuri professionisti a questa rivoluzione con il corso di laurea in Scienze della Comunicazione. Il cambiamento radicale nel campo della comunicazione, accelerato da fenomeni come internet, i social media, la digitalizzazione e la globalizzazione, richiede competenze avanzate. L’approccio multidisciplinare proposto dall'Università Uninettuno combina diverse discipline, dalla sociologia alle materie umanistiche e massmediologiche, garantendo una formazione completa e versatile.",
        "Piano di studi 1": "Sociologia della comunicazione (9 cfu)\nComunicazione istituzionale nell'era digitale (10 cfu)\nSemiotica generale (9 cfu)\nIstituzioni di storia contemporanea (9 cfu)\nArti visuali e tecnologie (12 cfu)\nLetterature e transculturalità (12 cfu)\nLingua inglese (6 cfu)",
        "Piano di studi 2": "Visual Storytelling (8 cfu)\nComunicazione d’impresa nel mondo digitale (8 cfu)\nPsicologia dei consumi (8 cfu)\nMetodologia della ricerca nella società digitale (9 cfu)\nElementi di marketing (8 cfu)\nSearch Engine e Social Media Marketing (9 cfu)\nBrand Design (8 cfu)\nInsegnamento a scelta (6 cfu)",
        "Piano di studi 3": "Digital Advertising Strategy (6 cfu)\nTecnologie digitali e processi cognitivi (6 cfu)\nProcessi economici e produttivi delle Media Company (6 cfu)\nProcessi di Lobbying e Public Affairs (9 cfu)\nDiritto dei media digitali e delle istituzioni (6 cfu)\nLaboratori (4 cfu)\nWorkshop sulle professioni della comunicazione digitale\nLaboratorio di scrittura\nProva finale (6 cfu)",
        "operatore 1": "Uninettuno",
        "operatore 2": "Comparacorsi",
        "operatore 3": "Altri operatori",
        "prezzo 1": "2.000€",
        "prezzo 2": "1.900€",
        "prezzo 3": "3.000€",
        "Domanda 1": "Qual è l'obiettivo del corso di laurea in Comunicazione Digitale d'Impresa presso Uninettuno?",
        "Risposta 1": "Il corso mira a preparare i futuri professionisti alle dinamiche della comunicazione digitale nel settore aziendale, con un focus su strategie di marketing digitale e nuovi media.",
        "Domanda 2": "Quali sono i contenuti del piano di studi?",
        "Risposta 2": "Il piano di studi include corsi come Sociologia della comunicazione, Semiotica, Arti visuali e tecnologie, Visual Storytelling, Comunicazione d’impresa nel mondo digitale, Psicologia dei consumi, Brand Design, Digital Advertising Strategy, e altri.",
        "Domanda 3": "Che tipo di approccio e strumenti pratici offre questo corso?",
        "Risposta 3": "Il corso offre un approccio multidisciplinare, combinando materie di comunicazione, scienze umane, economico-sociali e marketing digitale, oltre a laboratori pratici e workshop.",
        "Domanda 4": "Quali opportunità lavorative sono disponibili per i laureati in questo corso?",
        "Risposta 4": "I laureati possono intraprendere carriere in aziende nel marketing e nel branding, nell'industria dello spettacolo, nell'editoria, e in professioni come redattori di contenuti, creatori di pubblicità, brand content manager, social media manager e marketing specialist.",
        "Domanda 5": "Quali sono i requisiti di ammissione per il corso?",
        "Risposta 5": "È necessario un diploma di scuola secondaria superiore o un titolo equivalente riconosciuto.",
        "Sbocchi": [
            "Redattore di Contenuti per i Media",
            "Creatore di Contenuti Televisivi",
            "Creatore di Pubblicità",
            "Brand Content Manager",
            "Social Media Manager",
            "Marketing Specialist"
        ]
    },
    {
        "nome": "Scienze della Comunicazione (Istituzioni Pubbliche e Media Digitali)",
        "Area": "Scienze della Comunicazione",
        "H1": "Corso di Laurea in Istituzioni Pubbliche e Media Digitali, Università Digitale Uninettuno",
        "tag 1": "Laurea triennale",
        "tag 2": "Durata: 3 anni",
        "tag 3": "180 cfu",
        "tag 4": "Anno accademico 2023/2024",
        "tag 5": "L-20",
        "Descrizione": "L'obiettivo formativo del corso di laurea in Scienze della Comunicazione di Uninettuno è fornire competenze solide per navigare nel complicato mondo della Comunicazione Istituzionale e dei Nuovi Media. Questo settore ha subito una rivoluzione grazie a fenomeni come internet, la digitalizzazione, i social media e la globalizzazione. Tra gli indirizzi proposti, 'Istituzioni pubbliche e media digitali' emerge come un percorso studiato appositamente per formare professionisti in grado di padroneggiare la Comunicazione Digitale nell'ambito delle Istituzioni Pubbliche e dell'Innovazione Tecnologica.",
        "Piano di studi 1": "Sociologia della comunicazione (9cfu)\nComunicazione istituzionale nell'era digitale (10 cfu)\nSemiotica generale (9 cfu)\nIstituzioni di storia contemporanea (9 cfu)\nArti visuali e tecnologie (12 cfu)\nLetterature e transculturalità (12 cfu)\nLingua inglese (6 cfu)",
        "Piano di studi 2": "Semiotica dei media (12 cfu)\nMetodologia della ricerca nella società digitale (9 cfu)\nSociologia dei fenomeni politici (6 cfu)\nDiritto dei media digitali e delle istituzioni (6 cfu)\nTecnologie digitali e processi cognitivi (10 cfu)\nProcessi di Lobbying e Public Affairs (6 cfu)",
        "Piano di studi 3": "Digital and Social Media Management (9 cfu)\nDigital & Open Government (9 cfu)\nInformation Architecture (12 cfu)\nValorizzazione e narrazione del patrimonio culturale (12 cfu)\nLaboratori (4 cfu)\nWorkshop sulle professioni della comunicazione digitale\nLaboratorio di scrittura\nProva finale (6 cfu)",
        "operatore 1": "Uninettuno",
        "operatore 2": "Comparacorsi",
        "operatore 3": "Altri operatori",
        "prezzo 1": "2.000€",
        "prezzo 2": "1.900€",
        "prezzo 3": "3.000€",
        "Domanda 1": "Qual è l'obiettivo del corso di laurea in 'Scienze della Comunicazione - Istituzioni pubbliche e media digitali' di Uninettuno?",
        "Risposta 1": "Il corso mira a fornire competenze nelle dinamiche della Comunicazione Istituzionale e dei Media Digitali, preparando professionisti capaci di operare efficacemente nell'era digitale.",
        "Domanda 2": "Quali sono i contenuti del piano di studi di questo corso?",
        "Risposta 2": "Il piano di studi include corsi come Sociologia della comunicazione, Comunicazione istituzionale nell'era digitale, Semiotica, Arti visuali e tecnologie, Semiotica dei media, Sociologia dei fenomeni politici, Digital and Social Media Management, tra gli altri.",
        "Domanda 3": "Qual è l'elemento differenziante di questo corso di laurea online?",
        "Risposta 3": "Il corso offre un approccio multidisciplinare che combina sociologia, discipline umanistiche, tecnologie digitali, E-government, Open Data e partecipazione civica, preparando gli studenti a comprendere il legame tra tecnologia, pubblica amministrazione e società.",
        "Domanda 4": "Quali opportunità lavorative sono disponibili per i laureati in questo corso?",
        "Risposta 4": "I laureati possono lavorare in agenzie di comunicazione, enti pubblici e privati, editori, testate giornalistiche, e in ruoli come redattore di contenuti per i media, social media manager, marketing specialist, esperti in politiche pubbliche e E-government.",
        "Domanda 5": "Quali sono i requisiti di ammissione per il corso?",
        "Risposta 5": "È necessario un diploma di scuola secondaria superiore o un titolo equivalente riconosciuto.",
        "Sbocchi": [
            "Esperti in Comunicazione Istituzionale",
            "Specialisti dei Media Digitali",
            "Redattori di Contenuti per i Media",
            "Social Media Manager",
            "Marketing Specialist",
            "Esperti in Politiche Pubbliche",
            "Professionisti dell'E-government"
        ]
    },
    {
        "nome": "Comunicazione Innovativa, Multimediale e Digitale",
        "Area": "Scienze della Comunicazione",
        "H1": "Corso di Laurea in Comunicazione Innovativa, Multimediale e Digitale, Università Digitale IUL",
        "tag 1": "Laurea triennale",
        "tag 2": "Durata: 3 anni",
        "tag 3": "180 cfu",
        "tag 4": "Anno accademico 2023/2024",
        "tag 5": "L-20",
        "Descrizione": "Il corso è fortemente ancorato alla mission di formare figure professionali adatte a navigare il mondo dei media digitali, dell'informazione e dell'industria culturale. Questo programma è disegnato per fornire agli studenti le competenze necessarie per operare efficacemente in un ambiente che vede una crescente influenza della tecnologia. Focalizzandosi sui nuovi media e sulle emergenti forme di comunicazione online, il corso non solo offre strumenti per analizzare i vari fenomeni comunicativi, ma prepara gli studenti all'utilizzo delle tecnologie informatiche nel dominio della comunicazione digitale e multimediale.",
        "Piano di studi 1": "Teorie della comunicazione e dei media digitali (9 cfu)\nFondamenti di scienze sociali (9 cfu)\nStoria politica sociale e culturale dell’età contemporanea (6 cfu)\nPsicologia sociale della comunicazione interculturale (9 cfu)\nInformatica e tecnologie della comunicazione digitale (9 cfu)\nComunicazione verbale e linguaggio dei Media (6 cfu)\nLaboratorio informatico (6 cfu)\nLaboratorio di lingua inglese (6 cfu)",
        "Piano di studi 2": "Sociologia degli ecosistemi (6 cfu)\nMetodi di ricerca per la comunicazione (9 cfu)\nEstetica dei linguaggi audiovisivi (9 cfu)\nFondamenti di Economia Politica (6 cfu)\nLaboratorio di tecniche di scrittura e linguaggi del giornalismo (9 cfu)\nTecnologie della comunicazione per il web (6 cfu)\nFilosofia e scienza politica (9 cfu)\nNuovi media nei contesti di apprendimento (9 cfu)",
        "Piano di studi 3": "Diritto e proprietà intellettuale nella società dell’informazione (9 cfu)\nSemiotica del Branding e della pubblicità (9 cfu)\nStrumenti di comunicazione per l’e-learning (6 cfu)\nTecniche e strategie di comunicazione per il marketing (6 cfu)\nStrumenti di valutazione dell’impatto di beni e servizi (6 cfu)\nEsami a scelta (18 cfu)\nProva finale (3 cfu)",
        "operatore 1": "IUL",
        "operatore 2": "Comparacorsi",
        "operatore 3": "Altri operatori",
        "prezzo 1": "3.000€",
        "prezzo 2": "2.500€",
        "prezzo 3": "3.000€",
        "Domanda 1": "Qual è l'obiettivo del corso di laurea in 'Comunicazione Innovativa, Multimediale e Digitale' all’Università IUL?",
        "Risposta 1": "Il corso mira a formare professionisti qualificati nel mondo dei media digitali, dell'informazione e dell'industria culturale, con un forte focus sulla comunicazione innovativa e tecnologica.",
        "Domanda 2": "Quali sono i contenuti del piano di studi di questo corso?",
        "Risposta 2": "Il piano di studi include corsi come Teorie della comunicazione e dei media digitali, Psicologia sociale della comunicazione interculturale, Sociologia degli ecosistemi, Estetica dei linguaggi audiovisivi, Tecniche e strategie di comunicazione per il marketing, tra gli altri.",
        "Domanda 3": "Che tipo di approccio e strumenti pratici offre questo corso?",
        "Risposta 3": "Il corso combina conoscenze teoriche con esperienze pratiche, attraverso laboratori di tecniche giornalistiche, informatica, web design, e progetti di gruppo, preparando gli studenti a navigare efficacemente nel mondo digitale.",
        "Domanda 4": "Quali opportunità lavorative sono disponibili per i laureati in questo corso?",
        "Risposta 4": "I laureati possono trovare opportunità in settori come pubblicità, informazione, editoria, comunicazione educativa, lavorando come giornalisti, specialisti di uffici stampa, professionisti in agenzie pubblicitarie, o nel settore della comunicazione educativa.",
        "Domanda 5": "Quali sono i requisiti di ammissione per il corso?",
        "Risposta 5": "È necessario un diploma di scuola secondaria superiore o un titolo equivalente riconosciuto.",
        "Sbocchi": [
            "Giornalisti (tradizionali e online)",
            "Specialisti di Uffici stampa",
            "Professionisti in Agenzie Pubblicitarie",
            "Esperti di Produzione Multimediale",
            "Professionisti nel Settore della Comunicazione Educativa"
        ]
    },
    {
        "nome": "Scienze e Tecniche Psicologiche",
        "Area": "Psicologia",
        "H1": "Corso di Laurea in Tecniche e Scienze Psicologiche, Università Digitale Unifortunato",
        "tag 1": "Laurea triennale",
        "tag 2": "Durata: 3 anni",
        "tag 3": "180 cfu",
        "tag 4": "Anno accademico 2023/2024",
        "tag 5": "L-24",
        "Descrizione": "Il Corso di Laurea online in 'Psicologia, Scienze e Tecniche psicologiche' (L-24) dell'Università telematica Unifortunato è ideale per chi cerca una laurea triennale flessibile e accessibile. Il programma di studi di tre anni (180 CFU) mira a fornire competenze metodologiche essenziali nello studio dei processi psicologici e delle scienze umane, preparando gli studenti a operare in diversi contesti (sociali, organizzativi, educativi e clinici). Attraverso la piattaforma e-learning di Unifortunato, gli studenti possono seguire le lezioni gestendo i propri tempi di studio in autonomia. La laurea in Psicologia, Scienze e Tecniche psicologiche apre la strada a diverse opportunità lavorative nei servizi psicologici e in altri ambiti correlati.",
        "Piano di studi 1": "Psicologia generale (9 cfu), Psicologia dello sviluppo (9 cfu), Psicometria (9 cfu), Fondamenti anatomo-fisiologici dell’attività psichica (9 cfu), Fisiologia del comportamento (9 cfu), Antropologia culturale (9 cfu), Lingua inglese (6 cfu)",
        "Piano di studi 2": "Psicobiologia (9 cfu), Psicologia sociale (9 cfu), Psicologia dinamica (9 cfu), Psicologia del lavoro (9 cfu), Psicologia della personalità (9 cfu), Psicologia della percezione (9 cfu), Materia a scelta dello studente (6 cfu)",
        "Piano di studi 3": "Sociologia generale (9 cfu), Informatica (5 cfu), Psicologia clinica (9 cfu), Psicologia della salute e del benessere (9 cfu), Teorie e tecniche del colloquio (9 cfu), Materia a scelta dello studente (6 cfu), Tirocinio (10 cfu), Prova finale (3 cfu)",
        "operatore 1": "Unifortunato",
        "operatore 2": "Comparacorsi",
        "operatore 3": "Altri operatori",
        "prezzo 1": "3.600€",
        "prezzo 2": "3.000€",
        "prezzo 3": "4.100€",
        "Domanda 1": "Che tipo di competenze si acquisiscono nel Corso di Laurea in 'Psicologia, Scienze e Tecniche psicologiche' di Unifortunato?",
        "Risposta 1": "Gli studenti acquisiscono competenze metodologiche essenziali per lo studio dei processi psicologici e delle scienze umane. Il corso copre aspetti della valutazione psicologica, analisi di contesti lavorativi e sociali, progettazione di interventi psicologici, e sviluppo di metodologie di osservazione. Si enfatizza l'uso di strumenti testologici e relazionali per la valutazione.",
        "Domanda 2": "Come viene erogata la didattica nel corso di laurea online di Unifortunato?",
        "Risposta 2": "La didattica è erogata principalmente tramite una piattaforma e-learning che consente agli studenti di seguire lezioni in modalità telematica e studiare a proprio ritmo. Il corso include anche workshop pratici e attività interattive, con esami che possono essere sostenuti nelle sedi di Unifortunato sparse in tutta Italia.",
        "Domanda 3": "Quali sono gli sbocchi lavorativi per i laureati in questo corso?",
        "Risposta 3": "I laureati possono operare in vari ambiti come i servizi psicologici, la valutazione del personale, i consultori, la gestione dei conflitti e la promozione della salute. Altri settori includono la Sanità pubblica e privata, e la psicologia scolastica e organizzativa. La laurea abilita anche all’iscrizione all’Albo B degli Psicologi.",
        "Domanda 4": "Il corso offre opportunità di tirocinio o esperienza pratica?",
        "Risposta 4": "Sì, il corso include un tirocinio che consente agli studenti di acquisire competenze pratiche. Queste attività di tirocinio si concentrano su metodi empirici in psicologia, valutazione psicologica e principali forme di intervento, fornendo esperienze preziose nei contesti sociali e lavorativi reali.",
        "Domanda 5": "Come si può accedere al Corso di Laurea in 'Psicologia, Scienze e Tecniche psicologiche' di Unifortunato?",
        "Risposta 5": "Per iscriversi, è necessario possedere un diploma di scuola superiore o un titolo equipollente anche conseguito all’estero. Non è previsto un test di ingresso ostativo, rendendo il corso accessibile a chiunque abbia interesse e la preparazione adeguata per intraprendere studi in ambito psicologico.",
        "Sbocchi": [
            "Assistente di Ricerca in Psicologia",
            "Operatore in Servizi di Neuropsichiatria Infantile",
            "Tecnico di Selezione e Formazione del Personale",
            "Consulente per la Promozione del Benessere in Aziende",
            "Assistente in Strutture per l'Assistenza ai Disabili",
            "Educatore in Programmi di Prevenzione della Salute Mentale",
            "Addetto alla Gestione di Conflitti e Mediazione nelle Organizzazioni"
        ]
    },
    {
        "nome": "Scienze e Tecniche Psicologiche",
        "Area": "Psicologia",
        "H1": "Corso di Laurea in Scienze e Tecniche Psicologiche, Università Digitale Unimarconi",
        "tag 1": "Laurea triennale",
        "tag 2": "Durata: 3 anni",
        "tag 3": "180 cfu",
        "tag 4": "Anno accademico 2023/2024",
        "tag 5": "L-24",
        "Descrizione": "L'Università Telematica Unimarconi offre un corso di laurea triennale in Scienze e Tecniche Psicologiche che appartiene alla classe di laurea L-24. Questo programma è progettato per offrire una formazione solida nelle discipline psicologiche, combinando l'approfondimento dei modelli teorici più all'avanguardia con attività introduttive alla dimensione pratica. Il piano di studi proposto da Unimarconi copre un ampio spettro della psicologia, comprendendo materie come Psicologia generale, Psicologia dello sviluppo, Fisiologia umana, Psicometria e Psicologia clinica.",
        "Piano di studi 1": "Psicologia generale\nFondamenti di fisiologia\nPsicologia sociale\nPsicometria\nIdoneità linguistica: inglese, Abilità informatiche",
        "Piano di studi 2": "Psicobiologia e psicologia fisiologica\nPsicologia dello sviluppo\nPsicologia dinamica\nPsicologia clinica\nPsicologia di comunità\nUlteriori attività Formative a scelta tra: laboratorio di psicologia clinica / Laboratorio di psicologia dinamica",
        "Piano di studi 3": "Psicologia della salute\nPsicologia del lavoro e delle organizzazioni\nEsame a scelta (tra Sociologia del lavoro e Organizzazione aziendale)\n12 crediti (tra Fondamenti di psichiatria, Igiene generale, Biologia applicata, Sanità Pubblica e Medicina di Comunità)\nScelta libera\nUlteriori attività formative: CPG (Corsi Pratici Guidati) Deontologia professionale\n10 CFU Tirocinio pratico Valutativo\nTesi di Laurea",
        "operatore 1": "Unimarconi",
        "operatore 2": "Comparacorsi",
        "operatore 3": "Altri operatori",
        "prezzo 1": "2.760€",
        "prezzo 2": "1.500€",
        "prezzo 3": "3.200€",
        "Domanda 1": "Qual è l'obiettivo del corso di laurea in Scienze e Tecniche Psicologiche presso Unimarconi?",
        "Risposta 1": "Il corso mira a fornire una formazione solida nelle discipline psicologiche, con un focus su modelli teorici all'avanguardia e introduzione alla dimensione pratica della psicologia.",
        "Domanda 2": "Quali sono i contenuti del piano di studi di questo corso?",
        "Risposta 2": "Il piano di studi include Psicologia generale, Psicologia dello sviluppo, Fisiologia umana, Psicometria, Psicologia clinica e una gamma di esami facoltativi come Biologia applicata e Fondamenti di Psichiatria.",
        "Domanda 3": "Che tipo di approccio e strumenti pratici offre questo corso?",
        "Risposta 3": "Il corso bilancia la teoria con la pratica, introducendo metodi di apprendimento pratici e tecniche psicologiche di intervento, come l'elaborazione di diagnosi e l'uso di strumenti di valutazione psicometrica.",
        "Domanda 4": "Quali opportunità lavorative sono disponibili per i laureati in questo corso?",
        "Risposta 4": "I laureati possono lavorare in ruoli di supporto tecnico e pratico in settori come valutazione e diagnosi, assistenza, educazione e formazione, promozione della salute, e selezione del personale.",
        "Domanda 5": "Quali sono i requisiti di ammissione per il corso?",
        "Risposta 5": "È necessario un diploma di scuola secondaria superiore o un titolo equivalente riconosciuto.",
        "Sbocchi": [
          "Supporto tecnico e pratico in valutazione e diagnosi",
          "Professionisti nell'assistenza e nella promozione della salute",
          "Specialisti nell'educazione e formazione",
          "Operatori in istituzioni educative e organizzazioni del terzo settore",
          "Esperti nella selezione del personale in aziende e organizzazioni"
        ],
    },
    {
        "nome": "Scienze e Tecniche Psicologiche",
        "Area": "Psicologia",
        "H1": "Corso di Laurea in Scienze e Tecniche Psicologiche, Università Digitale Mercatorum",
        "tag 1": "Laurea triennale",
        "tag 2": "Durata: 3 anni",
        "tag 3": "180 cfu",
        "tag 4": "Anno accademico 2023/2024",
        "tag 5": "L-24",
        "Descrizione": "L'Università telematica offre un coinvolgente ed esauriente Corso di Laurea Online in 'Scienze e Tecniche Psicologiche', focalizzato non solo su una solida formazione psicologica di base e generalista, ma anche sull'approfondimento di conoscenze disciplinari specifiche legate ai processi sociali ed economici. Il corso si distingue per un profilo di formazione psicologica che si integra con discipline correlate al mondo sociale, della formazione e del lavoro. Con un piano di studi strutturato in 180 Crediti Formativi Universitari da ottenere in tre anni, il corso copre aree essenziali come la Psicologia sociale, Psicologia dello sviluppo, Psicologia del lavoro e Psicologia clinica.",
        "Piano di studi 1": "Psicologia dei processi cognitivi ed emotivi (9 cfu), Psicologia dello sviluppo (9 cfu), Psicologia sociale (9 cfu), Psicologia del lavoro e delle organizzazioni (12 cfu), Psicologia dinamica (9 cfu), Formazione continua (12 cfu)",
        "Piano di studi 2": "Neuropsicologia (9 cfu), Progettazione e valutazione dell'e-learning (6 cfu), Metodi di ricerca psicologica e analisi dei dati (9 cfu), Psicometria (9 cfu), Modelli e tecniche di osservazione del comportamento nei contesti educativi e sociali (6 cfu), Psicologia delle relazioni interpersonali e di gruppo (12 cfu), Psicologia clinica (9 cfu)",
        "Piano di studi 3": "Psicologia dei processi motivazionali e decisionali (9 cfu), Sociologia dei media digitali (9 cfu), Economia e gestione delle imprese (9 cfu), Insegnamento a scelta (12 cfu), Lingua inglese (6 cfu), Abilità informatiche e telematiche (6 cfu), Altre conoscenze utili per l'inserimento nel mondo del lavoro (3 cfu), Prova finale (6 cfu)",
        "operatore 1": "Mercatorum",
        "operatore 2": "Comparacorsi",
        "operatore 3": "Altri operatori",
        "prezzo 1": "3.000€",
        "prezzo 2": "1.600€",
        "prezzo 3": "4.000€",
        "Domanda 1": "Qual è l'obiettivo del corso di laurea in Scienze e Tecniche Psicologiche alla Mercatorum?",
        "Risposta 1": "Il corso mira a fornire una solida formazione psicologica di base e generalista, con enfasi sui processi sociali ed economici e sulle competenze applicabili nel mondo del lavoro e delle organizzazioni.",
        "Domanda 2": "Quali sono i contenuti del piano di studi di questo corso?",
        "Risposta 2": "Il piano di studi include materie come Psicologia dei processi cognitivi ed emotivi, Psicologia del lavoro e delle organizzazioni, Neuropsicologia, Psicometria, Sociologia dei media digitali, e Economia e gestione delle imprese.",
        "Domanda 3": "Che tipo di approccio e strumenti pratici offre questo corso?",
        "Risposta 3": "Il corso prevede seminari, laboratori e esperienze applicative per sviluppare competenze concrete nelle metodiche sperimentali e nell'uso di strumenti di indagine in ambito personale e sociale.",
        "Domanda 4": "Quali opportunità lavorative sono disponibili per i laureati in questo corso?",
        "Risposta 4": "I laureati possono trovare impiego come supporto tecnico/pratico in strutture pubbliche o private, istituzioni educative, aziende, organizzazioni del terzo settore, e in ruoli che coinvolgono attività psicosociali, valutazione e diagnosi, e gestione delle risorse umane.",
        "Domanda 5": "Quali sono i requisiti di ammissione per il corso?",
        "Risposta 5": "È necessario un diploma di scuola secondaria superiore o un titolo equivalente riconosciuto.",
        "Sbocchi": [
          "Specialisti in Supporto Tecnico e Pratico in Strutture Pubbliche o Private",
          "Professionisti in Istituzioni Educative e Aziende",
          "Esperti in Gestione delle Risorse Umane",
          "Consulenti in Attività Psicosociali e Valutazione",
          "Professionisti nel Settore della Promozione della Salute"
        ],
    },
    {
        "nome": "Scienze e Tecniche Psicologiche",
        "Area": "Psicologia",
        "H1": "Corso di Laurea in Scienze e Tecniche Psicologiche, Università Digitale eCampus",
        "tag 1": "Laurea triennale",
        "tag 2": "Durata: 3 anni",
        "tag 3": "180 cfu",
        "tag 4": "Anno accademico 2023/2024",
        "tag 5": "L-24",
        "Descrizione": "Il corso in 'Scienze e Tecniche Psicologiche' presso l'Università telematica eCampus, strutturato in una durata di tre anni e inerente alla classe di laurea L-24, si rivolge a chi ambisce ad acquisire solide basi teoriche e metodologiche nell'ambito psicologico. Durante il percorso, gli studenti esplorano settori diversificati della psicologia e delle scienze connesse, focalizzandosi su strumenti concettuali, metodologici e tecnici per l'analisi e l'intervento psicologico. La formazione pratica riveste un ruolo cardine, accompagnando i futuri professionisti nello sviluppo delle competenze richieste dal mondo del lavoro.",
        "Piano di studi 1": "Psicologia generale, Psicologia dello sviluppo e psicologia dell'educazione, Psicometria, Discipline Demoetnoantropologiche, Biologia applicata, Neurofisiologia, Lingua inglese, Fondamenti di informatica",
        "Piano di studi 2": "Psicologia sociale, Psicologia dello sviluppo tipico e atipico, Filosofia della mente, Metodologia della ricerca, Psicologia Clinica, Psichiatria, Psicologia fisiologica e delle emozioni",
        "Piano di studi 3": "Psicologia Dinamica, Neuropsicologia, Psicologia della comunicazione, Psicologia delle organizzazioni, Psicologia del lavoro, Tirocini, Prova finale",
        "operatore 1": "eCampus",
        "operatore 2": "Comparacorsi",
        "operatore 3": "Altri operatori",
        "prezzo 1": "3.900€",
        "prezzo 2": "2.600€",
        "prezzo 3": "4.300€",
        "Domanda 1": "Qual è l'obiettivo del corso di laurea in Scienze e Tecniche Psicologiche alla eCampus?",
        "Risposta 1": "Il corso mira a fornire una formazione completa nelle discipline psicologiche, unendo competenze teoriche e pratiche per affrontare le sfide psicologiche in contesti sociali ed economici.",
        "Domanda 2": "Quali sono i contenuti del piano di studi di questo corso?",
        "Risposta 2": "Il piano di studi include materie come Psicologia generale, Psicologia dello sviluppo, Psicometria, Psicologia del lavoro, Psicologia clinica, oltre a una serie di seminari e laboratori in ambiti specifici.",
        "Domanda 3": "Che tipo di approccio e strumenti pratici offre questo corso?",
        "Risposta 3": "Il corso prevede seminari, laboratori e esperienze applicative per fornire una formazione pratica in metodiche sperimentali e uso di strumenti di indagine psicologica.",
        "Domanda 4": "Quali opportunità lavorative sono disponibili per i laureati in questo corso?",
        "Risposta 4": "I laureati possono operare in ambiti di interventi psico-educativi, utilizzare strumenti psicologici per valutazioni della personalità e implementare tecniche psicologiche per il recupero di competenze cognitive ed emotive.",
        "Domanda 5": "Quali sono i requisiti di ammissione per il corso?",
        "Risposta 5": "È necessario un diploma di scuola secondaria superiore o un titolo equivalente riconosciuto.",
        "Sbocchi": [
          "Professionisti in Interventi Psico-educativi",
          "Esperti nell'Uso di Strumenti Psicologici per Valutazioni della Personalità",
          "Specialisti in Tecniche Psicologiche per il Recupero di Competenze Cognitive ed Emotive",
          "Professionisti in Strutture Educative, Sociali e Sanitarie"
        ],
    },
    {
        "nome": "Scienze e Tecniche Psicologiche",
        "Area": "Psicologia",
        "H1": "Corso di Laurea in Tecniche e Scienze Psicologiche, Università Digitale Unicusano",
        "tag 1": "Laurea triennale",
        "tag 2": "Durata: 3 anni",
        "tag 3": "180 cfu",
        "tag 4": "Anno accademico 2023/2024",
        "tag 5": "L-24",
        "Descrizione": "Il Corso di Laurea online in 'Psicologia, Scienze e Tecniche psicologiche' (L-24) dell'Università telematica Unicusano è ideale per chi cerca una laurea triennale flessibile e accessibile. Il programma di studi di tre anni (180 CFU) mira a fornire competenze metodologiche essenziali nello studio dei processi psicologici e delle scienze umane, preparando gli studenti a operare in diversi contesti (sociali, organizzativi, educativi e clinici). Attraverso la piattaforma e-learning di Unicusano, gli studenti possono seguire le lezioni gestendo i propri tempi di studio in autonomia. La laurea in Psicologia, Scienze e Tecniche psicologiche apre la strada a diverse opportunità lavorative nei servizi psicologici e in altri ambiti correlati.",
        "Piano di studi 1": "Psicologia generale (9 cfu), Psicologia dello sviluppo (9 cfu), Psicometria (9 cfu), Fondamenti anatomo-fisiologici dell’attività psichica (9 cfu), Fisiologia del comportamento (9 cfu), Antropologia culturale (9 cfu), Lingua inglese (6 cfu)",
        "Piano di studi 2": "Psicobiologia (9 cfu), Psicologia sociale (9 cfu), Psicologia dinamica (9 cfu), Psicologia del lavoro (9 cfu), Psicologia della personalità (9 cfu), Psicologia della percezione (9 cfu), Materia a scelta dello studente (6 cfu)",
        "Piano di studi 3": "Sociologia generale (9 cfu), Informatica (5 cfu), Psicologia clinica (9 cfu), Psicologia della salute e del benessere (9 cfu), Teorie e tecniche del colloquio (9 cfu), Materia a scelta dello studente (6 cfu), Tirocinio (10 cfu), Prova finale (3 cfu)",
        "operatore 1": "Unicusano",
        "operatore 2": "Comparacorsi",
        "operatore 3": "Altri operatori",
        "prezzo 1": "3.600€",
        "prezzo 2": "3.000€",
        "prezzo 3": "4.100€",
        "Domanda 1": "Che tipo di competenze si acquisiscono nel Corso di Laurea in 'Psicologia, Scienze e Tecniche psicologiche' di Unicusano?",
        "Risposta 1": "Gli studenti acquisiscono competenze metodologiche essenziali per lo studio dei processi psicologici e delle scienze umane. Il corso copre aspetti della valutazione psicologica, analisi di contesti lavorativi e sociali, progettazione di interventi psicologici, e sviluppo di metodologie di osservazione. Si enfatizza l'uso di strumenti testologici e relazionali per la valutazione.",
        "Domanda 2": "Come viene erogata la didattica nel corso di laurea online di Unicusano?",
        "Risposta 2": "La didattica è erogata principalmente tramite una piattaforma e-learning che consente agli studenti di seguire lezioni in modalità telematica e studiare a proprio ritmo. Il corso include anche workshop pratici e attività interattive, con esami che possono essere sostenuti nelle sedi di Unicusano sparse in tutta Italia.",
        "Domanda 3": "Quali sono gli sbocchi lavorativi per i laureati in questo corso?",
        "Risposta 3": "I laureati possono operare in vari ambiti come i servizi psicologici, la valutazione del personale, i consultori, la gestione dei conflitti e la promozione della salute. Altri settori includono la Sanità pubblica e privata, e la psicologia scolastica e organizzativa. La laurea abilita anche all’iscrizione all’Albo B degli Psicologi.",
        "Domanda 4": "Il corso offre opportunità di tirocinio o esperienza pratica?",
        "Risposta 4": "Sì, il corso include un tirocinio che consente agli studenti di acquisire competenze pratiche. Queste attività di tirocinio si concentrano su metodi empirici in psicologia, valutazione psicologica e principali forme di intervento, fornendo esperienze preziose nei contesti sociali e lavorativi reali.",
        "Domanda 5": "Come si può accedere al Corso di Laurea in 'Psicologia, Scienze e Tecniche psicologiche' di Unicusano?",
        "Risposta 5": "Per iscriversi, è necessario possedere un diploma di scuola superiore o un titolo equipollente anche conseguito all’estero. Non è previsto un test di ingresso ostativo, rendendo il corso accessibile a chiunque abbia interesse e la preparazione adeguata per intraprendere studi in ambito psicologico.",
        "Sbocchi": [
          "Assistente di Ricerca in Psicologia",
          "Operatore in Servizi di Neuropsichiatria Infantile",
          "Tecnico di Selezione e Formazione del Personale",
          "Consulente per la Promozione del Benessere in Aziende",
          "Assistente in Strutture per l'Assistenza ai Disabili",
          "Educatore in Programmi di Prevenzione della Salute Mentale",
          "Addetto alla Gestione di Conflitti e Mediazione nelle Organizzazioni"
        ],
    },
    {
        "nome": "Psicologia – Discipline Psicosociali",
        "Area": "Psicologia",
        "H1": "Corso di Laurea in Discipline Psicosociali, Università Digitale Uninettuno",
        "tag 1": "Laurea triennale",
        "tag 2": "Durata: 3 anni",
        "tag 3": "180 cfu",
        "tag 4": "Anno accademico 2023/2024",
        "tag 5": "L-24",
        "Descrizione": "Il Corso di Laurea triennale in Discipline Psicosociali presso l'Università Telematica Internazionale Uninettuno rappresenta un'innovativa fusione tra la ricerca psicologica e le neuroscienze cognitive con gli studi interdisciplinari delle scienze umane e dell'educazione. Questa sinergia mira a fornire agli studenti una comprensione profonda delle strutture e dei processi intrapsichici, delle dinamiche che governano le relazioni interpersonali e dei meccanismi psicofisiologici alla base del comportamento sociale.",
        "Piano di studi 1": "Psicologia cognitiva (9 cfu)\nPsicologia dello sviluppo (9 cfu)\nPsicometria e analisi dei dati (8 cfu)\nPsicometria e analisi dei dati in inglese (8 cfu)\nOrigini e storia della psicologia (9 cfu)\nOrigini e storia della psicologia in inglese (9 cfu)\nTeorie e metodi della psicologia sociale (8 cfu)\nElementi di informatica (9 cfu)\nLingua inglese (4 cfu)",
        "Piano di studi 2": "Psicologia di comunità (8 cfu)\nPsicobiologia del comportamento umano (9 cfu)\nBiologia e fisiologia del neurone (10 cfu)\nTeorie e strumenti di valutazione psicosociale (9 cfu)\nLinguaggio e comunicazione (9 cfu)\nPsicologia del lavoro (8 cfu)\nInsegnamento a scelta (6 cfu)",
        "Piano di studi 3": "Educazione e nuovi media (9 cfu)\nPsicologia della formazione, dell’orientamento e dell’organizzazione (8 cfu)\nPsicologia della personalità (9 cfu)\nPsicotecnologie e processi formativi (8 cfu)\nPsicologia dinamica (9 cfu)\nInsegnamento a scelta (6 cfu)\nUlteriori attività formative (2 cfu)\nTirocinio prativo e valutativo - TPV (10 cfu)\nProva finale (4 cfu)",
        "operatore 1": "Uninettuno",
        "operatore 2": "Comparacorsi",
        "operatore 3": "Altri operatori",
        "prezzo 1": "2.000€",
        "prezzo 2": "1.900€",
        "prezzo 3": "0€",
        "Domanda 1": "Qual è l'obiettivo del corso di laurea in Discipline Psicosociali presso UNINETTUNO?",
        "Risposta 1": "Il corso mira a fornire una comprensione approfondita delle strutture e dei processi psicologici, delle relazioni interpersonali e dei meccanismi psicofisiologici, con una fusione tra ricerca psicologica e neuroscienze cognitive.",
        "Domanda 2": "Quali sono i contenuti del piano di studi di questo corso?",
        "Risposta 2": "Il piano di studi include Psicologia cognitiva, Psicologia dello sviluppo, Psicometria e analisi dei dati, Psicologia di comunità, Psicobiologia del comportamento umano, Educazione e nuovi media, Psicologia della formazione, tra gli altri.",
        "Domanda 3": "Che tipo di approccio e strumenti pratici offre questo corso?",
        "Risposta 3": "Il corso integra formazione teorica con attività pratiche, individuali e di gruppo, e si focalizza su metodi di ricerca statistica e competenze avanzate nelle tecnologie della comunicazione e dell'informazione.",
        "Domanda 4": "Quali opportunità lavorative sono disponibili per i laureati in questo corso?",
        "Risposta 4": "I laureati possono lavorare come tecnici dell'assistenza e della previdenza sociale, in settori come sviluppo e riabilitazione, gestione delle risorse umane, formazione e supporto alle famiglie e persone con disabilità.",
        "Domanda 5": "Quali sono i requisiti di ammissione per il corso?",
        "Risposta 5": "È necessario un diploma di scuola secondaria superiore o un titolo equivalente riconosciuto.",
        "Sbocchi": [
            "Tecnici dell'Assistenza e della Previdenza Sociale",
            "Professionisti nel Sostegno ai Processi Educativi",
            "Esperti in Valutazione Psicometrica",
            "Specialisti in Gestione delle Risorse Umane",
            "Assistenti Sociali",
            "Tecnici del Reinserimento Sociale",
            "Tecnici dei Servizi per l'Impiego"
        ]
    },
    {
        "nome": "Scienze Psicologiche delle Risorse Umane, delle Organizzazioni e delle Imprese",
        "Area": "Psicologia",
        "H1": "Corso di Laurea in Scienze Psicologiche delle Risorse Umane, delle Organizzazioni e delle Imprese, Università Digitale IUL",
        "tag 1": "Laurea triennale",
        "tag 2": "Durata: 3 anni",
        "tag 3": "180 cfu",
        "tag 4": "Anno accademico 2023/2024",
        "tag 5": "L-24",
        "Descrizione": "L'Università Telematica degli Studi IUL propone un innovativo corso di Laurea Triennale in 'Scienze Psicologiche delle Risorse Umane, delle Organizzazioni e delle Imprese'. Gli studenti esplorano argomenti chiave come la psicologia del lavoro e delle organizzazioni, i processi psicologici, la sociologia dei processi culturali e comunicativi, e diversi aspetti della pedagogia. IUL, mantenendo un'attenzione particolare verso le psicologia delle imprese e la gestione del talento, si pone l'obiettivo di sviluppare competenze pertinenti alle dinamiche dei gruppi, cooperazione, conflitto e inclusione nei contesti lavorativi.",
        "Piano di studi 1": "Psicologia sociale (9 cfu)\nPsicologia dello sviluppo (9 cfu)\nPsicometria (9 cfu)\nPsicologia generale (12 cfu)\nSociologia generale (6 cfu)\nPedagogia Generale e Sociale (6 cfu)",
        "Piano di studi 2": "Psicologia della personalità e delle differenze individuali (6 cfu)\nPsicologia dello sviluppo e psicologia dell’educazione (6 cfu)\nPedagogia sperimentale (6 cfu)\nPsicologia del lavoro e delle organizzazioni (12 cfu)\nMetodologia della ricerca azione. Colloquio clinico e intervista nei contesti organizzativi (12 cfu)\nPsicologia dei gruppi, management e leadership (6 cfu)\nProcessi psicologici e costruzione sociale delle conoscenze (12 cfu)",
        "Piano di studi 3": "Psicologia clinica (6 cfu)\nPsicologia dinamica (6 cfu)\nDidattica e inclusione (6 cfu)\nEconomia e gestione delle imprese (6 cfu)\nSociologia dei processi culturali e comunicativi (6 cfu)\nConoscenza di almeno una lingua straniera (6 cfu)\nAbilità informatiche e telematiche (6 cfu)\nAltre conoscenze utili per l’inserimento nel mondo del lavoro (6 cfu)\nA scelta dello studente (18 cfu)\nProva finale (3 cfu)",
        "operatore 1": "IUL",
        "operatore 2": "Comparacorsi",
        "operatore 3": "Altri operatori",
        "prezzo 1": "2.700€",
        "prezzo 2": "2.500€",
        "prezzo 3": "3.000€",
        "Domanda 1": "Qual è l'obiettivo del corso di laurea in Scienze Psicologiche delle Risorse Umane, delle Organizzazioni e delle Imprese presso IUL?",
        "Risposta 1": "Il corso mira a fornire competenze in psicologia dell'individuo, sociale e organizzativa, con un focus su dinamiche lavorative e sociali, psicologia del lavoro, processi psicologici e sociologia dei processi culturali e comunicativi.",
        "Domanda 2": "Quali sono i contenuti del piano di studi di questo corso?",
        "Risposta 2": "Il piano di studi include Psicologia sociale, Psicologia dello sviluppo, Psicometria, Psicologia della personalità, Psicologia del lavoro e delle organizzazioni, Psicologia clinica, Psicologia dinamica, tra gli altri.",
        "Domanda 3": "Che tipo di approccio e strumenti pratici offre questo corso?",
        "Risposta 3": "Il corso enfatizza l'apprendimento permanente, combinando solide basi teoriche con strumenti teorico-pratici per funzioni di selezione, organizzazione e valutazione del personale in aziende ed enti.",
        "Domanda 4": "Quali opportunità lavorative sono disponibili per i laureati in questo corso?",
        "Risposta 4": "I laureati possono operare in settori come l’orientamento al lavoro, la selezione del personale, la gestione delle risorse umane in agenzie per il lavoro, aziende, enti pubblici o privati, e in dinamiche di gruppo e gestione del talento.",
        "Domanda 5": "Quali sono i requisiti di ammissione per il corso?",
        "Risposta 5": "È necessario un diploma di scuola secondaria superiore o un titolo equivalente riconosciuto.",
        "Sbocchi": [
            "Specialisti in Orientamento al Lavoro",
            "Esperti nella Selezione del Personale",
            "Professionisti nella Gestione delle Risorse Umane",
            "Consulenti Organizzativi",
            "Tecnici in Dinamiche di Gruppo e Gestione del Talento"
        ]
    },
    {
        "nome": "Psicologia del Lavoro e dell'Economia",
        "Area": "Psicologia",
        "H1": "Corso di Laurea in Psicologia del Lavoro e dell'Economia, Università Digitale Unimarconi",
        "tag 1": "Laurea triennale",
        "tag 2": "Durata: 3 anni",
        "tag 3": "180 cfu",
        "tag 4": "Anno accademico 2023/2024",
        "tag 5": "L-24",
        "Descrizione": "Nell’ambito della psicologia del lavoro, l'Università Telematica Unimarconi offre un percorso di laurea triennale strutturato e robusto. Il corso, che appartiene alla classe L-24, mira a formare lo Psicologo Junior, un professionista capace di sviluppare e applicare principi, conoscenze, modelli e metodi nei vari settori della psicologia, con un focus predominante sulla psicologia economica e del lavoro. Gli studenti hanno l'opportunità di vivere esperienze internazionali attraverso il programma Erasmus e partecipare a laboratori tematici, come quelli di neuroeconomia e neuromarketing, affrontando così tematiche di stretta attualità nel panorama della psicologia del lavoro.",
        "Piano di studi 1": "Psicologia generale\nFondamenti di fisiologia\nPsicologia sociale\nPsicometria\nIdoneità linguistica: inglese\nAbilità informatiche",
        "Piano di studi 2": "Psicobiologia e psicologia fisiologica\nPsicologia dello sviluppo\nPsicologia dinamica\nPsicologia di comunità\nPsicologia clinica\nPsicologia economica",
        "Piano di studi 3": "Psicologia della salute\nPsicologia del lavoro e delle organizzazioni\n12 crediti (tra Sociologia del lavoro, Organizzazione aziendale, Strategia, gestione e sviluppo dell’innovazione, Medicina del lavoro, Relazioni industriali)\nUlteriori attività formative: Laboratorio di Economia cognitiva e dei processi decisionali\nScelta libera\nUlteriori attività formative: CPG (Corsi Pratici Guidati) Deontologia professionale\n10 CFU Tirocinio pratico Valutativo\nTesi di Laurea",
        "operatore 1": "Unimarconi",
        "operatore 2": "Comparacorsi",
        "operatore 3": "Altri operatori",
        "prezzo 1": "2.760€",
        "prezzo 2": "1.500€",
        "prezzo 3": "3.200€",
        "Domanda 1": "Qual è l'obiettivo del corso di laurea in Psicologia del Lavoro alla Unimarconi?",
        "Risposta 1": "Il corso mira a formare Psicologi Junior specializzati in psicologia economica e del lavoro, combinando teoria e pratica per sviluppare conoscenze e competenze applicabili nei vari settori della psicologia.",
        "Domanda 2": "Quali sono i contenuti del piano di studi di questo corso?",
        "Risposta 2": "Il piano di studi include Psicologia generale, Psicologia sociale, Psicometria, Psicologia del lavoro e delle organizzazioni, Psicologia clinica, Psicologia economica, tra gli altri.",
        "Domanda 3": "Che tipo di approccio e strumenti pratici offre questo corso?",
        "Risposta 3": "Il corso offre un approccio pratico attraverso laboratori di neuromarketing e processi decisionali, preparando gli studenti ad applicare le loro conoscenze nel contesto lavorativo e organizzativo.",
        "Domanda 4": "Quali opportunità lavorative sono disponibili per i laureati in questo corso?",
        "Risposta 4": "I laureati possono lavorare in settori come HR, consulenza, formazione, sviluppo organizzativo, e sono preparati per percorsi magistrali che portano all'abilitazione come psicologi.",
        "Domanda 5": "Quali sono i requisiti di ammissione per il corso?",
        "Risposta 5": "È necessario un diploma di scuola secondaria superiore o un titolo equivalente riconosciuto.",
        "Sbocchi": [
            "Specialisti in Risorse Umane",
            "Consulenti Organizzativi",
            "Professionisti nella Formazione e Sviluppo Organizzativo",
            "Esperti in Psicologia Economica e Comportamento Lavorativo",
            "Preparatori per ulteriori percorsi di specializzazione in Psicologia"
        ]
    },
    {
        "nome": "Psicologia del Lavoro e delle Organizzazioni",
        "Area": "Psicologia",
        "H1": "Corso di Laurea in Psicologia del Lavoro e delle Organizzazioni, Università Digitale Mercatorum",
        "tag 1": "Laurea magistrale",
        "tag 2": "Durata: 2 anni",
        "tag 3": "120 cfu",
        "tag 4": "Anno accademico 2023/2024",
        "tag 5": "LM-51",
        "Descrizione": "La laurea magistrale in 'Psicologia del Lavoro e delle Organizzazioni' offerta dall’Università telematica Mercatorum si propone come un percorso formativo completo e articolato, focalizzato sulla gestione delle risorse umane in varie organizzazioni. Questo corso online di laurea (classe LM-51) si concentra su diversi ambiti del profilo professionale dello psicologo, tra cui recruiting, selezione, valutazione, formazione, sviluppo e coaching. Dando una spiccata attenzione alle dinamiche psicologiche e sociali intrinseche nell’ambito lavorativo e organizzativo, il programma permette di acquisire competenze nella pianificazione e gestione delle risorse umane.",
        "Piano di studi 1": "Psicologia della personalità e delle differenze individuali (9 cfu), Teorie e tecniche dei test (6 cfu), Metodologia della progettazione formativa (6 cfu), Psicologia dell'orientamento e del placement (6 cfu), Psicologia della comunicazione e del marketing (12 cfu), Psicologia delle organizzazioni (12 cfu), Diritto del lavoro (9 cfu)",
        "Piano di studi 2": "E-learning delle organizzazioni (9 cfu), Psicologia della gestione e dello sviluppo individuale e organizzativo (9 cfu), Psicodinamica dei gruppi e delle istituzioni (12 cfu), Insegnamento a scelta (9 cfu), Lingua inglese (6 cfu), Stage e tirocini presso imprese, enti pubblici o privati, ordini professionali (6 cfu), Prova Finale (9 cfu)",
        "operatore 1": "Mercatorum",
        "operatore 2": "Comparacorsi",
        "operatore 3": "Altri operatori",
        "prezzo 1": "3.000€",
        "prezzo 2": "1.600€",
        "prezzo 3": "4.300€",
        "Domanda 1": "Qual è l'obiettivo del corso di laurea magistrale in Psicologia del Lavoro e delle Organizzazioni alla Mercatorum?",
        "Risposta 1": "Il corso mira a formare professionisti specializzati nella gestione delle risorse umane e nelle dinamiche psicologiche all'interno delle organizzazioni, con una forte enfasi sulla comunicazione, il marketing e il diritto del lavoro.",
        "Domanda 2": "Quali sono i contenuti del piano di studi di questo corso?",
        "Risposta 2": "Il piano di studi include materie come Psicologia delle Organizzazioni, Psicologia della Comunicazione e del Marketing, Diritto del Lavoro, Psicologia della Gestione e dello Sviluppo Individuale e Organizzativo, tra gli altri.",
        "Domanda 3": "Che tipo di approccio e strumenti pratici offre questo corso?",
        "Risposta 3": "Il corso adotta metodi e tecniche specifiche dell'analisi psicologico-sociale dei processi lavorativi e organizzativi, con un focus su sviluppo di competenze relazionali, riflessive e tecniche.",
        "Domanda 4": "Quali opportunità lavorative sono disponibili per i laureati in questo corso?",
        "Risposta 4": "I laureati possono operare come liberi professionisti o dipendenti in ambiti che riguardano la gestione delle relazioni, la consulenza, la ricerca scientifica e la gestione dello stress lavoro-correlato.",
        "Domanda 5": "Quali sono i requisiti di ammissione per il corso?",
        "Risposta 5": "È necessaria una laurea in un campo rilevante, con un certo numero di crediti nei settori scientifici disciplinari psicologici e una conoscenza della lingua inglese di almeno livello B1.",
        "Sbocchi": [
            "Specialisti in Gestione delle Risorse Umane",
            "Consulenti Organizzativi e di Comunicazione",
            "Professionisti in Ricerca Scientifica nel settore Psicologico",
            "Esperti in Gestione dello Stress Lavoro-correlato",
            "Liberi Professionisti in Psicologia del Lavoro e delle Organizzazioni"
        ]
    },
    {
        "nome": "Psicologia Comportamentale e Cognitiva Applicata",
        "Area": "Psicologia",
        "H1": "Corso di Laurea in Psicologia Comportamentale e Cognitiva Applicata, Università digitale Unifortunato",
        "tag 1": "Laurea Magistrale",
        "tag 2": "Durata: 2 anni",
        "tag 3": "120 cfu",
        "tag 4": "Anno accademico 2023/2024",
        "tag 5": "LM-51",
        "Descrizione": "La laurea magistrale in 'Psicologia Comportamentale e Cognitiva Applicata' dell'Università Telematica Unifortunato è un percorso formativo avanzato che esplora gli intricati meccanismi della psicologia cognitivo-comportamentale. Con un focus sulle metodologie di studio dei processi cognitivi e della neuro-psicologia applicata, questo corso online (classe LM-51) apre le porte a una comprensione profonda delle basi psicologiche dei disturbi del comportamento e delle strategie efficaci di intervento psicologico. Attraverso un metodo interdisciplinare e i vantaggi della laurea online, il programma dell'università telematica integra gli approcci socio-cognitivo e clinico con l'uso delle tecnologie più recenti. Gli studenti acquisiranno competenze fondamentali per operare nell'ambito della terapia cognitivo comportamentale, clinica e in ambito formativo.",
        "Piano di studi 1": "Psicologia generale applicata (8 cfu), Psicologia e neuroscienze (8 cfu), Teoria dei test psicologici (8 cfu), Scienze psichiatriche (10 cfu), Benessere organizzativo e sviluppo delle performance (8 cfu), Pedagogia sperimentale e valutazione educativa (6 cfu), Psicologia dei cicli di vita (6 cfu), Psicologia della salute (6 cfu)",
        "Piano di studi 2": "Psicologia delle relazioni (8 cfu), Psicologia speciale e dell'inclusione (8 cfu), Prevenzione, diagnosi e intervento in ambito clinico (10 cfu), Inglese avanzato (4 cfu), Professione psicologica: mercato del lavoro, self marketing e personal branding (8 cfu), Prova finale (14 cfu)",
        "operatore 1": "Unifortunato",
        "operatore 2": "Comparacorsi",
        "operatore 3": "Altri operatori",
        "prezzo 1": "3.000€",
        "prezzo 2": "2.700€",
        "prezzo 3": "3.900€",
        "Domanda 1": "Qual è la durata del corso di laurea magistrale in Psicologia Comportamentale e Cognitiva Applicata?",
        "Risposta 1": "Il corso di laurea magistrale in Psicologia Comportamentale e Cognitiva Applicata ha una durata di due anni accademici. Durante questo periodo, gli studenti devono completare 120 Crediti Formativi Universitari. Questo percorso offre un'approfondita comprensione della psicologia comportamentale e cognitiva, integrando teoria e pratica per una formazione completa e versatile.",
        "Domanda 2": "Posso lavorare come psicologo dopo aver completato il corso?",
        "Risposta 2": "Dopo la laurea magistrale, è possibile intraprendere la carriera di psicologo. Tuttavia, è necessario completare ulteriori formazioni abilitanti in psicoterapia e iscriversi all'Albo professionale degli psicologi. La laurea fornisce le basi teoriche e pratiche fondamentali, ma l'abilitazione completa richiede passaggi aggiuntivi conformemente alle normative professionali.",
        "Domanda 3": "Quali sono le aree di specializzazione offerte dal corso?",
        "Risposta 3": "Il corso di Psicologia Comportamentale e Cognitiva Applicata copre un'ampia gamma di aree, tra cui la psicologia cognitiva, la neuropsicologia, l'analisi dei disturbi del comportamento e le tecniche di intervento psicologico. Queste specializzazioni preparano gli studenti a comprendere e affrontare complesse questioni psicologiche in vari contesti, dalla clinica alla ricerca.",
        "Domanda 4": "Quali sbocchi professionali offre il corso?",
        "Risposta 4": "I laureati in Psicologia Comportamentale e Cognitiva Applicata possono trovare opportunità lavorative in diversi settori, come la promozione della salute e del benessere in comunità, ambienti lavorativi e scolastici, oltre che in contesti sanitari e ospedalieri. Il corso fornisce le competenze necessarie per lavorare efficacemente in questi ambiti, promuovendo il benessere psicologico e affrontando varie problematiche.",
        "Domanda 5": "È richiesta una laurea specifica per l'ammissione al corso?",
        "Risposta 5": "Per l'ammissione a questo corso di laurea magistrale è preferibilmente richiesta una laurea triennale nella classe L-24 di Scienze e Tecniche psicologiche. Tuttavia, sono considerati anche candidati con titoli di laurea in altre classi, purché soddisfino i requisiti curriculari specificati nel regolamento didattico del corso di studi.",
        "Sbocchi": [
            "Psicologo Clinico",
            "Consulente Scolastico",
            "Ricercatore in Psicologia",
            "Esperto di Formazione e Sviluppo",
            "Consulente di Salute Mentale in Ambito Comunitario",
            "Specialista in ambito comportamentale"
        ]
    },
    {
        "nome": "Psicologia Clinica e Dinamica",
        "Area": "Psicologia",
        "H1": "Corso di Laurea in Psicologia Clinica e Dinamica, Università digitale eCampus",
        "tag 1": "Laurea Magistrale",
        "tag 2": "Durata: 2 anni",
        "tag 3": "120 cfu",
        "tag 4": "Anno accademico 2023/2024",
        "tag 5": "LM-51",
        "Descrizione": "La laurea magistrale telematica in 'Psicologia Clinica e Dinamica' offerta dall'Università eCampus è un corso online di due anni che forma psicologi nella valutazione e gestione di interventi psicologico-clinici. Questo corso di 120 crediti integra teoria psicodinamica e applicazioni pratiche, incluse le neuroscienze cognitive e l'analisi delle relazioni interpersonali. Gli studenti partecipano a lezioni, workshop e tirocini, preparandosi a carriere come psicologi clinici, psicoterapeuti, o specialisti in ambito educativo e organizzativo. Con la laurea in Psicologia Clinica e Dinamica, si apre la strada all'esercizio della professione di psicologo e a ulteriori studi specialistici.",
        "Piano di studi 1": "Teorie e tecniche dei test (6 cfu), Psicologia del ciclo di vita (6 cfu), Scienze psichiatriche (12 cfu), Analisi e trattamento del disagio psichico e delle psicopatologie (12 cfu), Principi costituzionali, legislazione e programmazione sanitaria e sociale (6 cfu), Psicologia e analisi delle relazioni interpersonali (6 cfu), Neuroscienze cognitive e affettive e sociali (6 cfu), Esame a scelta (6 cfu)",
        "Piano di studi 2": "Psicologia dei gruppi e di comunità (9 cfu), Tecniche e modelli di Psicoterapia (9 cfu), dinamiche relazionali e rischio evolutivo (9 cfu), Educazione permanente e formazione degli adulti (6 cfu), Esame a scelta (6 cfu), Tirocini (9 cfu), Esame / Workshop a scelta (12 cfu), Prova Finale (12 cfu)",
        "operatore 1": "eCampus",
        "operatore 2": "Comparacorsi",
        "operatore 3": "Altri operatori",
        "prezzo 1": "3.900€",
        "prezzo 2": "2.600€",
        "prezzo 3": "4.200€",
        "Domanda 1": "Qual è la durata del corso di laurea magistrale in Psicologia Clinica e Dinamica dell'Università eCampus?",
        "Risposta 1": "Il corso di laurea magistrale ha una durata di due anni, con l'obiettivo di completare 120 crediti formativi universitari, offrendo una formazione approfondita in psicologia clinica e dinamica.",
        "Domanda 2": "Posso esercitare la professione di psicologo dopo aver completato questo corso?",
        "Risposta 2": "Sì, dopo la laurea, si può esercitare la professione di psicologo, previa iscrizione all'albo professionale e ulteriore formazione specializzata, in conformità alle normative vigenti.",
        "Domanda 3": "Quali sono le principali aree di studio coperte nel corso?",
        "Risposta 3": "Il corso copre aree quali la teoria psicodinamica, la psicologia del ciclo di vita, le neuroscienze cognitive e l'analisi delle relazioni interpersonali, fornendo una base solida per la pratica professionale.",
        "Domanda 4": "Quali sbocchi lavorativi sono disponibili per i laureati in questo corso?",
        "Risposta 4": "I laureati possono intraprendere carriere variegate, tra cui psicologi clinici e psicoterapeuti, psicologi dello sviluppo e dell'educazione, e specialisti in ambito organizzativo, sfruttando le competenze acquisite.",
        "Domanda 5": "Il corso prevede delle attività pratiche?",
        "Risposta 5": "Sì, il corso include diverse attività pratiche come workshop, aule virtuali per approfondimenti e tirocini formativi, che sono fondamentali per consolidare le competenze teoriche e pratiche nello studio della psicologia.",
        "Sbocchi": [
            "Esperto in Intervento in Crisi e Trauma",
            "Psicologo dello Sviluppo e dell'Educazione",
            "Psicologo del Lavoro e delle Organizzazioni",
            "Ricercatore in Psicologia",
            "Consulente in Ambiti Sanitari e Ospedalieri",
            "Formatore in Ambito Psicologico"
        ]
    },
    {
        "nome": "Psicologia e Nuove Tecnologie",
        "Area": "Psicologia",
        "H1": "Corso di Laurea Magistrale Psicologia e Nuove Tecnologie, Università Digitale eCampus",
        "tag 1": "Laurea Magistrale",
        "tag 2": "Durata: 2 anni",
        "tag 3": "120 cfu",
        "tag 4": "Anno accademico 2023/2024",
        "tag 5": "LM-51",
        "Descrizione": "Il Corso di Laurea Magistrale in 'Psicologia e Nuove Tecnologie' dell'Università telematica eCampus, della durata di due anni (120 CFU), si concentra sulla formazione di psicologi esperti nell'uso delle nuove tecnologie nel campo psicologico. Questo percorso innovativo integra le conoscenze apprese nelle tecniche e scienze psicologiche con l'applicazione delle tecnologie informatiche in ricerca e intervento. Gli studenti esplorano come le tecnologie influenzino la comunicazione e l'informazione, oltre a gestire interventi individuali e di gruppo per la prevenzione dei rischi associati all'uso della rete. Con questa laurea magistrale, gli studenti sono pronti ad inserirsi professionalmente in diversi settori occupazionali, inclusi quelli clinico, sociale, organizzativo, formativo ed educativo.",
        "Piano di studi 1": "Psicologia cognitiva applicata (6 cfu), Psicologia del ciclo di vita (6 cfu), Scienze psichiatriche (12 cfu), Analisi e trattamento del disagio psichico e delle psicopatologie (12 cfu), Lingua inglese (6 cfu), Neuroscienze cognitive, affettive e sociali (6 cfu), Psicologia e analisi delle relazioni interpersonali (6 cfu), A scelta dello studente (6 cfu)",
        "Piano di studi 2": "Teorie e tecniche della comunicazione tra gruppi (9 cfu), Psicotecnologie per la clinica (9 cfu), Psicologia delle dipendenze tecnologiche (9 cfu), Tecnologie dell’informazione per gli psicologi (6 cfu), A scelta dello studente (6 cfu), Tirocini (9 cfu), Prova finale (12 cfu)",
        "operatore 1": "eCampus",
        "operatore 2": "Comparacorsi",
        "operatore 3": "Altri operatori",
        "prezzo 1": "3.900€",
        "prezzo 2": "2.600€",
        "prezzo 3": "4.200€",
        "Domanda 1": "In cosa consiste l'approccio innovativo del corso in Psicologia e Nuove Tecnologie?",
        "Risposta 1": "Questo corso si distingue per l'integrazione delle conoscenze tradizionali in psicologia con l'applicazione pratica delle nuove tecnologie. Si concentra sull'utilizzo delle tecnologie informatiche per la ricerca e l'intervento in ambiti come la comunicazione online, la prevenzione dei rischi legati all'uso della rete e la gestione di interventi psicosociali.",
        "Domanda 2": "Quali competenze specifiche si acquisiscono con questo corso?",
        "Risposta 2": "Gli studenti sviluppano competenze nell'applicazione delle nuove tecnologie in contesti psicologici, compresa la valutazione e il trattamento di problemi legati all'uso della rete. Imparano anche a progettare e implementare interventi psicologici clinici e sociali in ambienti reali e virtuali, utilizzando strumenti tecnologici avanzati.",
        "Domanda 3": "Come viene erogata la didattica nel corso di laurea online?",
        "Risposta 3": "La didattica del corso è erogata attraverso una piattaforma e-learning di eCampus, che consente di seguire lezioni in modalità telematica. Gli studenti possono studiare a proprio ritmo e partecipare a workshop pratici, aule virtuali e tirocini formativi, per un apprendimento integrato tra teoria e pratica.",
        "Domanda 4": "Il corso include opportunità di tirocinio?",
        "Risposta 4": "Sì, il corso prevede tirocini formativi che consentono agli studenti di applicare le competenze acquisite in contesti reali, con un focus particolare sugli aspetti pratici dell'utilizzo delle nuove tecnologie nella psicologia. Queste esperienze pratiche sono essenziali per preparare gli studenti all'esercizio professionale.",
        "Domanda 5": "Quali sono i prerequisiti per l'ammissione al corso?",
        "Risposta 5": "Per essere ammessi al corso, è necessario possedere una laurea triennale in Scienze e Tecniche Psicologiche o in un campo affine. Gli studenti con altre lauree possono essere considerati se hanno acquisito un numero sufficiente di crediti in materie psicologiche. Non è previsto un test di ingresso ostativo per l'accesso al corso.",
        "Sbocchi": [
            "Esperto in Psicologia dei Media Digitali",
            "Consulente per la Cyberpsicologia",
            "Analista del Comportamento Online",
            "Specialista in Tecnologie Assistive per la Salute Mentale",
            "Ricercatore in Interazione Uomo-Macchina",
            "Coordinatore di Programmi di Benessere Digitale",
            "Consulente per lo Sviluppo di Applicazioni Terapeutiche Mobili"
        ]
    },
    {
        "nome": "Psicologia Giuridica",
        "Area": "Psicologia",
        "H1": "Corso di Laurea Magistrale Psicologia Giuridica, Università Digitale eCampus",
        "tag 1": "Laurea Magistrale",
        "tag 2": "Durata: 2 anni",
        "tag 3": "120 cfu",
        "tag 4": "Anno accademico 2023/2024",
        "tag 5": "LM-51",
        "Descrizione": "Il Corso di Laurea Magistrale in 'Psicologia Giuridica' dell'università telematica eCampus, della durata di due anni (120 CFU) prepara gli studenti a operare con competenza e scientificità nei contesti giudiziari. Questo percorso si focalizza sull'acquisizione di strumenti operativi, tecniche e metodi per intervenire efficacemente nell'ambito giuridico-forense, con particolare attenzione alle aree di tutela civile e penale, promozione della legalità, prevenzione dei rischi, e programmazione sociale. Gli studenti esploreranno l'importante ruolo dello psicologo nel contesto giudiziario, imparando a valutare e definire piani di trattamento e intervento adeguati per minori e adulti, e a gestire le dinamiche psicologiche in situazioni legali complesse.",
        "Piano di studi 1": "Psicologia cognitiva applicata (6 cfu), Psicologia del ciclo di vita (6 cfu), Scienze psichiatriche (12 cfu), Analisi e trattamento del disagio psichico e delle psicopatologie (12 cfu), Ordinamento giudiziario (6 cfu), Psicologia e analisi delle relazioni interpersonali (6 cfu), Neuroscienze cognitive e affettive e sociali (6 cfu), Esame a scelta (6 cfu)",
        "Piano di studi 2": "Psicologia giuridica M-PSI/05 (9 cfu), Teorie e tecniche di psicoterapia vittimologica (9 cfu), Dinamiche relazionali e rischio evolutivo (9 cfu), Pedagogia sociale e devianza (6 cfu), Esame a scelta (6 cfu), Tirocini (9 cfu), Prova Finale (12 cfu)",
        "operatore 1": "eCampus",
        "operatore 2": "Comparacorsi",
        "operatore 3": "Altri operatori",
        "prezzo 1": "3.900€",
        "prezzo 2": "2.600€",
        "prezzo 3": "4.200€",
        "Domanda 1": "Quali competenze specifiche si sviluppano nel Corso di Laurea Magistrale online in Psicologia Giuridica?",
        "Risposta 1": "Nel corso si svilupperanno competenze avanzate in valutazione psicologica e intervento nel contesto giuridico, e si imparerà a gestire casi riguardanti la tutela civile e penale, la promozione della legalità, e la prevenzione dei rischi. Inoltre si acquisirà anche abilità nell'analisi e nell'intervento in situazioni di esclusione sociale e lavorativa.",
        "Domanda 2": "Come la Psicologia Giuridica si applica nel contesto professionale?",
        "Risposta 2": "La Psicologia Giuridica si applica in una varietà di contesti, incluso il sostegno a vittime e testimoni, la valutazione della credibilità, la consulenza in casi di affidamento dei minori, la selezione del personale in ambito giuridico e la collaborazione con avvocati e giudici per comprendere gli aspetti psicologici che influenzano le decisioni legali.",
        "Domanda 3": "Quali sono le prospettive di carriera dopo aver completato il corso in Psicologia Giuridica?",
        "Risposta 3": "Le prospettive di carriera includono ruoli come psicologo forense, consulente per enti governativi o organizzazioni legali, esperto in valutazione di testimoni, operatore in programmi di sostegno a vittime di crimini, e collaboratore in team multidisciplinari per la pianificazione di politiche sociali e legali.",
        "Domanda 4": "Il corso prevede un approccio pratico o interattivo?",
        "Risposta 4": "Sì, oltre alle lezioni teoriche, il corso offre numerose attività interattive, workshop pratici in presenza e aule virtuali per gli approfondimenti disciplinari. Questi componenti sono progettati per consolidare le competenze pratiche e teoriche e preparare gli studenti all'applicazione reale delle conoscenze nel campo della psicologia giuridica.",
        "Domanda 5": "Quali sono i prerequisiti per l'ammissione al Corso di Laurea Magistrale in Psicologia Giuridica?",
        "Risposta 5": "Per l'ammissione è generalmente richiesto un titolo di laurea triennale in Psicologia o in un campo affine. Gli studenti devono dimostrare di avere una solida base nelle discipline psicologiche e un interesse specifico per le applicazioni della psicologia nel contesto giuridico e forense. Alcune conoscenze pregresse in ambito giuridico possono essere vantaggiose, ma non sono generalmente obbligatorie.",
        "Sbocchi": [
            "Consulente Forense per la Valutazione della Credibilità dei Testimoni",
            "Specialista in Interventi Psicologici per il Sostegno alle Vittime di Reato",
            "Esperto in Mediazione Familiare e Custodia dei Minori",
            "Analista del Comportamento per Agenzie di Sicurezza e Forze dell'Ordine",
            "Consulente Legale in Psicologia per Studi Legali e Tribunali",
            "Coordinatore di Programmi di Riabilitazione psicologica",
            "Esperto in Analisi del Comportamento e Profilazione Criminale"
        ]
    },
    {
        "nome": "Psicologia Strategica",
        "Area": "Psicologia",
        "H1": "Corso di Laurea Magistrale Psicologia Strategica, Università Digitale eCampus",
        "tag 1": "Laurea Magistrale",
        "tag 2": "Durata: 2 anni",
        "tag 3": "120 cfu",
        "tag 4": "Anno accademico 2023/2024",
        "tag 5": "LM-51",
        "Descrizione": "Il corso di Laurea Magistrale Online in 'Psicologia Strategica' dell'Università telematica eCampus, della durata di due anni (120 crediti), forma professionisti capaci di promuovere il cambiamento in ambiti individuali e sociali. Focalizzato su tecniche di comunicazione e strategie di intervento, il corso online in psicologia fornisce strumenti operativi e teorici avanzati. La laurea online in psicologia strategica è strutturata per garantire una solida preparazione teorica e pratica, con materie che vanno dalla 'Psicologia della Comunicazione Strategica' alla 'Neuroscienze Cognitive'. Il percorso didattico è arricchito da workshop pratici, tirocini e aule virtuali, permettendo agli studenti di sviluppare una comprensione approfondita e applicata della materia.",
        "Piano di studi 1": "Psicologia della comunicazione strategica (6 cfu), Psicologia del ciclo di vita (6 cfu), Scienze psichiatriche (12 cfu), Analisi e trattamento del disagio psichico e delle psicopatologie (12 cfu), Psicologia dei processi di apprendimento biologici, emotivi e cognitivi (6 cfu), Neuroscienze cognitive, affettive e sociali (6 cfu), Psicologia e analisi delle relazioni interpersonali (6 cfu), A scelta dello studente (6 cfu)",
        "Piano di studi 2": "Tecniche di intervento di psicologia strategica (9 cfu), Tecnica del colloquio psicologico (9 cfu), Psicologia economica, delle organizzazioni e della leadership (9 cfu), Lingua inglese (6 cfu), A scelta dello studente (6 cfu), Tirocini (9 cfu), Prova finale (12 cfu)",
        "operatore 1": "eCampus",
        "operatore 2": "Comparacorsi",
        "operatore 3": "Altri operatori",
        "prezzo 1": "3.900€",
        "prezzo 2": "2.600€",
        "prezzo 3": "4.200€",
        "Domanda 1": "È possibile iscriversi al corso di Psicologia Strategica online in qualsiasi momento?",
        "Risposta 1": "Sì, l'Università telematica eCampus offre flessibilità nelle iscrizioni per la laurea magistrale in psicologia, permettendo agli studenti di iniziare il percorso online in breve tempo, secondo le proprie esigenze.",
        "Domanda 2": "Come vengono erogate le lezioni di psicologia strategica online?",
        "Risposta 2": "Le lezioni sono erogate attraverso una piattaforma digitale interattiva, assicurando un'esperienza formativa coinvolgente e flessibile per la laurea magistrale in psicologia.",
        "Domanda 3": "Quali sono i prerequisiti per accedere alla laurea magistrale in Psicologia Strategica?",
        "Risposta 3": "È necessario avere una laurea triennale, preferibilmente in ambito psicologico o affine, per iscriversi alla laurea magistrale online in psicologia strategica presso l'eCampus.",
        "Domanda 4": "Sono previsti momenti di interazione diretta con i docenti del corso online in Psicologia Strategica?",
        "Risposta 4": "Sì, il corso di laurea magistrale in psicologia prevede sessioni interattive, webinar e tutoraggi per garantire un supporto costante e personalizzato.",
        "Domanda 5": "Come si svolgono gli esami nel corso di psicologia online?",
        "Risposta 5": "Gli esami si svolgono online attraverso la piattaforma dell'Università telematica eCampus, con procedure che garantiscono l'integrità e la valutazione oggettiva delle competenze acquisite nella psicologia laurea magistrale.",
        "Sbocchi": [
            "Consulente in Psicologia Strategica per le Organizzazioni.",
            "Esperto in Comunicazione Sanitaria.",
            "Facilitatore di Cambiamento in Ambienti Educativi.",
            "Analista Comportamentale in Settori di Mercato.",
            "Mediatore Familiare e di Coppia con Approccio Strategico.",
            "Consulente per lo Sviluppo Personale e Professionale."
        ]
    },
    {
        "nome": "Psicologia",
        "Area": "Psicologia",
        "H1": "Corso di Laurea Magistrale Psicologia, Università Digitale Unimarconi",
        "tag 1": "Laurea Magistrale",
        "tag 2": "Durata: 2 anni",
        "tag 3": "120 cfu",
        "tag 4": "Anno accademico 2023/2024",
        "tag 5": "LM-51",
        "Descrizione": "La Laurea Magistrale Online in 'Psicologia' offerta dall'Università Telematica Unimarconi, classificata LM-51, è un percorso formativo avanzato che mira a sviluppare competenze approfondite nei diversi ambiti della psicologia, quali psicobiologia, psicologia dello sviluppo e sociale, del lavoro, dinamica e clinica. Questo corso online, arricchito dalla Certificazione EuroPsy, assicura il raggiungimento della qualifica di psicologo con una preparazione solida e conforme agli standard europei, combinando modelli teorici innovativi con pratica professionale, metodologie di intervento e tecniche di psicologia online. Gli aspiranti psicologi affronteranno materie come Psicologia cognitiva e Neuropsicologia, oltre a scelte specializzate come la Sanità pubblica, preparandosi per una prova finale che apre la strada all'abilitazione professionale.",
        "Piano di studi 1": "Psicologia cognitiva (6 cfu), Neuropsicologia (6 cfu), Disturbi cognitivi in età evolutiva (6 cfu), Modelli teorici e operativi della psicologia di comunità (12 cfu), Modelli teorici, tecniche di ricerca e intervento in psicologia dinamica (12 cfu), Psichiatria e psicopatologia (6 cfu), Metodologia di intervento nel disagio emotivo (6 cfu), Scelta libera (6 cfu)",
        "Piano di studi 2": "Metodologia clinica (6 cfu), Intervento nelle relazioni e tecniche di osservazione in contesti clinici (12 cfu), Scelta libera (6 cfu), A scelta tra: Laboratorio di Metodologie e Tecniche del Colloquio Psicodinamico, Laboratorio Inglese Scientifico (4 cfu), Tirocinio Pratico Valutativo (20 cfu), Tesi di Laurea Magistrale (12 cfu)",
        "operatore 1": "Unimarconi",
        "operatore 2": "Comparacorsi",
        "operatore 3": "Altri operatori",
        "prezzo 1": "2.760€",
        "prezzo 2": "1.500€",
        "prezzo 3": "3.100€",
        "Domanda 1": "Posso accedere al corso di laurea magistrale in Psicologia di Unimarconi senza test selettivo?",
        "Risposta 1": "Sì, l'accesso alla laurea magistrale telematica in Psicologia all'Università Telematica Guglielmo Marconi è libero, senza test selettivi, rendendo il corso online in psicologia accessibile a tutti i qualificati.",
        "Domanda 2": "È possibile iscriversi al corso di Psicologia online in qualsiasi periodo dell'anno?",
        "Risposta 2": "Assolutamente, le iscrizioni alla laurea magistrale in Psicologia presso Unimarconi sono aperte tutto l'anno, offrendo flessibilità per iniziare il percorso di psicologia online secondo la tua convenienza.",
        "Domanda 3": "Come viene garantita la qualità della formazione in Psicologia a distanza?",
        "Risposta 3": "Il corso di laurea online in Psicologia di Unimarconi è arricchito dalla Certificazione EuroPsy, assicurando che la formazione telematica soddisfi standard di qualità europei e formi gli studenti sul significato principale della professione.",
        "Domanda 4": "Cosa comporta la prova finale del corso di Psicologia online?",
        "Risposta 4": "La prova finale della laurea magistrale in Psicologia è per definizione una valutazione complessiva delle competenze acquisite, cruciale per dimostrare la tua preparazione in psicologia online e per l'abilitazione professionale.",
        "Domanda 5": "Sono previsti supporti o tutoraggi durante il corso di laurea magistrale in Psicologia?",
        "Risposta 5": "Sì, Unimarconi fornisce supporto continuo agli studenti della laurea magistrale telematica in Psicologia, inclusi tutoraggi e risorse online per massimizzare l'apprendimento in psicologia.",
        "Sbocchi": [
            "Consulente in Psicologia delle Emergenze.",
            "Analista del Comportamento in Ambiti Forensi.",
            "Esperto in Psicologia del Benessere Organizzativo.",
            "Specialista in Neurofeedback.",
            "Coordinatore di Programmi di Intervento Comunitario.",
            "Esperto in Psicologia del Consumo e del Marketing."
        ]
    },
    {
        "nome": "Psicologia Clinica e della Riabilitazione",
        "Area": "Psicologia",
        "H1": "Corso di Laurea Magistrale Psicologia Clinica e della Riabilitazione, Università Digitale Unicusano",
        "tag 1": "Laurea Magistrale",
        "tag 2": "Durata: 2 anni",
        "tag 3": "120 cfu",
        "tag 4": "Anno accademico 2023/2024",
        "tag 5": "LM-51",
        "Descrizione": "Il corso di Laurea Magistrale in 'Psicologia Clinica e della Riabilitazione' offerto dall'Università Telematica Unicusano mira a formare esperti nei campi della psicologia clinica e della riabilitazione. Appartenente alla classe LM-51, il corso fornisce conoscenze approfondite in psicologia dello sviluppo e della formazione, psicologia sociale e del lavoro, oltre a metodologie e tecniche di indagine specifiche della psicologia clinica. Gli studenti si immergono in un curriculum che alterna conoscenze teoriche a esperienze pratiche, preparandoli ad interventi efficaci nel sistema sanitario nazionale e in ambito privato. Il piano di studi include materie come Neuropsicologia e Psicologia dell’handicap, con opzioni personalizzabili come Psicologia dello sport.",
        "Piano di studi 1": "Neuropsicologia clinica  (9 cfu), Psicologia dei processi cognitivi ed emozionali  (9 cfu), Valutazione della personalità  (6 cfu), Psicologia clinica c.a.  (9 cfu), Tecniche di ricerca e analisi dei dati  (6 cfu), Psicologia dei gruppi  (6 cfu), Psicopatologia generale  (6 cfu), Psicodiagnostica clinica  (9 cfu)",
        "Piano di studi 2": "Progettazione e valutazione dell’intervento  (6 cfu), Psicologia dello sport  (6 cfu), Psicologia della disabilità e della riabilitazione  (6 cfu), Materia a scelta dello studente  (8 cfu), Laboratorio di etica e deontologia professionale  (2 cfu), Tirocinio\t  (20 cfu), Prova finale  (12 cfu)",
        "operatore 1": "Unicusano",
        "operatore 2": "Comparacorsi",
        "operatore 3": "Altri operatori",
        "prezzo 1": "4.100€",
        "prezzo 2": "4.000€",
        "prezzo 3": "4.500€",
        "Domanda 1": "È possibile accedere al corso di Psicologia Clinica e della Riabilitazione con qualsiasi laurea triennale?",
        "Risposta 1": "L'accesso richiede una laurea triennale in classe L-24 o equivalente, ma altre lauree possono essere considerate se accompagnate dai requisiti curriculari adeguati.",
        "Domanda 2": "Come vengono erogate le lezioni nel corso online di Psicologia Clinica presso Unicusano?",
        "Risposta 2": "Le lezioni sono fornite tramite una piattaforma digitale avanzata, consentendo agli studenti di seguire il curriculum telematico in modo flessibile.",
        "Domanda 3": "Posso iscrivermi al corso di Psicologia Clinica e della Riabilitazione in qualsiasi momento dell'anno?",
        "Risposta 3": "Sì, le iscrizioni all'Università telematica Unicusano sono aperte tutto l'anno, offrendo massima flessibilità per l'inizio dei tuoi studi in psicologia clinica.",
        "Domanda 4": "Sono previsti supporti o tutoraggi durante il corso online in Psicologia Clinica?",
        "Risposta 4": "Unicusano fornisce un supporto continuo, inclusi tutoraggi e risorse online, per garantire un'apprendimento efficace nel corso di laurea magistrale.",
        "Domanda 5": "Cosa comporta la prova finale della laurea magistrale in Psicologia Clinica?",
        "Risposta 5": "La prova finale è una valutazione complessiva delle competenze e delle conoscenze acquisite, essenziale per dimostrare la tua preparazione in psicologia clinica e della riabilitazione.",
        "Sbocchi": [
            "Specialista in Psicologia Clinica Pediatrica.",
            "Consulente Psicologico in Centri di Riabilitazione Neurologica.",
            "Coordinatore di Programmi di Intervento Psicosociale.",
            "Esperto in Valutazione e Diagnosi Neuropsicologica.",
            "Terapeuta in Cliniche di Salute Mentale.",
            "Consulente Psicologico per Programmi di Riabilitazione in Ambito Carcerario."
        ]
    },
    {
        "nome": "Psicologia dello sviluppo tipico e atipico",
        "Area": "Psicologia",
        "H1": "Corso di Laurea Magistrale Psicologia dello Sviluppo Tipico e Atipico, Università Digitale Unicusano",
        "tag 1": "Laurea Magistrale",
        "tag 2": "Durata: 2 anni",
        "tag 3": "120 cfu",
        "tag 4": "Anno accademico 2023/2024",
        "tag 5": "LM-51",
        "Descrizione": "Il Corso di Laurea Magistrale in 'Psicologia dello Sviluppo Tipico e Atipico' dell’università telematica Unicusano, classe LM-51, forma specialisti capaci di comprendere e intervenire nei percorsi evolutivi. Il curriculum include neuroscienze cognitive, neuropsicologia dello sviluppo, e psicologia dei disturbi del neurosviluppo. Gli studenti approfondiscono la valutazione e l'intervento nel contesto dello sviluppo, con la possibilità di personalizzare il piano di studi. Questa laurea magistrale telematica è accessibile tutto l'anno, offrendo un percorso flessibile e avanzato in psicologia dello sviluppo presso l'Università telematica Unicusano.",
        "Piano di studi 1": "Psicobiologia dei processi cognitivi e emozionali (9 cfu), Psicologia dei processi cognitivi e emozionali (9 cfu), Psicobiologia della vulnerabilità e della resilienza (9 cfu), Psicodinamica delle relazioni familiari e sostegno alla genitorialità e alla scuola (9 cfu), Psicologia della salute nei contesti pediatrico e scolastico: tecniche di assessment e intervento (6 cfu), Psicologia della famiglia e del bambino nelle situazioni d’emergenza (6 cfu), Tecniche di ricerca e analisi dei dati (6 cfu), Neuroscienze cognitive dello sviluppo (6 cfu)",
        "Piano di studi 2": "Psicologia delle organizzazioni (6 cfu), Neuropsicologia dello sviluppo: valutazione e programmi di potenziamento (6 cfu), Psicologia clinica e disturbi psicopatologici dello sviluppo oppure Psicologia dei disturbi del neurosviluppo: valutazione e modelli di intervento evidence based (6 cfu), Materia a scelta dello studente (8 cfu), Laboratorio di etica e deontologia professionale (2 cfu), Tirocinio (20 cfu), Prova finale (12 cfu)",
        "operatore 1": "Unicusano",
        "operatore 2": "Comparacorsi",
        "operatore 3": "Altri operatori",
        "prezzo 1": "4.100€",
        "prezzo 2": "4.000€",
        "prezzo 3": "4.500€",
        "Domanda 1": "Posso accedere al corso di Psicologia dello Sviluppo se ho una laurea in un campo diverso dalla psicologia?",
        "Risposta 1": "Sì, con una laurea triennale e i requisiti curriculari adeguati, puoi accedere alla laurea magistrale telematica in Psicologia dello Sviluppo presso Unicusano.",
        "Domanda 2": "Come posso bilanciare studio e lavoro durante il corso online in Psicologia dello Sviluppo?",
        "Risposta 2": "Unicusano offre flessibilità con lezioni registrate e materiali accessibili 24 ore al giorno, permettendo di gestire lo studio secondo il tuo orario.",
        "Domanda 3": "È possibile interagire con i professori durante il corso di laurea online?",
        "Risposta 3": "Sì, Unicusano facilita l'interazione con i docenti tramite forum, e-mail e videoconferenze, assicurando supporto nel tuo percorso in psicologia dello sviluppo.",
        "Domanda 4": "Sono previste attività pratiche nel corso di Psicologia dello Sviluppo?",
        "Risposta 4": "Il corso include esperienze di laboratorio e progetti, fornendo opportunità pratiche per applicare la teoria appresa.",
        "Domanda 5": "Cosa mi prepara a fare il corso in Psicologia dello Sviluppo Tipico e Atipico?",
        "Risposta 5": "Ti prepara a comprendere e affrontare le sfide evolutive, fornendo competenze per valutare e intervenire in contesti educativi, clinici e di ricerca.",
        "Sbocchi": [
            "Psicologo Scolastico.",
            "Ricercatore in Psicologia dello Sviluppo.",
            "Consulente per Programmi Educativi Specializzati.",
            "Coordinatore di Servizi per l'Infanzia.",
            "Esperto in Interventi Precoci per Disturbi dello Sviluppo.",
            "Analista del Comportamento in Ambiti Clinici e Educativi."
        ]
    },
    {
        "nome": "Processi Cognitivi e Tecnologie (Cyberpsicologia)",
        "Area": "Psicologia",
        "H1": "Corso di Laurea Magistrale Psicologia dei Processi Cognitivi e Tecnologie (Cyberpsicologia), Università Digitale Uninettuno",
        "tag 1": "Laurea Magistrale",
        "tag 2": "Durata: 2 anni",
        "tag 3": "120 cfu",
        "tag 4": "Anno accademico 2023/2024",
        "tag 5": "LM-51",
        "Descrizione": "Il Corso di Laurea Magistrale in 'Psicologia dei Processi Cognitivi e Tecnologie (curriculum Cyberpsicologia)' di Uninettuno, classe LM-51, è un percorso innovativo che unisce psicologia, tecnologia e processi cognitivi. Si concentra su come le tecnologie influenzano la mente umana, preparando professionisti ad operare in ambiti educativi, organizzativi e clinici. Gli studenti esplorano materie come Experience Design, Intelligenza Artificiale e Ergonomia cognitiva, ricevendo una formazione completa sui meccanismi cognitivi legati all'uso delle tecnologie. Gli studenti apprenderanno a progettare e valutare interventi psicologici applicati al mondo digitale, fornendo loro le competenze necessarie per una carriera nel crescente campo della cyberpsicologia.",
        "Piano di studi 1": "Tecnologie e sostegno alla persona (8 cfu), Psicologia dello sviluppo e tecnologie (8 cfu), Psicologia sociale delle relazioni in rete (8 cfu), Experience Design (Nuova Edizione) (8 cfu), Processi cognitivi e tecnologie (8 cfu), Tecnologie e sostegno alla persona (8 cfu), Privacy, diritto e sicurezza informatica (4 cfu)",
        "Piano di studi 2": "Psicotecnologie e Processi Formativi 2 (8 cfu), Data Science (8 cfu), Psicologia della devianza online (8 cfu), Ergonomia cognitiva e Human Factor (6 cfu), Altre conoscenze utili per l'inserimento nel mondo del lavoro (3 cfu), Tirocinio pratico-valutativo TPV (20 cfu), Prova finale (15 cfu)",
        "operatore 1": "Uninettuno",
        "operatore 2": "Comparacorsi",
        "operatore 3": "Altri operatori",
        "prezzo 1": "2.200€",
        "prezzo 2": "2.100€",
        "prezzo 3": "2.500€",
        "Domanda 1": "È necessaria una conoscenza specifica in tecnologia per iscriversi al corso di Cyberpsicologia?",
        "Risposta 1": "Una comprensione di base dell'informatica è richiesta, ma il corso è progettato per accogliere studenti con vari livelli di esperienza tecnologica.",
        "Domanda 2": "Come si svolgono le lezioni online per la laurea magistrale in Cyberpsicologia?",
        "Risposta 2": "Le lezioni si svolgono tramite la piattaforma di Uninettuno, offrendo video, forum di discussione e risorse interattive per facilitare l'apprendimento.",
        "Domanda 3": "Quali competenze acquisirò nel corso di Cyberpsicologia?",
        "Risposta 3": "Acquisirai competenze nell'analisi e nell'intervento sui processi cognitivi influenzati dalla tecnologia, comprendendo l'impatto digitale sulla mente umana.",
        "Domanda 4": "Posso iscrivermi al corso di Cyberpsicologia in qualsiasi periodo dell'anno?",
        "Risposta 4": "Sì, Uninettuno offre iscrizioni aperte tutto l'anno, permettendoti di iniziare la tua laurea magistrale telematica quando preferisci.",
        "Domanda 5": "Sono previsti incontri in presenza durante il corso di laurea online?",
        "Risposta 5": "Il corso è completamente online, non sono previsti incontri in presenza, consentendo massima flessibilità agli studenti internazionali e a distanza.",
        "Sbocchi": [
            "Psicologo specializzato in Cyberpsicologia.",
            "Consulente per la sicurezza online e il comportamento digitale.",
            "Ricercatore in processi cognitivi e tecnologie.",
            "Esperto in ergonomia cognitiva e design dell'interazione.",
            "Analista di esperienza utente (UX) con specializzazione in psicologia.",
            "Formatore per la gestione delle dipendenze digitali."
        ]
    },
    {
        "nome": "Processi Cognitivi e Tecnologie (Neuroscienze)",
        "Area": "Psicologia",
        "H1": "Corso di Laurea Magistrale Psicologia dei Processi Cognitivi e Tecnologie (Neuroscienze), Università Digitale Uninettuno",
        "tag 1": "Laurea Magistrale",
        "tag 2": "Durata: 2 anni",
        "tag 3": "120 cfu",
        "tag 4": "Anno accademico 2023/2024",
        "tag 5": "LM-51",
        "Descrizione": "Il Corso di Laurea Magistrale 'Psicologia dei Processi Cognitivi e Tecnologie (curriculum Neuroscienze)' dell’università Telematica Uninettuno, LM-51, fonde neuroscienze, tecnologie e processi cognitivi. Gli studenti avranno nozioni approfondite riguardo la neuroscienza e la psicobiologia, la neuroanatomia, e i principi di neuro linguistica, applicandoli in ambiti educativi, organizzativi e clinici. Questo corso online, con iscrizioni aperte tutto l'anno, offre una formazione avanzata in neuroscienze cognitive, preparando gli studenti a interventi innovativi nei processi cognitivi coadiuvati dalle tecnologie.",
        "Piano di studi 1": "Psicologia sociale delle relazioni in rete (8 cfu), Neuroanatomia fisiologica e patologica (8 cfu), Data Science (6 cfu), Metodi di intervento nei gruppi e nelle organizzazioni (8 cfu), Neuroscienze e psicobiologia (8 cfu), Neuropsicologia clinica (8 cfu)",
        "Piano di studi 2": "Psicotecnologie e Processi Formativi 2 (8 cfu), Principi di neuroscienze cognitive (6 cfu), Valutazione neuropsicologica e neuroriabilitazione cognitiva (8 cfu), Scienza e psicoanalisi (6 cfu), Principi di neurolinguistica (4 cfu), Altre conoscenze utili per l'inserimento nel mondo del lavoro (1 cfu), Tirocinio pratico-valutativo TPV (20 cfu), Prova finale (13 cfu)",
        "operatore 1": "Uninettuno",
        "operatore 2": "Comparacorsi",
        "operatore 3": "Altri operatori",
        "prezzo 1": "2.200€",
        "prezzo 2": "2.100€",
        "prezzo 3": "2.500€",
        "Domanda 1": "Quali competenze specifiche svilupperò nel corso di Neuroscienze?",
        "Risposta 1": "Acquisirai una profonda comprensione delle neuroscienze e psicobiologia, oltre a competenze in tecnologie applicate ai processi cognitivi, preparandoti a comprendere e influenzare i comportamenti umani.",
        "Domanda 2": "Come posso bilanciare studio e lavoro con la laurea magistrale telematica?",
        "Risposta 2": "Uninettuno offre flessibilità con materiale didattico online accessibile 24/7, permettendoti di organizzare lo studio intorno agli impegni lavorativi.",
        "Domanda 3": "Sono previsti incontri faccia a faccia nel corso di Neuroscienze?",
        "Risposta 3": "Il corso è completamente online, non richiedendo incontri in presenza e permettendo di studiare da qualsiasi luogo con connessione internet.",
        "Domanda 4": "Come vengono erogate le lezioni nel corso online in Neuroscienze?",
        "Risposta 4": "Le lezioni sono fornite tramite piattaforma digitale di Uninettuno con video, forum e risorse interattive per un'esperienza di apprendimento completa.",
        "Domanda 5": "Quali requisiti sono necessari per l'ammissione al corso di Neuroscienze?",
        "Risposta 5": "Oltre a una laurea triennale in ambito psicologico o affine, è richiesta conoscenza della lingua inglese e dell'informatica per seguire il corso.",
        "Sbocchi": [
            "Ricercatore in Neuroscienze Cognitive.",
            "Psicologo Clinico specializzato in Neuroscienze.",
            "Esperto in Neuroergonomia.",
            "Consulente in Tecnologie Educative basate sulle Neuroscienze.",
            "Analista di Dati Cognitivi e Comportamentali.",
            "Specialista in Riabilitazione Neuropsicologica."
        ]
    },
    {
        "nome": "Processi Cognitivi e Tecnologie (Tecnologie di Supporto Clinico alla Persona)",
        "Area": "Psicologia",
        "H1": "Corso di Laurea Magistrale Psicologia dei Processi Cognitivi e Tecnologie (Tecnologie di Supporto Clinico alla Persona), Università Digitale Uninettuno",
        "tag 1": "Laurea Magistrale",
        "tag 2": "Durata: 2 anni",
        "tag 3": "120 cfu",
        "tag 4": "Anno accademico 2023/2024",
        "tag 5": "LM-51",
        "Descrizione": "Il Corso di Laurea Magistrale online 'Psicologia dei Processi Cognitivi e Tecnologie (Curriculum Tecnologie di Supporto Clinico alla Persona)' di Uninettuno è incentrato sull'approfondimento del processo cognitivo umano e sull'impiego dello stesso nelle tecnologie a sostegno dell'individuo. Attraverso un approccio interdisciplinare, gli studenti esplorano il sistema mente-cervello, l'interazione uomo-macchina e i nuovi scenari sociali, con un focus particolare sull’ ergonomia cognitiva e le interfacce multimodali. La laurea magistrale telematica di Uninettuno prevede anche opportunità internazionali, con corsi all'estero e collaborazioni con università straniere, arricchendo l'esperienza educativa e preparando gli studenti a contesti globali.",
        "Piano di studi 1": "Psicologia sociale delle relazioni in rete (12 cfu), Psicologia dello sviluppo e tecnologie (8 cfu), Linguaggi dei nuovi media (8 cfu), Tecnologie e sostegno alla persona (10 cfu), Processi cognitivi e tecnologie (10 cfu), Diritto e nuovi media (4 cfu)",
        "Piano di studi 2": "Tecnologie e processi di insegnamento e apprendimento (8 cfu), Psicopatologia dello Sviluppo e dello stress traumatico (8 cfu), Correlati neurobiologici dello Stress (8 cfu), Metodi di intervento nei gruppi e nelle organizzazioni (8 cfu), Valutazione delle condizioni di stress (8 cfu), Attività a scelta (8 cfu), Tirocini formativi e di orientamento (5 cfu), Prova finale (15 cfu)",
        "operatore 1": "Uninettuno",
        "operatore 2": "Comparacorsi",
        "operatore 3": "Altri operatori",
        "prezzo 1": "2.200€",
        "prezzo 2": "2.100€",
        "prezzo 3": "2.500€",
        "Domanda 1": "Come si integrano le neuroscienze con le tecnologie nel corso?",
        "Risposta 1": "Il corso unisce neuroscienze e tecnologie per esplorare come i processi cognitivi possono migliorare l'interazione uomo-macchina e supportare la persona in diversi contesti.",
        "Domanda 2": "Sono richieste conoscenze preliminari di programmazione o informatica?",
        "Risposta 2": "Una conoscenza di base è utile, ma il corso fornisce le competenze necessarie per comprendere e applicare le tecnologie ai processi cognitivi.",
        "Domanda 3": "Posso accedere al corso di laurea magistrale con una laurea non psicologica?",
        "Risposta 3": "Sì, purché tu abbia una laurea triennale e i requisiti curriculari adeguati, puoi accedere alla laurea magistrale telematica in Processi Cognitivi e Tecnologie.",
        "Domanda 4": "Come posso applicare le conoscenze acquisite in questo corso nella vita reale?",
        "Risposta 4": "Acquisirai competenze per progettare interfacce e sistemi che migliorino l'interazione tra tecnologie e funzioni cognitive, applicabili in sanità, educazione e industria.",
        "Domanda 5": "Il corso offre opportunità di tirocini o collaborazioni pratiche?",
        "Risposta 5": "Sì, il corso include tirocini e collaborazioni con laboratori e strutture esterne per applicare praticamente le conoscenze sui processi cognitivi e tecnologie.",
        "Sbocchi": [
            "Specialista in Interazione Uomo-Macchina.",
            "Esperto in Usabilità e Design di Esperienza Utente.",
            "Ricercatore in Tecnologie Cognitive.",
            "Consulente in Tecnologie Assistive per la Salute.",
            "Analista in Innovazione Tecnologica Educativa.",
            "Coordinatore di Progetti di Digitalizzazione Clinica."
        ]
    },
    {
        "nome": "Economia (Psicoeconomia)",
        "Area": "Economia",
        "H1": "Corso di Laurea in Economia (psicoeconomia), Università Digitale eCampus",
        "tag 1": "Laurea Triennale",
        "tag 2": "Durata: 3 anni",
        "tag 3": "180 cfu",
        "tag 4": "Anno accademico 2023/2024",
        "tag 5": "L-33",
        "Descrizione": "Il Corso di Laurea in Economia (Psicoeconomia) dell'Università eCampus, classe L-33, esplora l'intersezione tra processi cognitivi, economia e tecnologie. Gli studenti acquisiscono una comprensione avanzata del funzionamento dei sistemi economici, aziendali e finanziari, con un focus sulle componenti psicologiche che influenzano i comportamenti umani nei contesti lavorativi. Questo percorso telematico offre una formazione interdisciplinare, preparando gli studenti a operare efficacemente in settori come il bancario e il finanziario, con una solida base in psicologia ed economia. Con corsi online sempre disponibili, la laurea triennale in Psicoeconomia di eCampus è accessibile e flessibile, ideale per chi cerca un'educazione avanzata in questo campo emergente.",
        "Piano di studi 1": "Istituzioni di diritto (10 cfu), Diritto pubblico (4 cfu), Diritto privato (6 cfu), Macroeconomia (8 cfu), Economia aziendale (12 cfu), Storia economica (5 cfu), Psicologia generale (8 cfu), Matematica generale (9 cfu), A scelta dello studente (9 cfu)",
        "Piano di studi 2": "Organizzazione e gestione aziendale (12 cfu), Organizzazione aziendale (6 cfu), Gestione aziendale (6 cfu), Psicologia sociale (8 cfu), Macroeconomia (10 cfu), Sociologia generale (10 cfu), Lingua inglese (4 cfu), Statistica (14 cfu)",
        "Piano di studi 3": "Psicologia delle basi cognitive ed emotive della decisione (prima parte) (8 cfu), Psicologia delle basi cognitive ed emotive della decisione (seconda parte) (4 cfu), Mercati finanziari internazionali (10 cfu), Psicologia del lavoro e delle organizzazioni (8 cfu), Imprese e mercati (10 cfu), Diritto del lavoro (6 cfu), Prova finale (5 cfu), Tirocini (10 cfu)",
        "operatore 1": "eCampus",
        "operatore 2": "Comparacorsi",
        "operatore 3": "Altri operatori",
        "prezzo 1": "3.900€",
        "prezzo 2": "2.600€",
        "prezzo 3": "4.100€",
        "Domanda 1": "Quali competenze specifiche svilupperò nel corso di Psicoeconomia?",
        "Risposta 1": "Acquisirai una comprensione approfondita dei processi cognitivi e del loro impatto sulle decisioni economiche, imparando a integrare psicologia ed economia per analizzare e interpretare comportamenti umani e di mercato.",
        "Domanda 2": "Come vengono erogate le lezioni nel corso online di Economia?",
        "Risposta 2": "Le lezioni sono fornite tramite la piattaforma telematica di eCampus, offrendo materiale didattico interattivo e flessibile accessibile da qualsiasi luogo.",
        "Domanda 3": "È possibile bilanciare studio e lavoro durante il corso di laurea online?",
        "Risposta 3": "Sì, grazie alle modalità offerte dall'università telematica, la laurea in Economia online di eCampus è progettata per essere flessibile, consentendo agli studenti di gestire il proprio tempo tra studio e impegni lavorativi.",
        "Domanda 4": "Ci sono opportunità di interazione con i professori e altri studenti?",
        "Risposta 4": "Certo, eCampus promuove l'interazione tramite forum, chat e videoconferenze, permettendo scambi e discussioni tra studenti e docenti.",
        "Domanda 5": "Che tipo di supporto offre eCampus agli studenti online?",
        "Risposta 5": "eCampus fornisce supporto accademico e tecnico continuo, inclusi tutor dedicati, assistenza tecnica e risorse didattiche sempre aggiornate.",
        "Sbocchi": [
            "Analista del Comportamento del Consumatore.",
            "Consulente in Strategie di Mercato e Psicologia del Consumo.",
            "Ricercatore in Psicoeconomia e Decision Making.",
            "Esperto in Comunicazione e Marketing Emotivo.",
            "Specialista in Politiche Pubbliche e Analisi del Comportamento Economico.",
            "Professionista in Risorse Umane con Focus su Motivazione e Prestazione."
        ]
    },
    {
        "nome": "Economia (Scienze Bancarie e Assicurative)",
        "Area": "Economia",
        "H1": "Corso di Laurea in Scienze bancarie e assicurative (economia), Università Digitale eCampus",
        "tag 1": "Laurea Triennale",
        "tag 2": "Durata: 3 anni",
        "tag 3": "180 cfu",
        "tag 4": "Anno accademico 2023/2024",
        "tag 5": "L-33",
        "Descrizione": "Il Corso di Laurea Triennale online in Scienze Bancarie e Assicurative offerto da eCampus, classe L-33, è progettato per formare specialisti nel dinamico mondo dell'economia, con un focus particolare sul settore bancario e assicurativo. Attraverso un curriculum approfondito che copre aree economiche, aziendali e giuridiche, gli studenti acquisiscono una comprensione critica dei sistemi economici e delle loro interazioni con le normative. Il corso si compone di 180 CFU, preparando i laureandi a interpretare e influenzare una realtà economica in continuo cambiamento. Con la laurea triennale di eCampus, gli studenti sono pronti per affrontare le sfide del settore bancario e assicurativo, equipaggiati con conoscenze e competenze per gestire con successo le problematiche contemporanee e contribuire allo sviluppo del settore.",
        "Piano di studi 1": "Economia aziendale (9 cfu), Storia economica (9 cfu), Metodi matematici (6 cfu), Statistica (9 cfu), Microeconomia (9 cfu), Lingua inglese (5 cfu), Economia e gestione delle imprese (9 cfu), A scelta tra: Sociologia dei processi economici e del lavoro (intelligenze manageriali) (6 cfu), Dalla societa’ di massa al capitale sociale (6 cfu)",
        "Piano di studi 2": "Diritto commerciale (9 cfu), Economia degli intermediari finanziari (9 cfu), Finanza aziendale (9 cfu), Ragioneria e contabilita’ aziendale (6 cfu), Macroeconomia (9 cfu), Diritto bancario (9 cfu), Matematica finanziaria (6 cfu)",
        "Piano di studi 3": "Abilità informatiche e telematiche (2 cfu), Gestione dei rischi finanziari (6 cfu), Istituzioni di diritto pubblico (6 cfu), Economia delle imprese di assicurazione e della previdenza (9 cfu), Scienza delle finanze (9 cfu), Economia applicata (9 cfu), Seminario “La gestione del colloquio individuale e di gruppo per l’inserimento nel mondo del lavoro” (2 cfu), Prova finale (6 cfu), A scelta (12 cfu)",
        "operatore 1": "eCampus",
        "operatore 2": "Comparacorsi",
        "operatore 3": "Altri operatori",
        "prezzo 1": "3.900€",
        "prezzo 2": "2.600€",
        "prezzo 3": "4.100€",
        "Domanda 1": "Quali competenze svilupperò nel corso di Scienze Bancarie e Assicurative?",
        "Risposta 1": "Acquisirai competenze nel comprendere e gestire sistemi economici, aziendali e finanziari, con una particolare attenzione al settore bancario e assicurativo, integrando principi economici e giuridici.",
        "Domanda 2": "Come vengono erogate le lezioni nel corso online di eCampus?",
        "Risposta 2": "Le lezioni sono fornite tramite la piattaforma telematica di eCampus, con accesso a materiali didattici, video-lezioni e risorse interattive, offrendo flessibilità e supporto continuo.",
        "Domanda 3": "Come posso bilanciare studio e lavoro con la laurea online?",
        "Risposta 3": "eCampus progetta i suoi corsi per essere flessibili, permettendoti di studiare quando e dove preferisci, ideale per chi deve conciliare impegni lavorativi con lo studio.",
        "Domanda 4": "Quali sono i requisiti di ammissione al corso di Scienze Bancarie e Assicurative?",
        "Risposta 4": "È necessario un diploma di scuola secondaria superiore o equivalente, conoscenze di base in matematica e informatica, e una comprensione della lingua inglese.",
        "Domanda 5": "Ci sono opportunità di interazione diretta con i professori?",
        "Risposta 5": "Sì, eCampus incoraggia l'interazione tra studenti e docenti tramite forum, chat e sessioni di videoconferenza per garantire un apprendimento coinvolgente e supportato.",
        "Sbocchi": [
            "Analista Finanziario in Banche e Assicurazioni.",
            "Consulente per la Gestione del Rischio.",
            "Esperto in Compliance Bancaria e Assicurativa.",
            "Specialista in Prodotti Bancari e Assicurativi.",
            "Responsabile di Filiale Bancaria.",
            "Agente di Assicurazione e Brokeraggio."
        ]
    },
    {
        "nome": "Economia (start-up d'impresa e modelli di business)",
        "Area": "Economia",
        "H1": "Corso di Laurea in Modelli di business e startup d'impresa, Università Digitale eCampus",
        "tag 1": "Laurea Triennale",
        "tag 2": "Durata: 3 anni",
        "tag 3": "180 cfu",
        "tag 4": "Anno accademico 2023/2024",
        "tag 5": "L-33",
        "Descrizione": "Il Corso di Laurea Triennale in Economia, con specializzazione in Start-up d'Impresa e Modelli di Business di eCampus, classe L-33, forma esperti capaci di innovare nel mercato, coniugando i classici principi di economia e modelli di business innovativi. Attraverso un approccio multidisciplinare, gli studenti apprendono come elaborare idee imprenditoriali e gestire progetti di startup, comprendendo dinamiche economiche e finanziarie. Con 180 CFU, il corso online offre un modello di studio innovativo su materie economiche, aziendali e giuridiche, integrate con attività pratiche, esempi concreti e opportunità di stage. La laurea online offerta dall’università telematica eCampus è un ottimo esempio di come si possano armonizzare i nuovi saperi con gli storici insegnamenti in materia per guidare l'innovazione e lo sviluppo nel settore delle start-up, del modello di business del futuro e oltre.",
        "Piano di studi 1": "Economia aziendale (9 cfu), Storia economica (9 cfu), Metodi matematici (6 cfu), Statistica (9 cfu), Microeconomia (9 cfu), Lingua inglese (5 cfu), Business plan ed economia delle imprese di start-up (6 cfu) uno a scelta tra: Sociologia dei processi economici e del lavoro (intelligenze manageriali) (6 cfu), Dalla societa' di massa al capitale sociale (6 cfu)",
        "Piano di studi 2": "Diritto commerciale (9 cfu), Economia degli intermediari finanziari (9 cfu), Finanza aziendale (9 cfu), Ragioneria e contabilita’ aziendale (9 cfu), Macroeconomia (9 cfu), Gli istituti giuridici della start-up (9 cfu), Matematica finanziaria (6 cfu)",
        "Piano di studi 3": "Abilità informatiche e telematiche (2 cfu), Strategie d'azienda e spin-off (6 cfu), Istituzioni di diritto pubblico (9 cfu), Politica economica (6 cfu), Scienza delle finanze (9 cfu), Economia applicata (9 cfu), Seminario \"la gestione del colloquio individuale e di gruppo per l'inserimento nel mondo del lavoro\" (2 cfu), Prova finale (6 cfu), A scelta (12 cfu)",
        "operatore 1": "eCampus",
        "operatore 2": "Comparacorsi",
        "operatore 3": "Altri operatori",
        "prezzo 1": "3.900€",
        "prezzo 2": "2.600€",
        "prezzo 3": "4.100€",
        "Domanda 1": "In che modo il corso online di Start-up d'Impresa e Modelli di Business si distingue dagli altri corsi di economia?",
        "Risposta 1": "Si focalizza su innovativi modelli di business e l'avvio di start-up, integrando conoscenze economiche, finanziarie e giuridiche con un forte accento sull'innovazione e l'imprenditorialità.",
        "Domanda 2": "Come vengono erogate le lezioni nel corso online di eCampus?",
        "Risposta 2": "Le lezioni sono fornite tramite piattaforma telematica di eCampus, con video-lezioni, esercitazioni interattive e materiali didattici accessibili da qualsiasi luogo.",
        "Domanda 3": "Quali sono i prerequisiti per iscriversi a questo corso di laurea online?",
        "Risposta 3": "Oltre al diploma di scuola secondaria superiore, è richiesta una conoscenza di base di matematica e informatica e una comprensione della lingua inglese.",
        "Domanda 4": "Sono previste opportunità di apprendimento pratico nel corso?",
        "Risposta 4": "Sì, il corso include esercitazioni pratiche, studio di casi e opportunità di stage per applicare concretamente le conoscenze acquisite.",
        "Domanda 5": "Ci sono opportunità di interazione e networking con altri studenti e professionisti?",
        "Risposta 5": "eCampus promuove attivamente il networking tra studenti e professionisti attraverso forum, seminari online e gruppi di studio.",
        "Sbocchi": [
            "Imprenditore di Start-up",
            "Business Analyst per Start-up e PMI.",
            "Consulente di Strategia Aziendale.",
            "Manager di Sviluppo Commerciale.",
            "Esperto in Finanziamenti e Crowdfunding.",
            "Specialista in Incubatori e Acceleratori di Imprese."
        ]
    },
    {
        "nome": "Economia e Commercio",
        "Area": "Economia",
        "H1": "Corso di Laurea in Economia e commercio, Università Digitale eCampus",
        "tag 1": "Laurea Triennale",
        "tag 2": "Durata: 3 anni",
        "tag 3": "180 cfu",
        "tag 4": "Anno accademico 2023/2024",
        "tag 5": "L-33",
        "Descrizione": "Il Corso di Laurea Triennale in Economia e Commercio di eCampus, classe L-33, offre una formazione completa su sistemi economici, aziendali e finanziari. Con un forte focus su legislazioni che riguardano aziende e mercati, il corso prepara gli studenti a comprendere e gestire realtà economiche in evoluzione. Attraverso 180 CFU, il programma include materie economiche, aziendali, giuridiche e matematico-statistiche, fornendo una solida base teorica e pratica. La laurea triennale online di eCampus è ideale per chi mira a una carriera nel commercio e nelle imprese, offrendo flessibilità e un ampio spettro di conoscenze applicabili al mondo aziendale.",
        "Piano di studi 1": "Economia Aziendale (9 cfu), Storia Economica (9 cfu), Metodi Matematici (6 cfu), Statistica (9 cfu), Microeconomia (9 cfu), Lingua Inglese (5 cfu), Gestione Delle Imprese E Marketing (6 cfu), Uno A Scelta Tra: Mercati Finanziari Internazionali (6 cfu), Imprese E Mercati (6 cfu)",
        "Piano di studi 2": "Diritto Commerciale (9 cfu), Analisi Di Bilancio (6 cfu), Ragioneria E Contabilita’ Aziendale (9 cfu), Macroeconomia (9 cfu), Economia Degli Intermediari Finanziari (9 cfu), Matematica Finanziaria (6 cfu), Finanza Aziendale (9 cfu)",
        "Piano di studi 3": "Abilità Informatiche E Telematiche (2 cfu), Economia Del Capitale Umano D’impresa (6 cfu), Istituzioni Di Diritto Pubblico (9 cfu), Diritto Bancario (9 cfu), Scienza Delle Finanze (9 cfu), Economia Applicata (9 cfu), Seminario “La Gestione Del Colloquio Individuale E Di Gruppo Per L’inserimento Nel Mondo Del Lavoro” (2 cfu), Prova Finale (6 cfu), A Scelta (12 cfu)",
        "operatore 1": "eCampus",
        "operatore 2": "Comparacorsi",
        "operatore 3": "Altri operatori",
        "prezzo 1": "3.900€",
        "prezzo 2": "2.600€",
        "prezzo 3": "4.100€",
        "Domanda 1": "Quali competenze svilupperò nel corso di Economia e Commercio?",
        "Risposta 1": "Acquisirai competenze nel comprendere e gestire sistemi economici e aziendali, con un'enfasi sulle principali discipline economiche, giuridiche e matematico-statistiche, cruciali nel mondo del commercio.",
        "Domanda 2": "Come vengono erogate le lezioni nel corso online di eCampus?",
        "Risposta 2": "Le lezioni sono fornite tramite la piattaforma telematica di eCampus, con materiali didattici, video-lezioni e risorse interattive, accessibili ovunque tu sia.",
        "Domanda 3": "È possibile bilanciare studio e lavoro con la laurea online in Economia e Commercio?",
        "Risposta 3": "Sì, la laurea online di eCampus è progettata per essere flessibile, permettendo agli studenti di organizzare studio e impegni lavorativi secondo le proprie esigenze.",
        "Domanda 4": "Quali sono i requisiti di ammissione per il corso di Economia e Commercio?",
        "Risposta 4": "È necessario un diploma di scuola secondaria superiore, conoscenze di base in matematica e informatica, e una comprensione dell'inglese.",
        "Domanda 5": "Il corso di Economia e Commercio prevede interazioni e discussioni con altri studenti?",
        "Risposta 5": "Sì, eCampus promuove l'interazione tra studenti attraverso forum, chat e gruppi di studio, arricchendo l'esperienza formativa con scambi e discussioni.",
        "Sbocchi": [
            "Analista Finanziario.",
            "Consulente Aziendale.",
            "Responsabile del Controllo di Gestione.",
            "Esperto in Commercio Internazionale.",
            "Funzionario di Banca Commerciale.",
            "Responsabile di Pianificazione e Strategia Aziendale."
        ]
    },
    {
        "nome": "Economia, Management e Mercati Internazionali",
        "Area": "Economia",
        "H1": "Corso di Laurea in Mercati internazionali, economia e management Università Digitale IUL",
        "tag 1": "Laurea Triennale",
        "tag 2": "Durata: 3 anni",
        "tag 3": "180 cfu",
        "tag 4": "Anno accademico 2023/2024",
        "tag 5": "L-33",
        "Descrizione": "Il Corso di Laurea in Economia, Management e Mercati Internazionali dell'Università Telematica IUL mira a formare laureati con una visione globale del mondo economico e aziendale. Offre competenze trasversali nelle discipline economiche, con un forte focus sui mercati internazionali. Gli studenti impareranno a navigare i complessi sistemi economici nazionali e internazionali, adottando approcci innovativi e tecnologici per affrontare sfide globali. La laurea online fornisce un'ampia base di conoscenze giuridiche e linguistiche, essenziali per operare efficacemente in contesti internazionali. Con un'approfondita comprensione di economia, management e commercio, i laureati saranno attrezzati per diventare leader nel settore.",
        "Piano di studi 1": "Istituzioni di economia (9 cfu), Introduzione alla matematica e principi di matematica finanziaria (9 cfu), Istituzioni di diritto pubblico (6 cfu), Economia aziendale (9 cfu), Organizzazione industriale: teoria e regolamentazione (9 cfu), Lingua inglese (9 cfu), Laboratorio applicato (altre conoscenze utili per il mondo del lavoro) (3 cfu), Abilità informatiche (6 cfu)",
        "Piano di studi 2": "Diritto privato (9 cfu), Contabilità e bilancio (9 cfu), Economia e gestione delle imprese internazionali (6 cfu), Geografia politica ed economia internazionale (9 cfu), Analisi dei dati economici e finanziari (9 cfu), Economia applicata: industrie, mercati, regioni (9 cfu), Politica economica (9 cfu)",
        "Piano di studi 3": "Economia degli intermediari finanziari (9 cfu), Dinamiche della popolazione (6 cfu), Diritto comunitario e del commercio internazionale (9 cfu), Economia delle istituzioni e delle organizzazioni internazionali (6 cfu), Modelli di gestione delle aziende nazionali ed internazionali (9 cfu), Laboratorio applicato (altre conoscenze utili per il mondo del lavoro) (3 cfu), Esami a scelta* (15 cfu), Prova Finale (3 cfu)",
        "operatore 1": "IUL",
        "operatore 2": "Comparacorsi",
        "operatore 3": "Altri operatori",
        "prezzo 1": "2.500€",
        "prezzo 2": "2.300€",
        "prezzo 3": "2.850€",
        "Domanda 1": "Come si focalizza sui mercati internazionali il corso in Economia, Management e Mercati Internazionali?",
        "Risposta 1": "Il corso di laurea esplora la dinamica dei mercati internazionali, insegnando come le economie globali interagiscono e influenzano il commercio e l'investimento internazionale.",
        "Domanda 2": "Quali strumenti didattici sono utilizzati nel corso online?",
        "Risposta 2": "IUL utilizza una piattaforma interattiva che offre video-lezioni, forum di discussione, esercitazioni online e materiali didattici accessibili in qualsiasi momento.",
        "Domanda 3": "Il corso offre competenze linguistico-culturali oltre all'economia?",
        "Risposta 3": "Sì, include studi di lingua inglese e traduzione, essenziali per comprendere e operare efficacemente in contesti economici internazionali.",
        "Domanda 4": "Come posso conciliare gli studi con il lavoro?",
        "Risposta 4": "La laurea online offre flessibilità, permettendoti di studiare secondo il tuo ritmo e orari, ideale per chi lavora o ha altri impegni.",
        "Domanda 5": "Sono previsti esami orali o scritti nel corso online?",
        "Risposta 5": "Il corso prevede esami finali scritti e orali per valutare la comprensione e l'applicazione delle conoscenze acquisite.",
        "Sbocchi": [
            "Analista di Mercato Internazionale.",
            "Consulente per il Commercio Estero.",
            "Responsabile di Sviluppo Aziendale Globale.",
            "Esperto in Politiche Economiche Internazionali.",
            "Manager di Strategie di Internazionalizzazione.",
            "Funzionario in Organizzazioni Economiche Multilaterali."
        ]
    },
    {
        "nome": "Economia Aziendale e Management",
        "Area": "Economia",
        "H1": "Corso di Laurea in Economia aziendale e management, Università Digitale Unimarconi",
        "tag 1": "Laurea Triennale",
        "tag 2": "Durata: 3 anni",
        "tag 3": "180 cfu",
        "tag 4": "Anno accademico 2023/2024",
        "tag 5": "L-18",
        "Descrizione": "Il Corso di Laurea in Economia Aziendale e Management di Unimarconi, classe L-18, mira a formare professionisti con una solida conoscenza dei processi aziendali e gestionali. Con un approccio multidisciplinare, gli studenti acquisiscono strumenti per comprendere, gestire e amministrare imprese. Il curriculum si adatta a diversi settori, fornendo basi in economia, management, finanza e diritto. L'obiettivo è sviluppare una cultura economico-aziendale che consenta agli studenti di analizzare e rispondere ai cambiamenti dei sistemi economici e organizzativi. Con 180 CFU, il corso prepara gli studenti a interpretare complessità economiche e gestionali, rendendoli pronti per sfide nazionali e internazionali nel mondo aziendale e oltre.",
        "Piano di studi 1": "Economia Politica (12 cfu), Diritto Privato (6 cfu), Economia Aziendale (6 cfu), Matematica Generale (6 cfu), Metodologie e Determinazioni Quantitative D’azienda (12 cfu), Statistica (12 cfu), Abilità informatiche (6 cfu)",
        "Piano di studi 2": "Diritto Commerciale (12 cfu), Diritto Tributario (6 cfu), Economia e Gestione delle Imprese (6 cfu), Matematica Finanziaria (6 cfu), Organizzazione Aziendale (12 cfu), Politica Economica (12 cfu), Idoneità Linguistica: Inglese (6 cfu)",
        "Piano di studi 3": "Finanza Aziendale (12 cfu), Strategia, Gestione e Sviluppo dell’innovazione (6 cfu), 18 CFU a scelta tra: Marketing (6 cfu), Startup Strategy (6 cfu), Economia e Politica Industriale (6 cfu), Strategia e Politica Aziendale (6 cfu), Economia degli Intermediari Finanziari (6 cfu), Diritto Bancario (6 cfu), Diritto Amministrativo (12 cfu), Diritto del Lavoro (12 cfu), Statistica e Demografia (12 cfu), Relazioni Istituzionali (6 cfu), Scelta libera (6+6 cfu), Altre attività: tirocinio, seminari, ulteriori conoscenze linguistiche, laboratori (6 cfu), Tesi di Laurea (6 cfu)",
        "operatore 1": "Unimarconi",
        "operatore 2": "Comparacorsi",
        "operatore 3": "Altri operatori",
        "prezzo 1": "2.760€",
        "prezzo 2": "1.500€",
        "prezzo 3": "2.900€",
        "Domanda 1": "Quali competenze specifiche sviluppo nel corso di Economia Aziendale e Management?",
        "Risposta 1": "Acquisirai una solida comprensione dei principi di economia aziendale, strategie di management, e strumenti per l'analisi finanziaria e amministrativa, cruciali per il successo aziendale.",
        "Domanda 2": "Come vengono erogate le lezioni nel corso online di Unimarconi?",
        "Risposta 2": "Le lezioni sono fornite tramite la piattaforma telematica di Unimarconi, offrendo accesso a materiali didattici, video-lezioni e forum interattivi per un apprendimento flessibile e interattivo.",
        "Domanda 3": "Posso conciliare il corso con impegni lavorativi o personali?",
        "Risposta 3": "Sì, la laurea online di Unimarconi è progettata per offrire massima flessibilità, permettendoti di studiare in base ai tuoi tempi e necessità.",
        "Domanda 4": "Quali sono i requisiti di ammissione per il corso di Economia Aziendale e Management?",
        "Risposta 4": "È necessario un diploma di scuola secondaria superiore e una conoscenza di base in matematica e inglese.",
        "Domanda 5": "Il corso include progetti o esercitazioni pratiche?",
        "Risposta 5": "Sì, oltre alle lezioni teoriche, il corso prevede esercitazioni pratiche e progetti per applicare concretamente le conoscenze acquisite.",
        "Sbocchi": [
            "Analista Finanziario.",
            "Manager delle Risorse Umane.",
            "Consulente Aziendale.",
            "Responsabile Controllo di Gestione.",
            "Esperto in Marketing Strategico.",
            "Direttore Amministrativo."
        ]
    },
    {
        "nome": "Economia Aziendale e Management (Amministrazione e Finanza)",
        "Area": "Economia",
        "H1": "Corso di Laurea in Amministrazione e finanza (economia aziendale e management), Università Digitale Unimarconi",
        "tag 1": "Laurea Triennale",
        "tag 2": "Durata: 3 anni",
        "tag 3": "180 cfu",
        "tag 4": "Anno accademico 2023/2024",
        "tag 5": "L-18",
        "Descrizione": "Il Corso di Laurea in Economia Aziendale e Management, specializzazione Amministrazione e Finanza presso l'Università Telematica Guglielmo Marconi, è strutturato per fornire una formazione approfondita e multidisciplinare nel campo dell'economia aziendale. Il programma si concentra sull'acquisizione di competenze analitiche e manageriali, indispensabili per interpretare e gestire efficacemente le dinamiche finanziarie e amministrative all'interno delle organizzazioni. Il corso online afferisce alla classe L-18 e attraverso un curriculum ben bilanciato, che spazia dall'economia all'analisi finanziaria, dalla gestione aziendale al diritto, gli studenti sono preparati a comprendere e affrontare le complessità del mondo aziendale e finanziario contemporaneo.",
        "Piano di studi 1": "Economia Politica (12 cfu), Diritto Privato (6 cfu), Economia Aziendale (6 cfu), Matematica Generale (6 cfu), Metodologie e Determinazioni Quantitative D’azienda (12 cfu), Statistica (12 cfu), Abilità informatiche (6 cfu)",
        "Piano di studi 2": "Diritto Commerciale (12 cfu), Diritto Tributario (6 cfu), Economia e Gestione delle Imprese (6 cfu), Matematica Finanziaria (6 cfu), Organizzazione Aziendale (12 cfu), Politica Economica (12 cfu), Idoneità Linguistica: Inglese (6 cfu)",
        "Piano di studi 3": "Finanza Aziendale (12 cfu), 6 CFU a scelta tra: Strategia, Gestione e Sviluppo dell’innovazione (6 cfu), Bilanci e Certificazioni Aziendali (6 cfu), Analisi Finanziaria di Bilancio (6 cfu), 18 CFU a scelta tra: Diritto Amministrativo (12 cfu), Economia Monetaria (6 cfu), Diritto Bancario (6 cfu), Diritto del Mercato Finanziario (6 cfu), Economia degli Intermediari Finanziari (6 cfu), Strategia e Politica Aziendale (6 cfu), Diritto pubblico dell’economia (18 cfu), Scelta libera (6+6 cfu), Altre attività: tirocinio, seminari, ulteriori conoscenze linguistiche, laboratori (6 cfu), Tesi di Laurea (6 cfu)",
        "operatore 1": "Unimarconi",
        "operatore 2": "Comparacorsi",
        "operatore 3": "Altri operatori",
        "prezzo 1": "2.760€",
        "prezzo 2": "1.500€",
        "prezzo 3": "2.900€",
        "Domanda 1": "Quali competenze specifiche sviluppo nel curriculum Amministrazione e Finanza?",
        "Risposta 1": "Acquisirai una profonda comprensione dei principi di economia aziendale, strategie di gestione finanziaria e tecniche di amministrazione aziendale essenziali per il successo nel settore.",
        "Domanda 2": "Come viene garantita l'interazione con docenti e studenti nel corso online?",
        "Risposta 2": "Unimarconi utilizza una piattaforma interattiva che facilita la comunicazione diretta con docenti e la collaborazione con altri studenti attraverso forum e gruppi di studio.",
        "Domanda 3": "È possibile accedere al corso senza una conoscenza avanzata di matematica?",
        "Risposta 3": "Sì, il corso è aperto a tutti, ma una conoscenza di base di matematica è consigliata. Unimarconi offre risorse per colmare eventuali lacune.",
        "Domanda 4": "Il corso prevede progetti o casi studio reali?",
        "Risposta 4": "Sì, oltre alle lezioni teoriche, sono previste esercitazioni pratiche, analisi di casi reali e progetti per applicare concretamente le conoscenze acquisite.",
        "Domanda 5": "Come si svolgono gli esami nel corso online?",
        "Risposta 5": "Gli esami sono organizzati online con modalità diverse a seconda del corso, includendo prove scritte, orali e valutazioni di progetti o esercitazioni.",
        "Sbocchi": [
            "Responsabile Finanziario.",
            "Analista di Business.",
            "Consulente Strategico Aziendale.",
            "Direttore delle Risorse Umane.",
            "Auditor Interno.",
            "Manager di Progetto."
        ]
    },
    {
        "nome": "Economia Aziendale e Management (Consulente del Lavoro)",
        "Area": "Economia",
        "H1": "Corso di Laurea in Consulente del lavoro (economia aziendale e management), Università Digitale Unimarconi",
        "tag 1": "Laurea Triennale",
        "tag 2": "Durata: 3 anni",
        "tag 3": "180 cfu",
        "tag 4": "Anno accademico 2023/2024",
        "tag 5": "L-18",
        "Descrizione": "Il Corso di Laurea online in Economia Aziendale e Management, curriculum Consulente del Lavoro presso l'Università Telematica Unimarconi, classe L-18, mira a formare professionisti con una solida preparazione in ambito economico, aziendale e manageriale da inserire nel mondo del lavoro. Gli studenti vengono dotati di strumenti e conoscenze multidisciplinari indispensabili per comprendere e gestire i processi aziendali, con un focus particolare sulle dinamiche di amministrazione e gestione del personale. Il curriculum è progettato per fornire competenze specifiche nel settore della consulenza del lavoro, abilitando i laureati a interpretare e dare orientamento circa la normativa del lavoro in contesti aziendali diversificati.",
        "Piano di studi 1": "Economia Politica (12 cfu), Diritto Privato (6 cfu), Economia Aziendale (6 cfu), Matematica Generale (6 cfu), Metodologie e Determinazioni Quantitative D’azienda (12 cfu), Statistica (12 cfu), Abilità informatiche (6 cfu)",
        "Piano di studi 2": "Diritto Commerciale (12 cfu), Diritto Tributario (6 cfu), Economia e Gestione delle Imprese (6 cfu), Matematica Finanziaria (6 cfu), Organizzazione Aziendale (12 cfu), Politica Economica (12 cfu), Idoneità Linguistica: Inglese (6 cfu)",
        "Piano di studi 3": "Finanza Aziendale (12 cfu), Diritto del Lavoro (12 cfu), 6 CFU A Scelta Tra: Strategia, Gestione e Sviluppo dell’innovazione (6 cfu), Bilanci e Certificazioni Aziendali (6 cfu), Analisi Finanziaria di Bilancio (6 cfu) 6 CFU a scelta tra: Diritto del Lavoro nella Pubblica Amministrazione (6 cfu), Diritto Sindacale (6 cfu), Legislazione Previdenziale (6 cfu), Sociologia del Lavoro (6 cfu) Scelta libera (6+6 cfu), Altre attività: tirocinio, seminari, ulteriori conoscenze linguistiche, laboratori (6 cfu), Tesi di Laurea (6 cfu)",
        "operatore 1": "Unimarconi",
        "operatore 2": "Comparacorsi",
        "operatore 3": "Altri operatori",
        "prezzo 1": "2.760€",
        "prezzo 2": "1.500€",
        "prezzo 3": "2.900€",
        "Domanda 1": "Quali competenze informatiche sono necessarie per il curriculum Consulente del Lavoro in Economia Aziendale e Management?",
        "Risposta 1": "Una conoscenza base di informatica è richiesta, con formazione aggiuntiva fornita durante il corso per supportare la gestione aziendale e la consulenza del lavoro.",
        "Domanda 2": "Come funziona il test orientativo non selettivo all'Università Telematica Guglielmo Marconi?",
        "Risposta 2": "Il test si occupa di valutare le competenze in ingresso degli studenti per personalizzare il loro percorso di studi e può essere svolto online dopo l'immatricolazione.",
        "Domanda 3": "È possibile conciliare lavoro e studio in questo corso di laurea triennale?",
        "Risposta 3": "Sì, l'approccio flessibile e online dell'università consente agli studenti di adattare lo studio agli impegni lavorativi.",
        "Domanda 4": "Quanto è importante la lingua inglese nel curriculum Consulente del Lavoro?",
        "Risposta 4": "È fondamentale per comprendere testi specialistici e operare in contesti lavorativi internazionali.",
        "Domanda 5": "Ci sono opportunità di interazione con docenti e studenti nel corso?",
        "Risposta 5": "Sì, la piattaforma MyUnimarconi promuove attivamente la comunicazione e la collaborazione tra studenti, docenti e tutor.",
        "Sbocchi": [
            "Responsabile delle Risorse Umane",
            "Consulenti del lavoro",
            "Analista di Controllo di Gestione",
            "Esperto Contabile",
            "Risk Manager Aziendale",
            "Responsabile della Pianificazione e Analisi Finanziaria"
        ]
    },
    {
        "nome": "Economia Aziendale e Management (Management dello Sport e degli Eventi Sportivi)",
        "Area": "Economia",
        "H1": "Corso di Laurea in Management dello Sport e degli Eventi Sportivi (economia aziendale e management), Università Digitale Unimarconi",
        "tag 1": "Laurea Triennale",
        "tag 2": "Durata: 3 anni",
        "tag 3": "180 cfu",
        "tag 4": "Anno accademico 2023/2024",
        "tag 5": "L-18",
        "Descrizione": "Il curriculum di Management dello Sport e degli Eventi Sportivi del Corso di Laurea Triennale online in Economia Aziendale e Management di Unimarconi è progettato per preparare gli studenti a carriere nel settore sportivo. Focalizzandosi sull'amministrazione e finanza, il corso offre una formazione multidisciplinare, coprendo l'ambito economico, aziendale, manageriale e giuridico. Gli studenti acquisiscono 180 CFU, approfondendo aree funzionali come marketing, finanza e risorse umane, e settori specifici come eventi sportivi. Il percorso, nella classe di laurea L-18, mira a sviluppare conoscenze organizzative per operare in ambiti professionali diversificati, dai ruoli HR al management sportivo. L'accesso è aperto tutto l'anno, senza test d'ingresso, e supporta gli studenti con valutazioni di competenza.",
        "Piano di studi 1": "Economia Politica (12 cfu), Diritto Privato (6 cfu), Economia Aziendale (6 cfu), Matematica Generale (6 cfu), Metodologie e Determinazioni Quantitative D’azienda (12 cfu), Statistica (12 cfu), Abilità informatiche (6 cfu)",
        "Piano di studi 2": "Diritto Commerciale (12 cfu), Diritto Tributario (6 cfu), Economia e Gestione delle Imprese (6 cfu), Matematica Finanziaria (6 cfu), Organizzazione Aziendale (12 cfu), Politica Economica (12 cfu), Idoneità Linguistica: Inglese (6 cfu)",
        "Piano di studi 3": "Management delle Società e degli Impianti Sportivi (6 cfu), 12 CFU a scelta tra: Finanza Aziendale (12 cfu), Economia e Gestione delle Imprese Sportive (6 cfu), Strategia, Gestione e Sviluppo dell’innovazione (6 cfu), 18 CFU a scelta tra: Marketing (6 cfu), Diritto dello Sport (6 cfu), Organizzazione e comunicazione degli eventi sportivi (6 cfu), Beni contratti e circolazione dei diritti (6 cfu), Diritto del Lavoro (12 cfu), Diritto amministrativo (12 cfu), Scelta libera (6+6 cfu), Altre attività: tirocinio, seminari, ulteriori conoscenze linguistiche, laboratori (6 cfu), Tesi di Laurea (6 cfu)",
        "operatore 1": "Unimarconi",
        "operatore 2": "Comparacorsi",
        "operatore 3": "Altri operatori",
        "prezzo 1": "2.760€",
        "prezzo 2": "1.500€",
        "prezzo 3": "2.900€",
        "Domanda 1": "Quali competenze svilupperò nel curriculum Management dello Sport?",
        "Risposta 1": "Acquisirai conoscenze approfondite in economia aziendale e management specifiche per il settore sportivo, incluse competenze organizzative, di marketing sportivo, e di gestione finanziaria degli eventi sportivi.",
        "Domanda 2": "Posso iscrivermi al corso in qualsiasi periodo dell'anno?",
        "Risposta 2": "Sì, l'Università Telematica Unimarconi permette iscrizioni aperte durante tutto l'anno, offrendo flessibilità agli studenti.",
        "Domanda 3": "Come viene valutata la mia preparazione iniziale?",
        "Risposta 3": "Unimarconi fornisce un test orientativo non selettivo dopo l'immatricolazione per valutare le tue competenze in ingresso e offrire percorsi di supporto personalizzati.",
        "Domanda 4": "Il corso include materie pratiche oltre alla teoria?",
        "Risposta 4": "Sì, oltre alla teoria, il corso include esercitazioni, studio di casi, e tirocini formativi per applicare la conoscenza in contesti reali.",
        "Domanda 5": "È richiesta una conoscenza approfondita dell'inglese?",
        "Risposta 5": "È importante possedere una buona comprensione dell'inglese, poiché il corso può includere materiale didattico in questa lingua e prepara a un contesto lavorativo internazionale.",
        "Sbocchi": [
            "Responsabile di marketing sportivo",
            "Direttore di eventi sportivi",
            "Gestore di impianti sportivi",
            "Consulente finanziario per atleti e squadre",
            "Analista di performance sportive",
            "Agente di atleti e talenti sportivi"
        ]
    },
    {
        "nome": "Management per l’innovazione digitale e la sostenibilità",
        "Area": "Economia",
        "H1": "Corso di Laurea in Innovazione Digitale e Sostenibilità (economia aziendale), Università Digitale Unitelma",
        "tag 1": "Laurea Triennale",
        "tag 2": "Durata: 3 anni",
        "tag 3": "180 cfu",
        "tag 4": "Anno accademico 2023/2024",
        "tag 5": "L-18",
        "Descrizione": "Il Corso di Laurea in Scienze dell'Economia Aziendale, con specializzazione in Management per l'innovazione digitale e la sostenibilità presso l'Università Digitale Unitelma, si concentra sull'integrazione tra economia aziendale e le sfide contemporanee dell'innovazione digitale e della sostenibilità. L'obiettivo è sviluppare una capacità critica e autonoma nell'analisi delle dinamiche aziendali e di mercato, e nelle decisioni di politica economica nazionale. Il curriculum enfatizza l'importanza di comprendere e gestire le aziende nel contesto delle innovazioni digitali e sostenibili attuali, preparando i laureati a ruoli direttivi e gestionali in un'ampia gamma di settori. L'accesso è aperto tutto l'anno, senza test d'ingresso, e supporta gli studenti con valutazioni di competenza.",
        "Piano di studi 1": "Economia Politica (15 cfu), Economia Aziendale 1 (10 cfu), Economia e gestione delle imprese sostenibili (10 cfu), Metodi quantitativi per la gestione aziendale (10 cfu), Statistica e big data (5 cfu), Diritto Privato e nuove tecnologie (10 cfu), Politica economica e sostenibilità (7 cfu)",
        "Piano di studi 2": "Economia aziendale 2 (10 cfu), Statistica economica (10 cfu), Marketing (8 cfu), Diritto commerciale (12 cfu), Economia comportamentale nell’era digitale (6 cfu), Altre a scelta dello studente (12 cfu)",
        "Piano di studi 3": "Organizzazione aziendale per le imprese sostenibili (10 cfu), Analisi di bilancio e indicatori di sostenibilità (8 cfu), Affine o integrativo: Impresa, diritti umani e sostenibilità (8 cfu), Affine o integrativo: Digital Marketing (7 cfu), Affine o integrativo: Strumenti per la gestione e l'innovazione digitale d'impresa (8 cfu), Lingua straniera (4 cfu), Abilità informatiche e telematiche (4 cfu), Laboratorio di procedure di negoziazione e mediazione (3 cfu), Per stage e tirocini presso imprese, enti pubblici o privati, ordini professionali (5 cfu), Per prova finale (3 cfu)",
        "operatore 1": "Unitelma",
        "operatore 2": "Comparacorsi",
        "operatore 3": "Altri operatori",
        "prezzo 1": "2.100€",
        "prezzo 2": "1.600€",
        "prezzo 3": "2.500€",
        "Domanda 1": "Che supporto offre Unitelma per lo studio online?",
        "Risposta 1": "Unitelma fornisce una piattaforma e-learning all'avanguardia con lezioni digitali, tutoraggio, seminari web e apprendimento cooperativo per supportare gli studenti nel loro percorso di studi online.",
        "Domanda 2": "Quali metodi di valutazione vengono utilizzati nel corso?",
        "Risposta 2": "Il corso prevede valutazioni attraverso esami scritti e/o orali, test in itinere e la preparazione di una prova finale, assicurando una valutazione completa delle competenze acquisite.",
        "Domanda 3": "Come posso integrare le mie conoscenze dopo la laurea?",
        "Risposta 3": "Unitelma incoraggia l'autoaggiornamento e l'approfondimento attraverso master, corsi di specializzazione e attività di ricerca, fornendo una base solida per studi avanzati o sviluppo professionale.",
        "Domanda 4": "È possibile interagire con docenti e studenti durante il corso?",
        "Risposta 4": "Sì, la piattaforma Unitelma facilita l'interazione continua con docenti e compagni attraverso forum, gruppi di discussione e seminari web, promuovendo un ambiente di apprendimento collaborativo.",
        "Domanda 5": "Quali risorse aggiuntive sono disponibili per gli studenti?",
        "Risposta 5": "Gli studenti hanno accesso a risorse digitali come biblioteche online, database di ricerca e materiali didattici supplementari, per arricchire ulteriormente il loro percorso di studi.",
        "Sbocchi": [
            "Consulente aziendale per l'innovazione digitale",
            "Esperto in strategie di sostenibilità aziendale",
            "Manager di progetto per lo sviluppo sostenibile",
            "Analista per l'ottimizzazione dei processi aziendali",
            "Responsabile della trasformazione digitale",
            "Coordinatore per iniziative di eco-efficienza aziendale"
        ]
    },
    {
        "nome": "Economia delle Aziende Turistiche",
        "Area": "Economia",
        "H1": "Corso di Laurea in Aziende Turistiche (economia), Università Digitale Unitelma",
        "tag 1": "Laurea Triennale",
        "tag 2": "Durata: 3 anni",
        "tag 3": "180 cfu",
        "tag 4": "Anno accademico 2023/2024",
        "tag 5": "L-18",
        "Descrizione": "Il corso di Laurea in Economia delle Aziende Turistiche presso l'Università Digitale Unitelma, con un approccio multidisciplinare, mira a formare professionisti capaci di comprendere e gestire la complessità del settore turistico. Con una durata di 3 anni e 180 CFU, il programma offre strumenti gestionali, matematico-statistici e giuridici essenziali per sviluppare analisi critiche e strategie efficaci nel campo turistico. Gli studenti acquisiscono competenze per analizzare le dinamiche di mercato, le decisioni di politica economica e gestire aziende turistiche, promuovendo beni e attività culturali. Il curriculum, parte del più ampio corso di Scienze dell'Economia Aziendale, permette di collocare le conoscenze in un contesto culturale, economico e sociale globale. L'accesso è aperto tutto l'anno, senza test d'ingresso, e supporta gli studenti con valutazioni di competenza.",
        "Piano di studi 1": "Economia Politica per il turismo (10 cfu), Economia Aziendale 1 (10 cfu), Gestione delle imprese turistiche (10 cfu), Metodi quantitativi per la gestione aziendale (10 cfu), Statistica e big data (5 cfu), Diritto Privato del settore turistico (10 cfu), Diritto del turismo e governo del territorio (7 cfu)",
        "Piano di studi 2": "Economia aziendale 2 (10 cfu), Statistica per il turismo (10 cfu), Marketing turistico (8 cfu), Diritto commerciale (12 cfu), Economia del turismo - corso avanzato (8 cfu), Altre a scelta dello studente (12 cfu)",
        "Piano di studi 3": "Organizzazione delle imprese turistiche (10 cfu), Analisi di bilancio delle aziende turistiche (10 cfu), Politica economica e turismo sostenibile (6 cfu), Affine o integrativo: Digital Marketing per il turismo (7 cfu), Affine o integrativo: Sistemi di elaborazione delle informazioni (8 cfu), Lingua straniera (4 cfu), Abilità informatiche e telematiche (4 cfu), Laboratorio di procedure di negoziazione e mediazione (3 cfu), Per stage e tirocini presso imprese, enti pubblici o privati, ordini professionali (5 cfu), Per prova finale (3 cfu)",
        "operatore 1": "Unitelma",
        "operatore 2": "Comparacorsi",
        "operatore 3": "Altri operatori",
        "prezzo 1": "2.100€",
        "prezzo 2": "1.600€",
        "prezzo 3": "2.500€",
        "Domanda 1": "Quali sono le modalità di ammissione al corso in Aziende Turistiche?",
        "Risposta 1": "L'ammissione al corso richiede un diploma di scuola superiore o equivalente. Non è previsto un test d'ingresso, ma Unitelma potrebbe proporre una valutazione orientativa delle competenze.",
        "Domanda 2": "Come si svolge l'apprendimento a distanza in Unitelma?",
        "Risposta 2": "L'apprendimento avviene tramite lezioni digitali su una piattaforma e-learning. Include video, tutorato, web seminar sincroni e altre attività interattive per una formazione completa e flessibile.",
        "Domanda 3": "Quali competenze acquisirò con questo corso?",
        "Risposta 3": "Acquisirai competenze in gestione turistica, analisi del mercato, strategie economico-finanziarie e capacità di promuovere e gestire beni culturali e turistici a livello nazionale e internazionale.",
        "Domanda 4": "È possibile svolgere tirocini o stage durante il corso?",
        "Risposta 4": "Sì, il corso incoraggia tirocini e stage con aziende partner per mettere in pratica le competenze acquisite e acquisire esperienza diretta nel settore.",
        "Domanda 5": "Come posso accedere a risorse aggiuntive per il mio studio?",
        "Risposta 5": "Gli studenti hanno accesso a una vasta biblioteca digitale, risorse online e supporto continuo dai docenti e dai tutor per arricchire il loro percorso di studio.",
        "Sbocchi": [
            "Manager di strutture ricettive turistiche",
            "Consulente per lo sviluppo e la promozione turistica",
            "Analista di mercato nel settore del turismo",
            "Coordinatore di eventi e congressi",
            "Esperto di marketing turistico",
            "Responsabile di agenzia di viaggi o tour operator"
        ]
    },
    {
        "nome": "Diritto ed Economia delle Imprese",
        "Area": "Economia",
        "H1": "Corso di Laurea in Diritto ed economia delle imprese, Università Digitale Unifortunato",
        "tag 1": "Laurea Triennale",
        "tag 2": "Durata: 3 anni",
        "tag 3": "180 cfu",
        "tag 4": "Anno accademico 2023/2024",
        "tag 5": "L-14/L-18",
        "Descrizione": "Il Corso di Laurea Online in Diritto ed Economia delle Imprese di Unifortunato mira a formare esperti con competenze giuridiche ed economiche per operare efficacemente nel contesto aziendale. Il corso è interclasse, infatti afferisce a due diverse classi di laurea: Scienze dei servizi giuridici (L-14) e Scienze dell’economia e della gestione aziendale (L-18). Gli iscritti, al momento dell’immatricolazione, devono indicare la classe di appartenenza nella quale conseguire il titolo. Gli insegnamenti si focalizzano sull'analisi dei problemi economico-sociali e delle strategie aziendali; lo studente acquisisce una profonda comprensione delle dinamiche economico-finanziarie e giuridiche che influenzano le aziende, con un'attenzione particolare al sovraindebitamento e alla gestione d'azienda. L'accesso è aperto tutto l'anno, senza test d'ingresso, e supporta gli studenti con valutazioni di competenza.",
        "Piano di studi 1": "Diritto Privato (9 cfu), Diritto Romano e Fondamenti del Diritto Europeo (12 cfu), Economia Politica (8 cfu), Storia economica (8 cfu), Filosofia del diritto – ESCLUSIVO PERCORSO GIURIDICO (9 cfu), Informatica giuridica – ESCLUSIVO PERCORSO ECONOMICO (9 cfu), Diritto Costituzionale (9 cfu), Lingua Inglese (5 cfu)",
        "Piano di studi 2": "Economia Aziendale (8 cfu), Statistica di base (6 cfu), Diritto Tributario (6 cfu), Informatica (6 cfu), Insegnamento a scelta (6 cfu), Diritto dell’Unione Europea oppure European Union Law (6 cfu), Economia e gestione delle imprese (8 cfu), Diritto commerciale (6 cfu), Bilancio e principi contabili (8 cfu)",
        "Piano di studi 3": "Diritto del lavoro (6 cfu), Revisione aziendale (8 cfu), Diritto Penale (6 cfu), Insegnamento a scelta (6 cfu), Statistica economica (10 cfu), Marketing (8 cfu), Diritto e procedure della crisi d’impresa (12 cfu), Prova Finale (4 cfu)",
        "operatore 1": "Unifortunato",
        "operatore 2": "Comparacorsi",
        "operatore 3": "Altri operatori",
        "prezzo 1": "2.700€",
        "prezzo 2": "2.500€",
        "prezzo 3": "3.000€",
        "Domanda 1": "Quali competenze svilupperò nel corso di Diritto ed Economia delle Imprese?",
        "Risposta 1": "Acquisirai competenze interdisciplinari in ambito giuridico ed economico, focalizzandoti sulle dinamiche aziendali, il sovraindebitamento e la gestione d'impresa, indispensabili per affrontare il mercato del lavoro attuale.",
        "Domanda 2": "È possibile cambiare il curriculum durante il corso?",
        "Risposta 2": "Sì, all'Università Giustino Fortunato puoi cambiare la tua classe di appartenenza (L-14 o L-18) entro il terzo anno, permettendoti una maggiore flessibilità nel tuo percorso accademico.",
        "Domanda 3": "Come si articola l'apprendimento a distanza in questo corso?",
        "Risposta 3": "Utilizzerai una piattaforma e-learning avanzata che offre lezioni digitali, esercitazioni, tutorato e webinar, garantendo un'esperienza di apprendimento interattiva e flessibile presso Unifortunato.",
        "Domanda 4": "Quali sono i requisiti di ammissione per il corso?",
        "Risposta 4": "Devi possedere un diploma di scuola secondaria superiore o un titolo equivalente e una buona padronanza della lingua italiana. Non è richiesto un test d'ingresso selettivo, ma verrà effettuata una verifica delle competenze.",
        "Domanda 5": "Posso iscrivermi al corso in qualsiasi periodo dell'anno?",
        "Risposta 5": "Sì, Unifortunato permette l'iscrizione in qualsiasi momento dell'anno, offrendo massima flessibilità agli studenti per iniziare il loro percorso accademico.",
        "Sbocchi": [
            "Consulente aziendale",
            "Revisore contabile",
            "Analista di gestione finanziaria",
            "Responsabile della compliance aziendale",
            "Esperto in strategie di marketing e comunicazione",
            "Funzionario nelle istituzioni finanziarie e bancarie"
        ]
    },
    {
        "nome": "Economia D'impresa digitale e sostenibile",
        "Area": "Economia",
        "H1": "Corso di Laurea in Impresa digitale e sostenibile (economia), Università Digitale Unipegaso",
        "tag 1": "Laurea Triennale",
        "tag 2": "Durata: 3 anni",
        "tag 3": "180 cfu",
        "tag 4": "Anno accademico 2023/2024",
        "tag 5": "L-18",
        "Descrizione": "Il Corso di Laurea in Impresa Digitale e Sostenibile dell'Università Telematica Unipegaso si concentra sulla formazione integrata tra economia aziendale ed evoluzione sostenibile dell'impresa digitale. Il corso, della durata di 3 anni e appartenente alla classe di laurea L-18, ha come obiettivi formativi di dotare gli studenti di competenze organizzativo-gestionali e amministrativo-finanziarie, con un'enfasi particolare sulla sostenibilità delle imprese. Prepara per una varietà di ruoli professionali nel settore contabile, amministrativo, nella gestione dei fattori produttivi, nell'assicurazione, nella vendita, nel marketing e oltre. Il corso triennale apre anche a una prosecuzione degli studi con un titolo di studi magistrale.",
        "Piano di studi 1": "Storia dell'innovazione e dello sviluppo economico (10 cfu), Economia aziendale (10 cfu), Statistica (10 cfu), Diritto privato (10 cfu), Diritto tributario (10 cfu), Contabilità delle imprese sostenibili (10 cfu)",
        "Piano di studi 2": "Strategie per l'impresa innovativa (10 cfu), Diritto commerciale (10 cfu), Economia dei mercati finanziari digitali (15 cfu), Diritto amministrativo (10 cfu), Lingua inglese (5 cfu), Organizzazione dell'impresa innovativa e sostenibile (10 cfu)",
        "Piano di studi 3": "Economia e gestione delle imprese innovative (15 cfu), Insegnamento a scelta (12 cfu), Economia Politica (10 cfu), Modelli di analisi dell'economia circolare (10 cfu), Prova di abilità informatica (5 cfu), Per stages e tirocini presso imprese, enti pubblici o privati, ordini professionali (3 cfu), Prova Finale (5 cfu)",
        "operatore 1": "Unipegaso",
        "operatore 2": "Comparacorsi",
        "operatore 3": "Altri operatori",
        "prezzo 1": "3.400€",
        "prezzo 2": "1.700€",
        "prezzo 3": "3.600€",
        "Domanda 1": "Quali sono i requisiti per iscriversi al corso Economia Aziendale (Impresa Digitale e Sostenibile) di Unipegaso?",
        "Risposta 1": "Per l'iscrizione è necessario un diploma di scuola secondaria superiore o un titolo equipollente, oltre a una buona padronanza della lingua italiana e una conoscenza base di cultura generale.",
        "Domanda 2": "Come vengono valutati gli studenti di Economia Aziendale di Unipegaso?",
        "Risposta 2": "Gli studenti sono valutati attraverso prove in itinere come test, esercizi e simulazioni, oltre agli esami tradizionali, per monitorare costantemente l'apprendimento.",
        "Domanda 3": "È possibile conciliare lavoro e studio nel Corso Economia Aziendale di Unipegaso?",
        "Risposta 3": "Sì, il corso offre grande flessibilità grazie alla modalità di studio telematica, permettendo agli studenti di bilanciare impegni lavorativi e formativi.",
        "Domanda 4": "Il corso Economia Aziendale di Unipegaso include esperienze pratiche come stage?",
        "Risposta 4": "Sì, include opportunità di stage presso imprese, enti pubblici o privati, e ordini professionali per integrare la formazione teorica con esperienza pratica.",
        "Domanda 5": "Che tipo di supporto didattico offre Unipegaso ai suoi studenti di Economia Aziendale?",
        "Risposta 5": "Unipegaso fornisce supporto didattico attraverso tutor online, forum di discussione e materiali di studio digitali, garantendo assistenza continua e risorse per un apprendimento efficace.",
        "Sbocchi": [
            "Analista finanziario",
            "Consulente aziendale",
            "Responsabile della pianificazione e controllo",
            "Manager del settore bancario",
            "Specialista in risorse umane",
            "Direttore marketing",
            "Economia aziendale sostenibile",
            "Laurea in Economia sostenibile",
            "Università economia aziendale"
        ]
    },
    {
        "nome": "Economia Aziendale",
        "Area": "Economia",
        "H1": "Corso di Laurea in Economia aziendale, Università Digitale Unipegaso",
        "tag 1": "Laurea Triennale",
        "tag 2": "Durata: 3 anni",
        "tag 3": "180 cfu",
        "tag 4": "Anno accademico 2023/2024",
        "tag 5": "L-18",
        "Descrizione": "Il Corso di Laurea Triennale in Economia Aziendale dell'Università Telematica Unipegaso mira a formare professionisti con competenze organizzativo-gestionali e amministrativo-finanziarie. L'obiettivo è fornire una solida preparazione in discipline economiche, giuridiche, matematico-statistiche ed aziendali, essenziali per la gestione delle piccole e medie imprese. Ogni studente deve accumulare 180 CFU, distribuiti tra materie di base, attività caratterizzanti, materie affini e altre attività, che includono la conoscenza di una lingua straniera e competenze informatiche. Inoltre, i percorsi formativi offrono l'opportunità di stage in imprese o enti. Unipegaso si impegna a fornire un percorso di studi accessibile, con iscrizione aperta tutto l'anno e costi variabili.",
        "Piano di studi 1": "Storia Economica (10 cfu), Economia aziendale (10 cfu), Statistica (10 cfu), Diritto privato (10 cfu), Diritto tributario (10 cfu), Metodologie e determinazioni quantitative d'azienda (10 cfu)",
        "Piano di studi 2": "Strategie di comunicazione d'impresa (10 cfu), Diritto commerciale (10 cfu), Economia degli intermediari finanziari (15 cfu), Diritto amministrativo (10 cfu), Lingua inglese (5 cfu), Organizzazione aziendale (10 cfu)",
        "Piano di studi 3": "Economia e gestione delle imprese (15 cfu), Insegnamento a scelta (12 cfu), Economia Politica (10 cfu), Statistica economica (10 cfu), Prova di abilità informatica (5 cfu), Per stages e tirocini presso imprese, enti pubblici o privati, ordini professionali (3 cfu), Prova Finale (5 cfu)",
        "operatore 1": "Unipegaso",
        "operatore 2": "Comparacorsi",
        "operatore 3": "Altri operatori",
        "prezzo 1": "3.400€",
        "prezzo 2": "1.700€",
        "prezzo 3": "3.600€",
        "Domanda 1": "Come si svolge l'apprendimento a distanza in Economia Aziendale presso Unipegaso?",
        "Risposta 1": "L'apprendimento avviene tramite lezioni online, materiali digitali e supporto continuo di tutor. Gli studenti accedono ai contenuti quando preferiscono, adattando lo studio ai propri obiettivi formativi e al proprio ritmo.",
        "Domanda 2": "Sono previsti stage o tirocini nel corso di Economia Aziendale?",
        "Risposta 2": "Sì, Unipegaso incoraggia stage presso imprese e enti, offrendo esperienze pratiche per integrare la formazione teorica ricevuta.",
        "Domanda 3": "Quali sono i requisiti tecnici per partecipare al corso online?",
        "Risposta 3": "Occorre un dispositivo con accesso a internet, preferibilmente un computer, e conoscenze base di informatica per navigare sulla piattaforma e-learning di Unipegaso.",
        "Domanda 4": "Come vengono valutati gli studenti nel corso di laurea online?",
        "Risposta 4": "La valutazione avviene tramite esami online, test in itinere e prove finali. Gli esami sono concepiti per valutare comprensione e applicazione delle conoscenze.",
        "Domanda 5": "Posso iscrivermi al corso di Economia Aziendale in qualsiasi periodo dell'anno?",
        "Risposta 5": "Sì, Unipegaso permette iscrizioni tutto l'anno, offrendo flessibilità a chi ha impegni lavorativi o personali.",
        "Sbocchi": [
            "Analista finanziario",
            "Responsabile della pianificazione aziendale",
            "Consulente di gestione",
            "Direttore delle risorse umane",
            "Controller aziendale",
            "Imprenditore/startup founder"
        ]
    },
    {
        "nome": "Gestione d’Impresa (statutario)",
        "Area": "Economia",
        "H1": "Corso di laurea in Gestione d'Impresa, Università Telematica Mercatorum",
        "tag 1": "Laurea Triennale",
        "tag 2": "Durata: 3 anni",
        "tag 3": "180 cfu",
        "tag 4": "Anno accademico 2023/2024",
        "tag 5": "L-18",
        "Descrizione": "Il Corso di laurea triennale in Gestione d'Impresa, indirizzo Statuario, presso l'Università Telematica Mercatorum, classe L-18, offre formazione in Scienze dell'economia e della gestione aziendale. Gli studenti acquisiscono competenze in ambiti aziendali, economici, giuridici e statistici, formando un profilo completo per il mondo del lavoro. Il percorso Statutario è progettato per impartire un'ampia comprensione teorica, metodologica e pratica riguardante la struttura e le operazioni delle aziende, con enfasi particolare su quelle operanti nei settori della pubblica amministrazione, dell’attività manifatturiera, tecnologico avanzato e commerciale. Pertanto, prevede l'assimilazione di concetti fondamentali per sbocchi occupazionali in scienze aziendali, economia, diritto, matematica e statistica.",
        "Piano di studi 1": "Economia politica (8 cfu), Economia aziendale  (12 cfu), Economia e gestione delle imprese  (8 cfu), Statistica  (8 cfu), Diritto privato  (10 cfu), Lingua inglese\t (4 cfu), Metodi quantitativi per le decisioni aziendali\t (8 cfu)",
        "Piano di studi 2": "Economia politica II  (8 cfu), Contabilità e bilancio  (12 cfu), Diritto Commerciale  (10 cfu), Strategie d'impresa e marketing (10 cfu), Statistica economica (10 cfu), Internazionalizzazione delle imprese (10 cfu)",
        "Piano di studi 3": "Per stages e tirocini presso imprese, enti pubblici o privati, ordini professionali (6 cfu), Insegnamento a scelta (12 cfu), Finanza aziendale (10 cfu), Diritto del lavoro e relazioni industriali (12 cfu), Economia e management dell'innovazione (10 cfu), Abilità informatiche e telematiche (4 cfu), Seconda Lingua (4 cfu), Prova Finale (4 cfu)",
        "operatore 1": "Mercatorum",
        "operatore 2": "Comparacorsi",
        "operatore 3": "Altri operatori",
        "prezzo 1": "3.400€",
        "prezzo 2": "1.700€",
        "prezzo 3": "3.900€",
        "Domanda 1": "Quali sono gli obiettivi formativi del Corso di Laurea in Gestione d'Impresa?",
        "Risposta 1": "Questa laurea online mira a fornire competenza circa la gestione di impresa, l'economia, il diritto e la statistica, preparando lo studente a comprendere e affrontare le sfide delle imprese moderne.",
        "Domanda 2": "Posso personalizzare il mio percorso di studi all'Università Mercatorum?",
        "Risposta 2": "Sì, scegliendo tra i vari curricula, potrai specializzarti in aree come marketing, management sportivo o economia digitale.",
        "Domanda 3": "Come funzionano gli esami online all'Università Mercatorum?",
        "Risposta 3": "Gli esami online prevedono test, simulazioni e progetti, valutati per monitorare costantemente il tuo apprendimento.",
        "Domanda 4": "Quali opportunità di stage offre il corso?",
        "Risposta 4": "Il corso prevede stage curriculari ed extracurriculari con aziende connesse alle Camere di Commercio, offrendo esperienze pratiche pertinenti al tuo campo di studi.",
        "Domanda 5": "Quali requisiti sono necessari per l'iscrizione al corso?",
        "Risposta 5": "È richiesto un diploma di scuola secondaria superiore o equivalente e il superamento di un test d'ingresso per valutare le tue competenze iniziali.",
        "Sbocchi": [
            "Consulente aziendale",
            "Analista di gestione",
            "Responsabile della pianificazione finanziaria",
            "Manager del marketing digitale",
            "Specialista in sviluppo sostenibile",
            "Responsabile delle risorse umane"
        ]
    },
    {
        "nome": "Gestione d’Impresa (Economia digitale)",
        "Area": "Economia",
        "H1": "Corso di Laurea in Economia digitale (gestione d'impresa), Università Digitale Mercatorum",
        "tag 1": "Laurea Triennale",
        "tag 2": "Durata: 3 anni",
        "tag 3": "180 cfu",
        "tag 4": "Anno accademico 2023/2024",
        "tag 5": "L-18",
        "Descrizione": "Il corso di laurea online di Economia Digitale (Gestione d'Impresa) dell’università telematica Mercatorum, mira a formare esperti nell'uso strategico delle tecnologie digitali in ambito aziendale. Inquadrato nella classe L-18, il percorso triennale offre specializzazione in economia aziendale e digitale, ponendo l'accento su marketing, data management, e sostenibilità. Gli studenti acquisiscono competenze nelle discipline aziendali, economiche, giuridiche, matematiche e statistiche. Il piano di studi, ricco di 180 CFU, include materie come Economia Digitale e Strategia di Marketing Digitale. Il corso prepara a ruoli chiave nell'era digitale, promuovendo un'approfondita comprensione di economia e gestione aziendale con un focus sulla digitalizzazione e la sostenibilità.",
        "Piano di studi 1": "Economia politica  (8 cfu), Economia aziendale  (12 cfu), Economia digitale  (8 cfu), Statistica  (8 cfu), DIRITTO PRIVATO  (10 cfu), Lingua inglese\t  (4 cfu), Metodi quantitativi e applicazioni per i big data  (8 cfu)",
        "Piano di studi 2": "Economia politica II  (8 cfu), Contabilità e bilancio  (12 cfu), Intellectual Property, competition and data management  (10 cfu), Strategie di Marketing digitale  (10 cfu), Statistica economica  (10 cfu), Internazionalizzazione delle imprese  (10 cfu), Per stages e tirocini presso imprese, enti pubblici o privati, ordini professionali  (6 cfu)",
        "Piano di studi 3": "Insegnamento a scelta  (12 cfu), Finance and Venture Capital  (10 cfu), Diritto del lavoro e relazioni industriali  (12 cfu), Economia e management dell'innovazione (10 cfu), Computer science (network e data management)  (4 cfu), Seconda Lingua (4 cfu), Prova Finale (4 cfu)",
        "operatore 1": "Mercatorum",
        "operatore 2": "Comparacorsi",
        "operatore 3": "Altri operatori",
        "prezzo 1": "3.400€",
        "prezzo 2": "1.700€",
        "prezzo 3": "3.900€",
        "Domanda 1": "Quali competenze digitali specifiche svilupperò in questo corso di economia digitale?",
        "Risposta 1": "Imparerai a gestire e analizzare grandi quantità di dati (data management), creare strategie di marketing digitale efficaci e utilizzare strumenti tecnologici avanzati per ottimizzare le operazioni aziendali.",
        "Domanda 2": "Come l'università telematica Mercatorum supporta gli studenti online?",
        "Risposta 2": "Mercatorum offre piattaforme e-learning all'avanguardia, tutoraggio online e accesso a una vasta gamma di risorse digitali per garantire un'esperienza formativa completa e interattiva.",
        "Domanda 3": "È possibile avere interazioni pratiche nel corso online di economia digitale?",
        "Risposta 3": "Sì, il corso prevede progetti, case study e, in alcuni casi, stage virtuali o reali per applicare le conoscenze teoriche in contesti pratici reali.",
        "Domanda 4": "Come posso personalizzare il mio percorso formativo in questo corso di laurea triennale?",
        "Risposta 4": "Puoi scegliere tra diversi moduli elettivi e indirizzi, come marketing digitale o sostenibilità, per adattare il corso alle tue aspirazioni professionali e interessi.",
        "Domanda 5": "Quali sono i requisiti tecnici per partecipare al corso online di gestione d'impresa?",
        "Risposta 5": "Avrai bisogno di un computer affidabile con accesso a Internet, software di base come un word processor e, per alcuni corsi, software specifico di analisi dati o progettazione.",
        "Sbocchi": [
            "Analista di business digitale",
            "Responsabile marketing digitale",
            "Consulente per la sostenibilità aziendale",
            "Specialista in e-commerce",
            "Manager di progetti innovativi",
            "Esperto in ottimizzazione dei processi aziendali"
        ]
    },
    {
        "nome": "Gestione d’Impresa(Marketing e vendite)",
        "Area": "Economia",
        "H1": "Corso di Laurea in Marketing e vendite (gestione d'impresa), Università Digitale Mercatorum",
        "tag 1": "Laurea Triennale",
        "tag 2": "Durata: 3 anni",
        "tag 3": "180 cfu",
        "tag 4": "Anno accademico 2023/2024",
        "tag 5": "L-18",
        "Descrizione": "Il Corso di Laurea in marketing e vendite di Mercatorum, classe L-18, si focalizza sulle strategie di mercato e le tecniche di vendita moderne. Gli studenti sviluppano competenze in marketing digitale, strategie di vendita innovative e gestione d'impresa. In 3 anni e 180 CFU, esploreranno materie come Economia digitale e Strategie di marketing digitale. Il corso è pensato per chi mira a carriere in ambiti dinamici, dove il marketing strategico e le vendite sono cruciali. L'accesso è aperto a diplomati e prevede un test iniziale per valutare la preparazione. Gli stage, sia curriculari che extracurriculari, offrono esperienze pratiche essenziali.",
        "Piano di studi 1": "Economia politica (8 cfu), Economia aziendale  (12 cfu), Economia e gestione delle imprese  (8 cfu), Statistica  (8 cfu), Diritto privato  (10 cfu), Lingua inglese (4 cfu), Metodi quantitativi per le decisioni aziendali (8 cfu)",
        "Piano di studi 2": "Tecniche di vendita on e off-line (10 cfu), Strategie di Marketing diretto, digitale e off-line  (8 cfu), Comunicazione efficace e media digitali   (6 cfu), Scenari economici di mercato  (8 cfu), Statistica per il marketing (10 cfu), Diritto delle imprese (10 cfu), Intellectual Property, competition and data management (6 cfu), Data Management per il marketing e la vendita (4 cfu)",
        "Piano di studi 3": "Finanza aziendale (10 cfu), Diritto del lavoro e delle relazioni industriali (12 cfu), Semiotica generale e dei media (12 cfu), Insegnamento a scelta (12 cfu), Seconda Lingua (4 cfu), Tirocini formativi e di orientamento (6 cfu), Prova Finale (4 cfu)",
        "operatore 1": "Mercatorum",
        "operatore 2": "Comparacorsi",
        "operatore 3": "Altri operatori",
        "prezzo 1": "3.400€",
        "prezzo 2": "1.700€",
        "prezzo 3": "3.900€",
        "Domanda 1": "Come avviene l'apprendimento nel corso di vendite e marketing online di Mercatorum?",
        "Risposta 1": "Attraverso una piattaforma e-learning interattiva, gli studenti accedono a lezioni, materiali didattici e partecipano a forum di discussione e progetti di gruppo, garantendo un'esperienza formativa completa e flessibile.",
        "Domanda 2": "Quali supporti sono disponibili per gli studenti online di Mercatorum?",
        "Risposta 2": "Gli studenti hanno accesso a tutoraggio, assistenza tecnica e orientamento accademico, assicurando che ricevano il supporto necessario per il loro percorso di studi online.",
        "Domanda 3": "È possibile interagire con i docenti nel corso online di Mercatorum?",
        "Risposta 3": "Sì, gli studenti possono interagire con i docenti tramite forum, e-mail e, in alcuni casi, sessioni di videoconferenza, favorendo un ambiente di apprendimento collaborativo.",
        "Domanda 4": "Come viene valutata la preparazione iniziale per il corso di Marketing e vendite?",
        "Risposta 4": "Prima dell'immatricolazione, gli studenti sostengono un test d'ingresso su cultura generale economica e logica matematica per valutare la loro preparazione e, se necessario, assegnare Obblighi Formativi Aggiuntivi.",
        "Domanda 5": "C'è un requisito linguistico per il corso online di Mercatorum?",
        "Risposta 5": "Sì, è prevista una prova di idoneità di lingua inglese per garantire che gli studenti possano comprendere e partecipare attivamente al materiale didattico e alle discussioni internazionali.",
        "Sbocchi": [
            "Manager di e-commerce",
            "Analista di vendita e marketing digitale",
            "Consulente per la sostenibilità aziendale",
            "Specialista in SEO e gestione dei contenuti",
            "Esperto in customer relationship management (CRM)",
            "Responsabile della supply chain digitale"
        ]
    },
    {
        "nome": "Gestione d’Impresa (Management sportivo)",
        "Area": "Economia",
        "H1": "Corso di Laurea in Management sportivo (gestione d'impresa), Università Digitale Mercatorum",
        "tag 1": "Laurea Triennale",
        "tag 2": "Durata: 3 anni",
        "tag 3": "180 cfu",
        "tag 4": "Anno accademico 2023/2024",
        "tag 5": "L-18",
        "Descrizione": "Il Corso di Laurea Triennale in Management sportivo di Mercatorum, classe L-18, si concentra su marketing strategico e diretto, considerando lo sport come settore d'affari e ambito lavorativo paragonabile a un'impresa commerciale. il Corso di Laurea online si propone di preparare professionisti adeguatamente qualificati per rispondere a questo bisogno del mercato, avvalendosi anche dell'apporto e dell'esperienza di noti professionisti del settore sportivo. Con un percorso formativo in 3 anni e 180 cfu, che bilancia teoria e pratica, gli studenti imparano metodi quantitativi per il marketing, strategie digitali e off-line e acquisiranno competenze sul management dello sport, preparandosi per ruoli professionali in aziende e imprese sportive.",
        "Piano di studi 1": "Economia politica (8 cfu), Management per le società sportive (12 cfu), Gestione delle imprese sportive (8 cfu), Diritto dello sport (10 cfu), Statistica (8 cfu), Metodi quantitativi per le decisioni aziendali (8 cfu), Lingua inglese (4 cfu)",
        "Piano di studi 2": "Contabilità e bilancio (12 cfu), Contrattualistica sportiva  (10 cfu), Economia politica II (8 cfu), Teorie e tecniche di comunicazione dello sport (10 cfu), Statistica economica (10 cfu), Strategie e marketing nel settore dello sport (10 cfu)",
        "Piano di studi 3": "Finanza aziendale e fiscalità delle aziende sportive (10 cfu), Coaching  (10 cfu), Diritto del lavoro e sindacale dello sport (12 cfu), Psicologia del lavoro e delle organizzazioni sportive\t (12 cfu), Abilità informatiche e telematiche (4 cfu), Seconda Lingua (4 cfu), Per stages e tirocini presso imprese, enti pubblici o privati, ordini professionali (6 cfu), Prova Finale (4 cfu)",
        "operatore 1": "Mercatorum",
        "operatore 2": "Comparacorsi",
        "operatore 3": "Altri operatori",
        "prezzo 1": "3.400€",
        "prezzo 2": "1.700€",
        "prezzo 3": "3.900€",
        "Domanda 1": "Quali sono gli obiettivi formativi del corso di Management Sportivo di Mercatorum?",
        "Risposta 1": "Acquisirai competenze nella gestione strategica e organizzativa dello sport, comprensione dei sistemi di controllo finanziario delle imprese sportive e abilità nell'analisi di mercato e marketing sportivo.",
        "Domanda 2": "Come si svolge il test d'ingresso per il Corso di Laurea in Management Sportivo?",
        "Risposta 2": "Il test valuta la cultura generale economica e la logica matematica. Se non superato, seguirai un corso di recupero per colmare le lacune.",
        "Domanda 3": "È possibile iscriversi al corso in qualsiasi periodo dell'anno?",
        "Risposta 3": "Sì, l'Università Telematica Mercatorum permette l'iscrizione in qualunque periodo, facilitando l'accesso agli studi.",
        "Domanda 4": "Il corso offre opportunità di stage?",
        "Risposta 4": "Sì, sono promossi stage curriculari ed extracurriculari, con progetti formativi personalizzati e connessioni con le Camere di Commercio per trovare aziende adeguate.",
        "Domanda 5": "Qual è l'importanza della lingua inglese nel corso?",
        "Risposta 5": "Il corso richiede il superamento di una prova di idoneità in inglese, essenziale per operare nel contesto internazionale del management sportivo.",
        "Sbocchi": [
            "Manager di imprese sportive",
            "Consulente di marketing sportivo",
            "Direttore operativo di eventi sportivi",
            "Responsabile delle relazioni pubbliche per squadre o atleti",
            "Esperto di sport management",
            "Responsabile dello sviluppo commerciale per imprese legate allo sport"
        ]
    },
    {
        "nome": "Gestione d’Impresa (Immobiliare)",
        "Area": "Economia",
        "H1": "Corso di Laurea in Immobiliare (gestione d'impresa), Università Digitale Mercatorum",
        "tag 1": "Laurea Triennale",
        "tag 2": "Durata: 3 anni",
        "tag 3": "180 cfu",
        "tag 4": "Anno accademico 2023/2024",
        "tag 5": "L-18",
        "Descrizione": "Il Corso di Laurea in Gestione d’Impresa indirizzo Immobiliare presso Mercatorum, classe L-18, offre una formazione specializzata per il settore immobiliare. Questo percorso formativo di laurea triennale, erogato completamente online, si focalizza sugli aspetti strategici, giuridici e economici del mercato immobiliare, preparando gli studenti a diventare professionisti competenti in gestione, valutazione e vendita di case e dinamiche di agenzia. Con 180 CFU, il corso mira a fornire una comprensione approfondita del settore immobile, abilitando gli studenti a soddisfare le esigenze di un mercato in continua evoluzione, quello immortale della ricerca casa in Italia. Le iscrizioni sono aperte tutto l'anno, con requisiti di accesso basati su un diploma di scuola secondaria superiore e un test d'ingresso orientativo.",
        "Piano di studi 1": "Economia politica (8 cfu), Economia aziendale  (12 cfu), Gestione delle imprese immobiliari  (8 cfu), Statistica  (8 cfu), Diritto per la gestione immobiliare  (10 cfu), Lingua inglese\t (4 cfu), Metodi quantitativi per le decisioni aziendali\t (8 cfu)",
        "Piano di studi 2": "Contabilità e bilancio (12 cfu), Economia politica II (8 cfu), Diritto Commerciale  (10 cfu), Marketing immobiliare\t  (10 cfu), Statistica economica  (10 cfu), Internazionalizzazione delle imprese\t (10 cfu)",
        "Piano di studi 3": "Asset e valutazioni immobiliari  (10 cfu), Diritto del lavoro e relazioni industriali (12 cfu), Coaching e tecniche di vendita  (10 cfu), Estimo\t (12 cfu), Abilità informatiche e telematiche (4 cfu), Seconda Lingua (4 cfu), Per stages e tirocini presso imprese, enti pubblici o privati, ordini professionali  (6 cfu), Prova Finale (4 cfu)",
        "operatore 1": "Mercatorum",
        "operatore 2": "Comparacorsi",
        "operatore 3": "Altri operatori",
        "prezzo 1": "3.400€",
        "prezzo 2": "1.700€",
        "prezzo 3": "3.900€",
        "Domanda 1": "Quali sono i requisiti di accesso per il Corso di Laurea in Immobiliare alla Mercatorum?",
        "Risposta 1": "Per iscriversi, è necessario un diploma di scuola secondaria superiore o un titolo straniero equipollente. Inoltre, è previsto un test iniziale per valutare le competenze in materie economiche e logico-matematiche.",
        "Domanda 2": "Come si svolgono gli esami nel corso di laurea online?",
        "Risposta 2": "Gli esami online alla Mercatorum si svolgono tramite la piattaforma e-learning dell'università, con modalità che possono variare tra test scritti, orali o progetti, a seconda dell'insegnamento.",
        "Domanda 3": "È possibile frequentare stage durante il corso?",
        "Risposta 3": "Sì, l'Università Mercatorum promuove stage curriculari ed extracurriculari, con progetti formativi personalizzati e collaborazioni con le Camere di Commercio per opportunità alineate al percorso di studi.",
        "Domanda 4": "Come posso verificare la compatibilità del mio titolo straniero?",
        "Risposta 4": "Per titoli stranieri, Mercatorum fornisce un servizio di riconoscimento e verifica di equivalenza. È consigliato contattare l'ufficio ammissioni dell'università per una consulenza personalizzata.",
        "Domanda 5": "Quali risorse di supporto sono disponibili per gli studenti online?",
        "Risposta 5": "Mercatorum offre una vasta gamma di risorse, tra cui tutoraggio online, accesso a materiali didattici e biblioteche virtuali, oltre a forum di discussione e supporto tecnico per la piattaforma e-learning.",
        "Sbocchi": [
            "Responsabile della comunicazione e marketing immobiliare",
            "Consulente per investimenti immobiliari",
            "Esperto in gestione e valorizzazione patrimoniale",
            "Agente immobiliare specializzato",
            "Direttore di agenzie immobiliari",
            "Analista di mercato immobiliare"
        ]
    },
    {
        "nome": "Economia e Gestione delle Imprese (Business Management)",
        "Area": "Economia",
        "H1": "Corso di Laurea in Business Management, Università Digitale Uninettuno",
        "tag 1": "Laurea Triennale",
        "tag 2": "Durata: 3 anni",
        "tag 3": "180 cfu",
        "tag 4": "Anno accademico 2023/2024",
        "tag 5": "L-18",
        "Descrizione": "Il Corso di Laurea Online in Business Management dell'Università Telematica Uninettuno, classe L-18, sviluppa competenze in gestione aziendale, marketing, finanza e giurisprudenza. Il curriculum della laurea triennale in “Business management” si completa con gli esami di marketing e financial accounting al secondo anno e management accounting nel terzo anno. Obiettivo è formare professionisti capaci di comprendere fenomeni economici, modelli di business e gestire impresa con approccio innovativo. Offre percorsi come Economia e Governo d'Impresa, Business Management (direzione d’impresa) in inglese, concentrati su amministrazione, gestione, marketing e finanza. Stage e attività pratiche completano il percorso in business & management, preparando a sfide manageriali nel mercato globale e a studi avanzati.",
        "Piano di studi 1": "Organizational dynamics and behaviour (9 cfu), Mathematics (9 cfu), Economics (9 cfu), Introduction to Law (9 cfu), Introduction to business administration (9 cfu), Business Management (9 cfu), Information management (3 cfu), English language (3 cfu)",
        "Piano di studi 2": "Monetary economics (9 cfu), Statistics and business statistics (9 cfu), International contract law (9 cfu), Financial accounting (9 cfu), Banking and finance (9 cfu), Marketing (9 cfu), CFU riservati ai moduli a scelta (6 cfu)",
        "Piano di studi 3": "Public economics (9 cfu), Management accounting (9 cfu), Industrial organization (9 cfu), Financial mathematics (6 cfu), Dissertation (6 cfu)",
        "operatore 1": "Uninettuno",
        "operatore 2": "Comparacorsi",
        "operatore 3": "Altri operatori",
        "prezzo 1": "2.000€",
        "prezzo 2": "1.900€",
        "prezzo 3": "2.200€",
        "Domanda 1": "Quali sono i prerequisiti linguistici per i corsi in inglese all'Uninettuno?",
        "Risposta 1": "Per iscriversi ai corsi di Business Management in inglese di Uninettuno, è richiesto un certificato di livello B1 in inglese, assicurando così che gli studenti possano seguire efficacemente le lezioni e partecipare attivamente.",
        "Domanda 2": "Come si svolge il test d'ingresso per il Corso di Laurea in Business Management?",
        "Risposta 2": "Il test d'ingresso non selettivo verifica la cultura economica generale e la logica matematica. Non superarlo comporta l'assegnazione di Obblighi Formativi Aggiuntivi, che richiedono corsi di recupero per colmare le lacune.",
        "Domanda 3": "È possibile iscriversi a Uninettuno in qualsiasi periodo dell'anno?",
        "Risposta 3": "Sì, le iscrizioni al Corso di Laurea in Business Management di Uninettuno sono aperte tutto l'anno, offrendo flessibilità agli studenti per iniziare il loro percorso formativo quando preferiscono.",
        "Domanda 4": "Quali competenze sviluppa il Corso di Business Management?",
        "Risposta 4": "Il corso mira a sviluppare competenze manageriali, economiche, finanziarie e di marketing, preparando gli studenti a comprendere e gestire complesse dinamiche aziendali in un contesto globale e digitale.",
        "Domanda 5": "Come sono strutturate le attività pratiche nel corso?",
        "Risposta 5": "Il corso include stage curriculari ed extracurriculari, collegati alle Camere di Commercio per esperienze pratiche mirate. Gli studenti partecipano a progetti formativi personalizzati, integrando teoria e pratica nel settore del business management.",
        "Sbocchi": [
            "Direttore commerciale",
            "Esperto in sviluppo del business",
            "Responsabile controllo di gestione",
            "Consulente di strategia aziendale",
            "Responsabile supply chain",
            "Analista di mercato",
            "Esperto in gestione del cambiamento",
            "Specialista in gestione del rischio",
            "Direttore delle relazioni internazionali",
            "Project manager"
        ]
    },
    {
        "nome": "Economia e Gestione delle Imprese (Cultura, Turismo e Territorio)",
        "Area": "Economia",
        "H1": "Corso di Laurea in turismo e territorio e cultura, Università Digitale Uninettuno",
        "tag 1": "Laurea Triennale",
        "tag 2": "Durata: 3 anni",
        "tag 3": "180 cfu",
        "tag 4": "Anno accademico 2023/2024",
        "tag 5": "L-18",
        "Descrizione": "Il Corso di Laurea online in Economia e Gestione delle Imprese (Curriculum in Cultura, Turismo e Territorio) di Uninettuno mira a formare laureati nella gestione imprenditoriale con focus su turismo e territorio. Gli studenti acquisiscono competenze in marketing, finanza aziendale e gestione, oltre a nozioni giuridiche essenziali per l'amministrazione aziendale. L'offerta formativa della laurea triennale, erogata in modalità online e in inglese, è articolata in materie come economia politica, aziendale, e specifiche del turismo. Il primo anno termina con istituzioni di diritto pubblico, mentre secondo e terzo anno prevedono materie incentrate su economia e turismo. Il percorso include anche tirocini e prepara a ruoli manageriali in aziende turistiche, culturali e territoriali.",
        "Piano di studi 1": "Economia Aziendale (9 cfu), Idoneità informatica (3 cfu), Istituzioni di economia politica (9 cfu), Organizzazione Aziendale (9 cfu), Economia e gestione delle imprese (9 cfu), Istituzioni di Diritto Pubblico (9 cfu), Matematica Generale (9 cfu), Idoneità linguistica (3 cfu)",
        "Piano di studi 2": "Turismo, territorio, impresa per lo sviluppo locale (9 cfu), Contabilità e bilancio (9 cfu), Politica economica del turismo (9 cfu), Marketing del turismo (9 cfu), Statistica e statistica economica del turismo (9 cfu), Diritto Commerciale (9 cfu)",
        "Piano di studi 3": "Economia dei beni pubblici e comuni (9 cfu), Diritto del Lavoro (9 cfu), Economia del territorio (9 cfu), Analisi e contabilità dei costi nelle imprese turistiche (9 cfu), Matematica finanziaria (6 cfu), Tirocinio (3 cfu), Prova Finale (3 cfu)",
        "operatore 1": "Uninettuno",
        "operatore 2": "Comparacorsi",
        "operatore 3": "Altri operatori",
        "prezzo 1": "2.000€",
        "prezzo 2": "1.900€",
        "prezzo 3": "2.200€",
        "Domanda 1": "Quali competenze informatiche svilupperò nel corso?",
        "Risposta 1": "Nel corso di Laurea in Turismo e Territorio di Uninettuno, acquisirai competenze informatiche essenziali per il settore turistico, imparando a utilizzare strumenti digitali per la gestione aziendale e la promozione del territorio.",
        "Domanda 2": "È necessario conoscere una lingua straniera?",
        "Risposta 2": "Sì, il corso incoraggia la padronanza di almeno una lingua straniera, fondamentale nel settore turistico, per comunicare efficacemente in un contesto internazionale.",
        "Domanda 3": "Come posso accedere al corso online?",
        "Risposta 3": "Dovrai iscriverti attraverso il portale Uninettuno e, una volta ammesso, riceverai le credenziali per accedere alla piattaforma e-learning dove seguirai lezioni e svolgerai attività.",
        "Domanda 4": "Ci sono opportunità di interazione con altri studenti?",
        "Risposta 4": "Sì, Uninettuno promuove attività interattive e forum online dove potrai discutere e collaborare con altri studenti e docenti, arricchendo la tua esperienza di apprendimento.",
        "Domanda 5": "Posso fare tirocinio durante il corso?",
        "Risposta 5": "Il corso prevede tirocini e stage per consentirti di acquisire esperienza pratica e applicare le conoscenze teoriche in contesti reali, essenziali nel settore turistico e territoriale.",
        "Sbocchi": [
            "Manager di destinazione turistica",
            "Esperto in sviluppo e promozione territoriale",
            "Consulente di viaggi e turismo",
            "Specialista in marketing turistico",
            "Coordinatore di eventi culturali e turistici",
            "Responsabile delle operazioni in strutture ricettive",
            "Funzionario in enti di gestione del patrimonio culturale",
            "Imprenditore nel settore turistico",
            "Analista di mercato per il turismo",
            "Guida turistica specializzata"
        ]
    },
    {
        "nome": "Economia e gestione delle imprese (Economia e Governo d'Impresa)",
        "Area": "Economia",
        "H1": "Corso di Laurea in Economia e gestione delle imprese (Economia e Governo d'Impresa), Università Digitale Uninettuno",
        "tag 1": "Laurea Triennale",
        "tag 2": "Durata: 3 anni",
        "tag 3": "180 cfu",
        "tag 4": "Anno accademico 2023/2024",
        "tag 5": "L-18",
        "Descrizione": "Il Corso di Laurea online in Economia e Gestione delle Imprese (Curriculum in Economia e Governo d'Impresa) di Uninettuno, in classe L-18, forma laureati in gestione organizzativa ed economica aziendale. La laurea triennale offre competenze in marketing, finanza, e diritto aziendale, essenziali per comprendere fenomeni economici e gestire un'impresa. Attraverso studi in economia politica, diritto privato, contabilità, finanza d’impresa, finanza aziendale e oltre, il curriculum prepara gli studenti a diverse sfide professionali, con possibilità di studi in inglese per approfondimenti internazionali.",
        "Piano di studi 1": "Economia Aziendale (9 cfu), Idoneità informatica (3 cfu), Istituzioni di economia politica (9 cfu), Economia e gestione delle imprese (9 cfu), Istituzioni di Diritto Privato (9 cfu), Organizzazione Aziendale (9 cfu), Matematica Generale (9 cfu), Idoneità linguistica (3 cfu)",
        "Piano di studi 2": "Diritto tributario (9 cfu), Statistica e statistica aziendale (9 cfu), Marketing (9 cfu), Contabilità e bilancio (9 cfu), Banche e intermediari finanziari (nuova edizione) (9 cfu), Diritto Commerciale (9 cfu), Numero di CFU riservati ai moduli a scelta (6 cfu)",
        "Piano di studi 3": "Scienza delle finanze (9 cfu), Finanza Aziendale (9 cfu), Diritto fallimentare (9 cfu), Economia Applicata (9 cfu), Matematica finanziaria (6 cfu), Tirocinio (3 cfu), Prova Finale (3 cfu)",
        "operatore 1": "Uninettuno",
        "operatore 2": "Comparacorsi",
        "operatore 3": "Altri operatori",
        "prezzo 1": "2.000€",
        "prezzo 2": "1.900€",
        "prezzo 3": "2.200€",
        "Domanda 1": "Posso accedere a Uninettuno senza test d'ingresso?",
        "Risposta 1": "Sì, Uninettuno offre iscrizioni tutto l'anno senza test d'ingresso per la laurea in economia e gestione delle imprese, garantendo accesso immediato agli studi.",
        "Domanda 2": "Quali sono gli obiettivi formativi del corso Economia e Governo d'Impresa?",
        "Risposta 2": "Svilupperai competenze in amministrazione, marketing, finanza e diritto aziendale, essenziali per interpretare fenomeni economici e gestire efficientemente le imprese.",
        "Domanda 3": "È possibile studiare in lingua inglese a Uninettuno?",
        "Risposta 3": "Sì, il corso offre indirizzi in inglese come Business Management e Financial Management, richiedendo il livello linguistico B1 per l'iscrizione.",
        "Domanda 4": "Uninettuno offre opportunità di tirocinio durante il corso?",
        "Risposta 4": "Sì, il corso include tirocini e attività pratiche per applicare la teoria appresa e acquisire esperienza diretta nel settore.",
        "Domanda 5": "Come posso seguire le lezioni online?",
        "Risposta 5": "Uninettuno fornisce una piattaforma e-learning dove puoi accedere a lezioni, risorse e interagire con docenti e studenti, facilitando lo studio da qualsiasi luogo.",
        "Sbocchi": [
            "Analista finanziario",
            "Consulente aziendale",
            "Direttore marketing",
            "Responsabile delle risorse umane",
            "Imprenditore/startup founder",
            "Controllore di gestione",
            "Esperto in economia digitale",
            "Manager del turismo",
            "Specialista in sviluppo sostenibile",
            "Esperto in gestione delle relazioni internazionali"
        ]
    },
    {
        "nome": "Scienze dell'Economia (eCommerce e Digital Management)",
        "Area": "Economia",
        "H1": "Corso di Laurea in Digital management e ecommerce, Università Digitale eCampus",
        "tag 1": "Laurea Magistrale",
        "tag 2": "Durata: 2 anni",
        "tag 3": "120 cfu",
        "tag 4": "Anno accademico 2023/2024",
        "tag 5": "LM-56",
        "Descrizione": "Il Corso di Laurea Magistrale in Scienze dell'Economia (Curriculum eCommerce e Digital Management) presso l'Università eCampus, della durata di 2 anni (LM-56), offre formazione avanzata nell'economia digitale. Concentrato su marketing digitale, gestione delle imprese digitali e aspetti legali dell'e-commerce, questo corso si adatta a chi aspira a ruoli dirigenziali nel settore digitale. Con 120 CFU, il piano di studi include economia, aziendale, giuridico e matematico-statistico, preparando per una carriera in pubblica amministrazione o imprese private, con possibilità di tirocini formativi. L'accesso è aperto a laureati triennali con competenze in settori rilevanti e test d'ingresso per valutare la preparazione.",
        "Piano di studi 1": "Economia del diritto (9 cfu), Economia dell’ambiente (9 cfu), Storia d’impresa (9 cfu), Programmazione e controllo (9 cfu), Strategia e governo d’azienda (12 cfu), Economia dello sviluppo (9 cfu)",
        "Piano di studi 2": "Marketing digitale e commercio elettronico (9 cfu), Diritto dell’impresa digitale (6 cfu), Diritto tributario avanzato (6 cfu), Statistica economica (6 cfu), Organizzazione e management delle imprese digitali (9 cfu), A scelta dello studente (9 cfu), Lingua inglese (6 cfu), Prova finale (12 cfu)",
        "operatore 1": "eCampus",
        "operatore 2": "Comparacorsi",
        "operatore 3": "Altri operatori",
        "prezzo 1": "3.900€",
        "prezzo 2": "2.600€",
        "prezzo 3": "4.200€",
        "Domanda 1": "È richiesta la conoscenza della lingua inglese per accedere al corso?",
        "Risposta 1": "Sì, il corso di laurea magistrale in Scienze dell'Economia (eCommerce e Digital Management) richiede la conoscenza della lingua inglese, data la natura internazionale del programma e la presenza di materiali e risorse in inglese.",
        "Domanda 2": "Come avviene l'interazione con i docenti nel corso online?",
        "Risposta 2": "Gli studenti interagiscono con i docenti tramite piattaforme di e-learning che offrono videolezioni, forum di discussione, chat e sessioni di web conference, garantendo un costante supporto didattico e accademico.",
        "Domanda 3": "Sono previsti momenti di confronto tra studenti?",
        "Risposta 3": "Sì, la piattaforma eCampus incoraggia l'interazione tra studenti attraverso gruppi di studio online, forum e attività collaborative, promuovendo lo scambio di idee e il networking.",
        "Domanda 4": "Come posso verificare il mio progresso durante il corso?",
        "Risposta 4": "Il corso include test di autovalutazione e esercitazioni regolari, permettendo agli studenti di monitorare il loro progresso e capire le aree che richiedono ulteriori studi o chiarimenti.",
        "Domanda 5": "È possibile effettuare un tirocinio durante il corso?",
        "Risposta 5": "Sì, il corso prevede la possibilità di svolgere tirocini formativi presso strutture pubbliche o private, offrendo l'opportunità di applicare praticamente le conoscenze acquisite e di acquisire esperienza nel campo.",
        "Sbocchi": [
            "Digital Marketing Manager",
            "E-commerce Strategist",
            "Data Analyst",
            "SEO Specialist",
            "Social Media Manager",
            "User Experience Designer",
            "Content Strategist",
            "Digital Project Manager",
            "Online Community Manager",
            "CRM Specialist"
        ]
    },
    {
        "nome": "Scienze dell'Economia (Green Economy e Sostenibilità)",
        "Area": "Economia",
        "H1": "Corso di Laurea Economia green e sostenibilità, Università Digitale eCampus",
        "tag 1": "Laurea Magistrale",
        "tag 2": "Durata: 2 anni",
        "tag 3": "120 cfu",
        "tag 4": "Anno accademico 2023/2024",
        "tag 5": "LM-56",
        "Descrizione": "Il Corso di Laurea Magistrale in Scienze dell'Economia (Curriculum Green Economy e Sostenibilità) di eCampus, classe LM-56, è un biennale online che forma professionisti capaci di analizzare e gestire problemi economici, con un focus sulla sostenibilità e modelli produttivi eco-compatibili. L'obiettivo è preparare esperti in politiche di crescita e sviluppo sostenibili e la green economy. Il curriculum include materie economiche, aziendali, giuridiche e matematico-statistiche, affrontando temi come economia verde e sviluppo sostenibile. Richiede 120 CFU, con lezioni e materiale digitale accessibili 24/7, e offre opportunità di tirocinio. Ideale per chi mira a ruoli di consulenza aziendale o gestione in ambiti legati alla sostenibilità.",
        "Piano di studi 1": "Economia del diritto (9 cfu), Politica economica per la crescita e lo sviluppo sostenibile (9 cfu), Storia d’impresa (9 cfu), Programmazione e controllo (9 cfu), Strategia e governo d’azienda (12 cfu), New Technology ed economia circolare (9 cfu)",
        "Piano di studi 2": "Green marketing (9 cfu), Diritto commerciale avanzato (6 cfu), Diritto tributario avanzato (6 cfu), Statistica economica (6 cfu), Gestione del portafoglio, fondi pensione e previdenza complementare (9 cfu), A scelta dello studente (9 cfu), Lingua inglese (6 cfu), Prova finale (12 cfu)",
        "operatore 1": "eCampus",
        "operatore 2": "Comparacorsi",
        "operatore 3": "Altri operatori",
        "prezzo 1": "3.900€",
        "prezzo 2": "2.600€",
        "prezzo 3": "4.200€",
        "Domanda 1": "Quali prerequisiti di conoscenza sono necessari per accedere al corso di laurea magistrale in Scienze dell'Economia (Green Economy e Sostenibilità) di eCampus?",
        "Risposta 1": "È richiesta una laurea triennale con basi in economia, aziendale, matematico-statistico e giuridico, oltre alla conoscenza dell'inglese. Un test valuta le competenze per identificare eventuali Obblighi Formativi Aggiuntivi.",
        "Domanda 2": "Come viene erogata la didattica nel corso magistrale di economia green e sostenibilità?",
        "Risposta 2": "Attraverso videolezioni, web conference, quiz interattivi, esercitazioni pratiche e seminari. Materiale didattico e supporto sono disponibili online h24 su piattaforma eCampus.",
        "Domanda 3": "Quali competenze specifiche svilupperò nel corso di economia green e sostenibilità?",
        "Risposta 3": "Acquisirai competenze in modelli economici sostenibili, green marketing, economia circolare e gestione aziendale sostenibile, preparandoti a gestire progetti eco-compatibili e politiche di sviluppo sostenibile.",
        "Domanda 4": "Posso iscrivermi al corso di economia green e sostenibilità in qualsiasi periodo dell'anno?",
        "Risposta 4": "Sì, le iscrizioni sono aperte tutto l'anno, senza costi aggiuntivi, offrendo flessibilità agli studenti per iniziare il loro percorso formativo secondo le proprie esigenze.",
        "Domanda 5": "Il corso di laurea magistrale in economia green prevede possibilità di tirocinio?",
        "Risposta 5": "Sì, il corso include opportunità di tirocini formativi presso enti pubblici o privati, oltre a studi professionali, per applicare praticamente le conoscenze acquisite e acquisire esperienza sul campo.",
        "Sbocchi": [
            "Consulente in sostenibilità aziendale",
            "Analista di politiche ambientali",
            "Manager di progetti di economia circolare",
            "Esperto in corporate social responsibility",
            "Specialista in green marketing",
            "Responsabile di supply chain sostenibile",
            "Analista di rischio ambientale e sostenibilità",
            "Coordinatore per lo sviluppo sostenibile",
            "Consulente in energie rinnovabili e gestione dell'energia",
            "Esperto in gestione dei rifiuti e riciclo"
        ]
    },
    {
        "nome": "Scienze Economiche",
        "Area": "Economia",
        "H1": "Corso di Laurea in scienze economiche, Università Digitale eCampus",
        "tag 1": "Laurea Magistrale",
        "tag 2": "Durata: 2 anni",
        "tag 3": "120 cfu",
        "tag 4": "Anno accademico 2023/2024",
        "tag 5": "LM-56",
        "Descrizione": "La Laurea Magistrale in Scienze Economiche dell'università telematica eCampus, della durata di 2 anni, forma professionisti in economia con una visione critica del contesto economico-culturale. Con un percorso formativo multidisciplinare in ambiti economico, aziendale, giuridico e matematico-statistico, il corso online mira a sviluppare competenze avanzate per analizzare e gestire sistemi economici complessi. Gli studenti acquisiscono strumenti per l'analisi economica, il controllo di gestione, il marketing e il diritto d’impresa e il diritto commerciale. Adatto a chi possiede una laurea triennale con basi in discipline economiche, il programma è erogato online da eCampus, offrendo flessibilità e approfondimenti, preparando gli studenti per ruoli dirigenziali e consulenziali in diversi settori.",
        "Piano di studi 1": "Economia del diritto (9 cfu), Economia dell’ambiente (9 cfu), Storia d’impresa (9 cfu), programmazione e controllo (9 cfu), Strategia e governo d’azienda (12 cfu), Economia dello sviluppo (9 cfu)",
        "Piano di studi 2": "Marketing avanzato (9 cfu), Diritto commerciale avanzato (6 cfu), Diritto tributario avanzato (6 cfu), Statistica economica (6 cfu), Gestione del portafoglio, Fondi pensione e previdenza complementare (9 cfu), lingua inglese (6 cfu), prova finale (12 cfu)",
        "operatore 1": "eCampus",
        "operatore 2": "Comparacorsi",
        "operatore 3": "Altri operatori",
        "prezzo 1": "3.900€",
        "prezzo 2": "2.600€",
        "prezzo 3": "4.200€",
        "Domanda 1": "Quali prerequisiti sono necessari per l'iscrizione alla Laurea Magistrale in Scienze Economiche all'eCampus?",
        "Risposta 1": "È richiesta una laurea triennale con conoscenze di base in ambiti economico, aziendale, matematico-statistico, giuridico e linguistico. Inoltre, è previsto un test iniziale per valutare la preparazione.",
        "Domanda 2": "Come è strutturata la didattica online per il corso di laurea magistrale eCampus?",
        "Risposta 2": "La didattica online include videolezioni, materiali, esercitazioni, quiz interattivi e partecipazione a seminari. Tutti accessibili 24/7 attraverso la piattaforma eCampus per un'esperienza di apprendimento flessibile.",
        "Domanda 3": "È possibile iscriversi al corso di laurea in Scienze Economiche in qualsiasi periodo dell'anno?",
        "Risposta 3": "Sì, l'iscrizione alla laurea magistrale è possibile in qualsiasi periodo dell'anno, offrendo flessibilità agli studenti di eCampus.",
        "Domanda 4": "Quali competenze linguistiche sono richieste per il corso di laurea magistrale in Scienze Economiche?",
        "Risposta 4": "È necessaria una conoscenza di base della lingua inglese, poiché il corso può includere materiali e testi in inglese per ampliare le competenze internazionali degli studenti.",
        "Domanda 5": "C'è supporto disponibile per gli studenti online del corso di laurea magistrale in Scienze Economiche?",
        "Risposta 5": "Sì, gli studenti di eCampus hanno accesso a tutoraggio, supporto tecnico e accademico, e risorse di apprendimento per garantire una formazione completa e assistita.",
        "Sbocchi": [
            "Analista finanziario",
            "Consulente aziendale",
            "Economista aziendale",
            "Direttore del marketing digitale",
            "Responsabile della sostenibilità",
            "Analista di mercato",
            "Specialista in controllo di gestione",
            "Esperto in politiche economiche",
            "Manager di progetti internazionali",
            "Esperto in strategie di crescita e sviluppo aziendale"
        ]
    },
    {
        "nome": "Economia e Sostenibilità",
        "Area": "Economia",
        "H1": "Corso di Laurea in Economia sostenibile, Università Digitale Unitelma",
        "tag 1": "Laurea Magistrale",
        "tag 2": "Durata: 2 anni",
        "tag 3": "120 cfu",
        "tag 4": "Anno accademico 2023/2024",
        "tag 5": "LM-77",
        "Descrizione": "Il Corso di Laurea Magistrale in Economia, management e innovazione di Unitelma, con durata biennale, è orientato a formare specialisti con competenze avanzate in gestione aziendale, diritto e metodi quantitativi, con un focus su sostenibilità, innovazione e risparmio energetico. Il programma offre cinque curricula, tra cui \"Economia e Sostenibilità\", per approfondire settori specifici come l'innovazione aziendale e la finanza ambientale. Si mira a creare professionisti capaci di gestire il cambiamento e promuovere modelli economici sostenibili in contesti nazionali e internazionali, fornendo loro una solida base in economia aziendale arricchita da conoscenze giuridiche e quantitative. Il corso è erogato online, garantendo flessibilità e accesso a materiali didattici avanzati, e termina con la redazione di una tesi.",
        "Piano di studi 1": "Scienze Della Sostenibilità: Fondamenti (12 cfu), Nuovi Modelli Organizzativi Per La Sostenibilità (12 cfu), Economia Ambientale E Politiche Per La Sostenibilità (6 cfu), Corporate Social Responsibility (6 cfu), Diritto Industriale (6 cfu), A Scelta Tra: Economia Circolare: Nuovi Modelli Di Produzione E Di Consumo (12 cfu), Economia Comportamentale (12 cfu), A Scelta Tra: Diritto Comparato Dell'ambiente E Della Sostenibilità (6 cfu), Diritto Internazionale Dell'ambiente (6 cfu)",
        "Piano di studi 2": "Bioeconomia Circolare (6 cfu), Finanza Sostenibile (12 cfu), Indicatori E Metodologie Statistiche Per Lo Sviluppo Sostenibile (6 cfu), Ulteriori Conoscenze Linguistiche – Abilità Informatiche E Telematiche – Tirocini Formativi E Di Orientamento (3 cfu), Prova Finale (15 cfu), A Scelta Tra: Modelli Econometrici Per La Sostenibilità (6 cfu), Certificazioni Standard Per La Sostenibilità (6 cfu)",
        "operatore 1": "Unitelma",
        "operatore 2": "Comparacorsi",
        "operatore 3": "Altri operatori",
        "prezzo 1": "2.300€",
        "prezzo 2": "1.800€",
        "prezzo 3": "2.700€",
        "Domanda 1": "È possibile iscriversi al Corso di Laurea in Economia Sostenibile di Unitelma senza una laurea triennale?",
        "Risposta 1": "No, per accedere al corso magistrale è richiesta una laurea triennale o equivalente.",
        "Domanda 2": "Posso seguire il corso di Economia Sostenibile online da un paese estero?",
        "Risposta 2": "Sì, come corso online offerto da Unitelma, puoi partecipare alle lezioni e accedere ai materiali da qualsiasi paese, purché disponi di una connessione internet.",
        "Domanda 3": "Quali sono le modalità di esame per il corso online in Economia Sostenibile?",
        "Risposta 3": "Gli esami si tengono online attraverso la piattaforma di Unitelma e possono includere test scritti, orali o progetti, a seconda del corso.",
        "Domanda 4": "Come posso accedere ai materiali didattici del corso di Economia Sostenibile?",
        "Risposta 4": "Dopo l'iscrizione, avrai accesso alla piattaforma e-learning di Unitelma, dove troverai videolezioni e dispense disponibili in qualsiasi momento.",
        "Domanda 5": "È previsto un supporto per gli studenti nel corso online in Economia Sostenibile?",
        "Risposta 5": "Certo, Unitelma offre supporto agli studenti tramite tutor online, forum di discussione e assistenza tecnica, assicurando un apprendimento efficace.",
        "Sbocchi": [
            "Consulente in sostenibilità aziendale",
            "Analista di politiche ambientali",
            "Responsabile della CSR (Corporate Social Responsibility)",
            "Esperto in economia circolare",
            "Manager di progetti di sviluppo sostenibile",
            "Responsabile di supply chain sostenibile",
            "Analista finanziario con specializzazione in investimenti sostenibili",
            "Esperto in certificazioni ambientali e di sostenibilità",
            "Consulente per l'innovazione verde e l'eco-efficienza",
            "Coordinatore di iniziative per la riduzione dell'impatto ambientale aziendale"
        ]
    },
    {
        "nome": "Economia, Finanza e Banca",
        "Area": "Economia",
        "H1": "Corso di Laurea in Economia banca e finanza, Università Digitale Unitelma",
        "tag 1": "Laurea Magistrale",
        "tag 2": "Durata: 2 anni",
        "tag 3": "120 cfu",
        "tag 4": "Anno accademico 2023/2024",
        "tag 5": "LM-77",
        "Descrizione": "Il Corso di Laurea Magistrale in Economia, Banca e Finanza di Unitelma, curriculum del corso di laurea online Economia, management e innovazione, mira a formare professionisti con competenze avanzate in economia aziendale, gestione bancaria e finanza, integrando conoscenze economico-aziendali, giuridiche e quantitative. Con un focus su innovazione, gestione del cambiamento e interdisciplinarità, il corso sviluppa capacità analitiche e di gestione per il settore bancario e finanziario. Le competenze acquisite preparano a ruoli responsabili in amministrazione, gestione aziendale e finanza, in un contesto internazionale e multiculturale, con un forte accento su innovazione sostenibile e strategie aziendali. ",
        "Piano di studi 1": "Mercati Internazionali E Marketing (12 cfu), Programmazione E Controllo Degli Enti Finanziari (6 cfu), Economia Dei Mercati Finanziari E Teoria Del Portafoglio (12 cfu), Politica Economica E Monetaria (12 cfu), Diritto Dei Mercati Finanziari (6 cfu), Economia Pubblica (6 cfu), Innovazione, Competitività E Strategie Bancarie (6 cfu)",
        "Piano di studi 2": "Teoria Dei Giochi, Del Credito E Finanziaria (6 cfu), Diritto Bancario E Finanziario (6 cfu), Introduzione Alla Misura Dei Rischi Finanziari (6 cfu), Finanza Comportamentale (6 cfu), Ulteriori Conoscenze Linguistiche – Abilità Informatiche E Telematiche – Tirocini Formativi E Di Orientamento (3 cfu), Prova Finale (15 cfu), A Scelta Tra: Data Analysis (6 cfu), Modelli Econometrici Per La Finanza (6 cfu)",
        "operatore 1": "Unitelma",
        "operatore 2": "Comparacorsi",
        "operatore 3": "Altri operatori",
        "prezzo 1": "2.300€",
        "prezzo 2": "1.800€",
        "prezzo 3": "2.700€",
        "Domanda 1": "Quali sono i prerequisiti per iscriversi al corso di Economia Banca e Finanza?",
        "Risposta 1": "Per iscriversi è richiesta una laurea triennale in ambito economico o affine. È importante anche una buona conoscenza della matematica e delle basi di economia aziendale.",
        "Domanda 2": "È possibile seguire il corso di Economia Banca e Finanza online?",
        "Risposta 2": "Sì, come università telematica, Unitelma offre la possibilità di seguire il corso interamente online, con piattaforme e-learning e supporto didattico digitale.",
        "Domanda 3": "Come vengono valutate le competenze acquisite durante il corso?",
        "Risposta 3": "Le competenze sono valutate tramite esami scritti e orali, progetti, casi studio e una tesi finale, per assicurare una comprensione approfondita della materia.",
        "Domanda 4": "Quali metodi di apprendimento sono utilizzati nel corso di laurea?",
        "Risposta 4": "Il corso utilizza una combinazione di lezioni digitali, esercitazioni, seminari online, lavori di gruppo e studio individuale per garantire un apprendimento completo e flessibile.",
        "Domanda 5": "Come posso accedere ai materiali didattici per il corso di Economia Banca e Finanza?",
        "Risposta 5": "I materiali didattici sono disponibili sulla piattaforma e-learning dell’università, accessibile h24, dove troverai lezioni, risorse aggiuntive e forum per interagire con docenti e compagni.",
        "Sbocchi": [
            "Analista finanziario",
            "Consulente per la gestione del rischio",
            "Esperto di credito in banca",
            "Responsabile di portafoglio investimenti",
            "Specialista in operazioni bancarie",
            "Consulente di finanza aziendale",
            "Direttore finanziario (CFO)",
            "Auditor interno per enti finanziari",
            "Broker di borsa",
            "Analista di mercato dei capitali"
        ]
    },
    {
        "nome": "Economia, Tecnologia, Innovazione",
        "Area": "Economia",
        "H1": "Corso di Laurea in Innovazione e tecnologia (economia), Università Digitale Unitelma",
        "tag 1": "Laurea Magistrale",
        "tag 2": "Durata: 2 anni",
        "tag 3": "120 cfu",
        "tag 4": "Anno accademico 2023/2024",
        "tag 5": "LM-77",
        "Descrizione": "Il Corso di Laurea in Economia, Tecnologia e Innovazione di Unitelma, completamente online, combina competenze economico-aziendali, giuridiche e quantitative per formare professionisti capaci di gestire e innovare in un mercato globale. Con focus su innovazione e tecnologia, prepara gli studenti a comprendere e guidare il cambiamento nei vari settori aziendali, bancari e finanziari. Il percorso offre insegnamenti diversificati, con un'ampia cultura gestionale e aziendale, e un forte orientamento internazionale. Gli studenti acquisiranno competenze critiche e interdisciplinari per posizioni di responsabilità e consulenza.",
        "Piano di studi 1": "Economia Dei Paesi Emergenti (9 cfu), Sistemi Di Rendicontazione Aziendale (9 cfu), Diritto Industriale (9 cfu), Diritto Bancario E Finanziario (9 cfu), Sostenibilità Ambientale E Sviluppo Sostenibile (6 cfu), Management Dell'innovazione (9 cfu), Teoria Dei Giochi, Del Credito E Finanziaria (6 cfu), Economia Pubblica (6 cfu)",
        "Piano di studi 2": "Economia Dell'innovazione (12 cfu), Corporate Finance (9 cfu), Gestione Delle Informazioni Aziendali (6 cfu), Ulteriori Conoscenze Linguistiche - Abilità Informatiche E Telematiche - Tirocini Formativi (3 cfu), Prova Finale (15 cfu), A Scelta Tra: Modelli Computazionali Per L'economia E La Finanza (6 cfu), Statistica Per Le Imprese (6 cfu)",
        "operatore 1": "Unitelma",
        "operatore 2": "Comparacorsi",
        "operatore 3": "Altri operatori",
        "prezzo 1": "2.300€",
        "prezzo 2": "1.800€",
        "prezzo 3": "2.700€",
        "Domanda 1": "Quali sono i requisiti di ammissione al corso di laurea in Economia, Tecnologia e Innovazione?",
        "Risposta 1": "Per iscriversi è necessario avere una laurea triennale o equivalente, con basi in ambiti economico, aziendale o tecnologico. Un test iniziale valuterà la tua preparazione.",
        "Domanda 2": "Come si svolgono le lezioni online di Unitelma?",
        "Risposta 2": "Le lezioni si tengono tramite una piattaforma e-learning avanzata, con video, materiali di studio digitali e sessioni interattive.",
        "Domanda 3": "C'è supporto per gli studenti online?",
        "Risposta 3": "Sì, gli studenti hanno accesso a tutoraggio, forum di discussione e assistenza tecnica per garantire un percorso formativo supportato e interattivo.",
        "Domanda 4": "Posso studiare mentre lavoro?",
        "Risposta 4": "Il corso online è flessibile e ideale per chi lavora, permettendo di gestire autonomamente orari e ritmi di studio.",
        "Domanda 5": "Quali competenze svilupperò con questo corso?",
        "Risposta 5": "Svilupperai competenze in gestione aziendale, analisi dei mercati finanziari, innovazione tecnologica e capacità decisionali strategiche.",
        "Sbocchi": [
            "Analista finanziario",
            "Consulente di strategia aziendale",
            "Esperto in gestione dell'innovazione",
            "Manager della tecnologia dell'informazione",
            "Analista di mercato",
            "Responsabile della pianificazione e dello sviluppo aziendale",
            "Consulente per le start-up tecnologiche",
            "Specialista in risorse umane con focus su tecnologia e innovazione",
            "Esperto in economia circolare e sostenibilità",
            "Responsabile di progetti di digitalizzazione aziendale"
        ]
    },
    {
        "nome": "Economy, Technology, Innovation (in lingua Inglese)",
        "Area": "Economia",
        "H1": "Corso di Laurea in Technology innovation, Economy, Università Digitale Unitelma",
        "tag 1": "Laurea Magistrale",
        "tag 2": "Durata: 2 anni",
        "tag 3": "120 cfu",
        "tag 4": "Anno accademico 2023/2024",
        "tag 5": "LM-77",
        "Descrizione": "Il corso di Laurea Magistrale in Technology Innovation and Economy dell'Università Digitale Unitelma è una laurea specialistica online di 2 anni focalizzata su economia, management e innovazione. Specializzazioni come Economia e Management Aziendale, Economia, Tecnologia e Innovazione, e percorsi internazionali in inglese, preparano gli studenti a diventare leader nel settore tecnologico e innovativo. Attraverso un mix di teoria e pratica, il corso promuove l'acquisizione di competenze avanzate in analisi e gestione, preparando i laureati a posizioni di rilievo in aziende e organizzazioni pubbliche e private, a livello nazionale e internazionale, con un forte orientamento verso la tecnologia e l'innovazione.",
        "Piano di studi 1": "Economics Of Innovation (9 cfu), Corporate Finance And Financial Innovation (12 cfu), Artificial Economics: Simulation And Computational Methods (6 cfu), Technology, Innovation And Sustainability Transition (6 cfu), Business And Human Rights (5 cfu), Data Mining (4 cfu), Multilevel Governance And Decision Making (12 cfu)",
        "Piano di studi 2": "Innovation In Emerging Countries (9 cfu), Corporate And Financial Law (9 cfu), Management Of Innovation (12 cfu), Further Language Knowledge - Computer And Telematic Skills - Training And Orientation Internships (9 cfu), Final Exam (15 cfu)",
        "operatore 1": "Unitelma",
        "operatore 2": "Comparacorsi",
        "operatore 3": "Altri operatori",
        "prezzo 1": "2.300€",
        "prezzo 2": "1.800€",
        "prezzo 3": "2.700€",
        "Domanda 1": "Quali sono i requisiti per l'accesso al corso di Laurea Magistrale in Technology Innovation and Economy?",
        "Risposta 1": "Per accedere è necessario possedere una laurea di primo livello o un titolo equivalente. Potrebbero essere richiesti ulteriori requisiti specifici, come una solida base in economia, management o discipline scientifiche.",
        "Domanda 2": "Posso frequentare il corso se lavoro a tempo pieno?",
        "Risposta 2": "Sì, il corso online è progettato per offrire flessibilità e consentire agli studenti di bilanciare studio e lavoro. Le risorse didattiche e le lezioni sono accessibili 24/7.",
        "Domanda 3": "Come avviene l'esame finale per la Laurea Magistrale?",
        "Risposta 3": "L'esame finale si svolge online sotto supervisione, con una presentazione o discussione di una tesi o progetto finale che dimostri la tua comprensione e specializzazione nell'area di studi.",
        "Domanda 4": "Sono previsti momenti di interazione diretta con i docenti?",
        "Risposta 4": "Sì, il corso prevede webinar, lezioni interattive e sessioni di tutoraggio dove potrai interagire direttamente con i docenti e altri studenti per una più ricca esperienza di apprendimento.",
        "Domanda 5": "Il corso offre opportunità di networking con professionisti del settore?",
        "Risposta 5": "Il corso prevede eventi online, seminari e incontri con esperti del settore per favorire il networking e l'aggiornamento professionale costante.",
        "Sbocchi": [
            "Analista di business",
            "Consulente strategico",
            "Manager dell'innovazione",
            "Esperto in trasformazione digitale",
            "Analista finanziario",
            "Product manager",
            "Responsabile sviluppo sostenibile",
            "Esperto in gestione del rischio",
            "Specialista in politiche pubbliche e economiche",
            "Imprenditore nel settore tecnologico"
        ]
    },
    {
        "nome": "Scienze Economiche (Innovazione digitale)",
        "Area": "Economia",
        "H1": "Corso di Laurea in Scienze economiche Unicusano",
        "tag 1": "Laurea Magistrale",
        "tag 2": "Durata: 2 anni",
        "tag 3": "120 cfu",
        "tag 4": "Anno accademico 2023/2024",
        "tag 5": "LM-56",
        "Descrizione": "Il Corso di Laurea Magistrale in Scienze Economiche Unicusano offre un approccio completo all'economia, mirato a formare professionisti pronti per il mercato globale e digitale. In due anni, gli studenti esplorano sia la microeconomia, con studi sulle dinamiche individuali e industriali, sia la macroeconomia, analizzando l'attività economica su scala più ampia, preparandoli a gestire le sfide economiche in un contesto internazionale e tecnologicamente avanzato. Con i curriculum 'Mercati Globali' e 'Innovazione Digitale', Scienze economiche di Unicusano si impegna a fornire una formazione multidisciplinare che combina economia, diritto e metodi quantitativi, ideale per chi cerca una formazione online flessibile ma rigorosa.",
        "Piano di studi 1": "Ragioneria generale e applicata II (9 cfu), Materia opzionale tra: Management della sostenibilità e dell’innovazione (9 cfu), Finanza comportamentale ed Etica (9 cfu), Economia pubblica e delle risorse naturali (9 cfu), Storia del pensiero economico (9 cfu), Tecnologia dei cicli produttivi (13 cfu), Statistica economica e finanziaria (9 cfu), Revisione aziendale (6 cfu)",
        "Piano di studi 2": "Economia e Finanza Internazionale (6 cfu), Teoria delle reti e delle decisioni (9 cfu), Diritto commerciale – mercati globali (6 cfu), Economia e gestione delle imprese internazionali (9 cfu), Ulteriori conoscenze linguistiche (English for Business oppure Lingua Spagnola) (5 cfu), Materia a scelta dello studente (9 cfu), Tirocini (2 cfu), Prova finale (14 cfu)",
        "operatore 1": "Unicusano",
        "operatore 2": "Comparacorsi",
        "operatore 3": "Altri operatori",
        "prezzo 1": "4.100€",
        "prezzo 2": "4.000€",
        "prezzo 3": "4.300€",
        "Domanda 1": "Quali sono i prerequisiti per iscriversi al Corso di Scienze Economiche Unicusano?",
        "Risposta 1": "Per iscriversi è richiesta una laurea triennale nelle classi L-18 o L-33 o un titolo equivalente riconosciuto idoneo. Non sono previste prove selettive.",
        "Domanda 2": "Come si svolgono gli esami nel Corso di Scienze Economiche Unicusano?",
        "Risposta 2": "Gli esami si svolgono online attraverso la piattaforma e-learning di Unicusano, permettendo agli studenti di sostenere le prove comodamente e in linea con i moderni standard digitali.",
        "Domanda 3": "Il Corso di Scienze Economiche Unicusano offre specializzazioni?",
        "Risposta 3": "Sì, offre due curricula: 'Mercati Globali' e 'Innovazione Digitale', ciascuno focalizzato su aspetti diversi dell'economia in contesti globali e tecnologici.",
        "Domanda 4": "Come posso accedere alle risorse didattiche del Corso di Scienze Economiche Unicusano?",
        "Risposta 4": "Tutte le risorse sono accessibili online tramite la piattaforma di e-learning di Unicusano, fornendo flessibilità e facilità di accesso a lezioni, materiali e supporto didattico.",
        "Domanda 5": "Qual è l'approccio metodologico del Corso di Scienze Economiche Unicusano?",
        "Risposta 5": "Il corso adotta un approccio interdisciplinare, integrando economia, diritto e metodi quantitativi, e pone enfasi sull'analisi critica e l'applicazione pratica delle teorie economiche in contesti reali.",
        "Sbocchi": [
            "Consulente economico",
            "Economista aziendale",
            "Responsabile del controllo di gestione",
            "Analista di mercato",
            "Funzionario in istituti di credito",
            "Specialista in politiche economiche pubbliche",
            "Responsabile di strategie di sviluppo internazionale",
            "Consulente per la sostenibilità economica",
            "Docente o ricercatore in scienze economiche"
        ]
    },
    {
        "nome": "Scienze dell'Economia (Economista/Manager delle organizzazioni per la salute)",
        "Area": "Economia",
        "H1": "Corso di Laurea in Economista/Manager delle organizzazioni per la salute, Università Digitale Unidav",
        "tag 1": "Laurea Magistrale",
        "tag 2": "Durata: 2 anni",
        "tag 3": "120 cfu",
        "tag 4": "Anno accademico 2023/2024",
        "tag 5": "LM-56",
        "Descrizione": "La laurea magistrale online in Economista/Manager delle organizzazioni per la salute offerta dall'Università Telematica Leonardo da Vinci (UniDav) si colloca nella classe di laurea LM-56. Il corso, della durata di 2 anni, è progettato per formare professionisti qualificati in grado di operare efficacemente in organizzazioni pubbliche e private, con una forte enfasi sul settore della tutela del benessere e della salute individuale e collettiva. Il piano di studi, articolato in quattro macro-settori (aziendale, economico, giuridico, statistico-matematico), permette agli studenti di acquisire competenze approfondite e multidisciplinari. Attraverso lezioni audio/video, project work, case study, workshop, e forum tematici a distanza, il corso offre un'offerta formativa innovativa e flessibile.",
        "Piano di studi 1": "Territorio, impresa, ambiente e sviluppo (12 cfu), Metodi di analisi dei dati (6 cfu), Politica economica europea (12 cfu), Economia delle aziende e delle PA (12 cfu), Psicologia del lavoro (6 cfu), Abilità informatiche (3 cfu), Lingua inglese (3 cfu), Esame a scelta (6 cfu)",
        "Piano di studi 2": "Statistica per le professioni sanitarie (6 cfu), Diritto pubblico delle nuove tecnologie in ambito sanitario (12 cfu), Economia e gestione delle aziende sanitarie (12 cfu), Diritto Sanitario (6 cfu), Controllo di gestione nelle aziende sanitarie (6 cfu), Esame a scelta (6 cfu), Prova finale (12 cfu)",
        "operatore 1": "Unidav",
        "operatore 2": "Comparacorsi",
        "operatore 3": "Altri operatori",
        "prezzo 1": "2.400€",
        "prezzo 2": "2.200€",
        "prezzo 3": "2.700€",
        "Domanda 1": "Qual è la modalità di erogazione delle lezioni nel corso online di laurea magistrale di Unidav?",
        "Risposta 1": "Le lezioni del corso di laurea magistrale online in 'Economista/Manager delle organizzazioni per la salute' di UniDav sono erogate in modalità telematica, con lezioni audio/video, forum tematici e workshop interattivi per garantire un'esperienza formativa completa e flessibile.",
        "Domanda 2": "Come posso accedere al corso di laurea online in Economista/Manager presso Unidav?",
        "Risposta 2": "Per accedere alla laurea magistrale di UniDav, è necessario possedere un titolo di laurea triennale o un diploma universitario equivalente, riconosciuto nelle classi L-18, L-33, L-17, o L-28. Ulteriori dettagli sono disponibili sul sito dell'università telematica.",
        "Domanda 3": "Quali sono i requisiti tecnici per frequentare il corso online di laurea magistrale in economia e organizzazioni per la salute?",
        "Risposta 3": "Per partecipare al corso online di Unidav, gli studenti devono avere una connessione internet stabile, un computer o dispositivo compatibile e software per visualizzare lezioni video e partecipare a forum e workshop online.",
        "Domanda 4": "C'è un limite di età per iscriversi alla laurea online in Economista/Manager delle organizzazioni per la salute?",
        "Risposta 4": "Non c'è un limite di età per iscriversi alla laurea magistrale di Unidav. L'università telematica accoglie studenti di tutte le età desiderosi di avanzare nella loro formazione in economia e gestione della salute.",
        "Domanda 5": "Come posso richiedere informazioni aggiuntive sul corso di laurea online in Economista/Manager di Unidav?",
        "Risposta 5": "Per ottenere ulteriori informazioni sul corso di laurea magistrale online, puoi visitare il sito di Unidav, contattare direttamente l'ufficio ammissioni dell'università telematica o scaricare il piano di studi direttamente dalla loro piattaforma.",
        "Sbocchi": [
            "Consulente in economia della salute",
            "Manager ospedaliero",
            "Analista di politiche sanitarie",
            "Responsabile di progetto in organizzazioni non governative (ONG) sanitarie",
            "Esperto in innovazione tecnologica per la salute",
            "Direttore di case di cura o strutture sanitarie private",
            "Funzionario in enti pubblici del settore sanitario",
            "Consulente per la digitalizzazione nel settore sanitario",
            "Responsabile di compliance in aziende farmaceutiche",
            "Ricercatore in economia e management della salute"
        ]
    },
    {
        "nome": "Scienze dell'Economia (Specialista in attività economiche, di gestione e di controllo)",
        "Area": "Economia",
        "H1": "Corso di Laurea in Specialista in attività economiche, di gestione e di controllo, Università Digitale Unidav",
        "tag 1": "Laurea Magistrale",
        "tag 2": "Durata: 2 anni",
        "tag 3": "120 cfu",
        "tag 4": "Anno accademico 2023/2024",
        "tag 5": "LM-56",
        "Descrizione": "Il Corso di Laurea Magistrale in 'Specialista in attività economiche, di gestione e di controllo' offerto da UniDav, università telematica, si colloca nella classe LM-56 e dura 2 anni. È indirizzato a chi desidera approfondire le proprie competenze nelle attività economiche e acquisire specializzazione in gestione e controllo in contesti aziendali. Il corso online è progettato per offrire una formazione avanzata e flessibile, combinando settore aziendale, economico, giuridico e statistico-matematico. Con esami mirati e un approccio pratico, la laurea magistrale prepara specialisti pronti per un mercato in continua evoluzione, fornendo tutte le competenze necessarie per eccellere nel campo delle attività economiche.",
        "Piano di studi 1": "Territorio, impresa, ambiente e sviluppo (12 cfu), Metodi di analisi dei dati (6 cfu), Politica economica europea (12 cfu), Economia delle aziende e delle PA (12 cfu), Psicologia del lavoro (6 cfu), Abilità informatiche (3 cfu), Lingua inglese (3 cfu), Esame a scelta (6 cfu)",
        "Piano di studi 2": "Statistica per le decisioni d’impresa (6 cfu), Diritto pubblico dell’economia e delle nuove tecnologie (12 cfu), Controllo di gestione (6 cfu), Diritto delle amministrazioni e dei servizi pubblici (6 cfu), Revisione contabile e gestionale delle aziende e della P.A. (6 cfu), Esame a scelta (6 cfu), Prova finale (12 cfu)",
        "operatore 1": "Unidav",
        "operatore 2": "Comparacorsi",
        "operatore 3": "Altri operatori",
        "prezzo 1": "2.400€",
        "prezzo 2": "2.200€",
        "prezzo 3": "2.700€",
        "Domanda 1": "Quali sono le modalità di erogazione delle lezioni nel corso di laurea magistrale in attività economiche di UniDav?",
        "Risposta 1": "Le lezioni sono fornite interamente online, tramite piattaforma telematica di UniDav, con materiali didattici come lezioni audio/video, forum di discussione e workshop interattivi.",
        "Domanda 2": "Come posso verificare la mia idoneità per il corso di specialista in gestione e controllo?",
        "Risposta 2": "Puoi verificare la tua idoneità contattando l'ufficio ammissioni di UniDav o consultando i requisiti sul sito dell'università, dove troverai le classi di laurea riconosciute.",
        "Domanda 3": "Quali sono i requisiti tecnici per partecipare al corso online di UniDav?",
        "Risposta 3": "Per partecipare, hai bisogno di una connessione internet stabile, un computer o dispositivo mobile compatibile e software necessario per visualizzare contenuti multimediali e partecipare a sessioni interattive.",
        "Domanda 4": "Posso richiedere una borsa di studio per il corso in attività economiche?",
        "Risposta 4": "UniDav potrebbe offrire borse di studio o agevolazioni finanziarie. Ti consigliamo di consultare la sezione dedicata sul sito di UniDav o contattare direttamente l'università per ulteriori informazioni.",
        "Domanda 5": "C'è un servizio di tutoraggio disponibile durante il corso di laurea magistrale online?",
        "Risposta 5": "Sì, UniDav fornisce un servizio di tutoraggio per assistere gli studenti durante il loro percorso formativo, aiutandoli a superare eventuali difficoltà e guidandoli negli esami e nelle attività didattiche.",
        "Sbocchi": [
            "Analista finanziario",
            "Consulente aziendale per la gestione e controllo",
            "Responsabile controllo di gestione",
            "Auditor interno in aziende e corporazioni",
            "Responsabile amministrativo",
            "Funzionario in enti pubblici per la gestione economica",
            "Analista di rischio in istituti bancari",
            "Specialista in budgeting e forecasting",
            "Consulente per l'ottimizzazione dei processi aziendali",
            "Direttore finanziario (CFO) in piccole e medie imprese."
        ]
    },
    {
        "nome": "Management",
        "Area": "Economia",
        "H1": "Corso di Laurea in Management, Università Telematica Mercatorum",
        "tag 1": "Laurea Magistrale",
        "tag 2": "Durata: 2 anni",
        "tag 3": "120 cfu",
        "tag 4": "Anno accademico 2023/2024",
        "tag 5": "LM-77",
        "Descrizione": "La laurea magistrale in Management offerta dall'Università Telematica Mercatorum è un corso online di 2 anni che prepara gli studenti a governare aziende e gestire processi e funzioni aziendali. Il corso copre aree aziendali, giuridiche, economiche e statistiche e prepara, per definizione, a carriere in campo manageriale. Gli studenti acquisiscono capacità analitiche e di problem solving, apprendendo a valutare implicazioni tecniche, strategiche e organizzative delle decisioni aziendali. Il percorso include stage curricolari, offrendo esperienza pratica e facilitando l'inserimento nel mercato del lavoro. Con un piano di studi approfondito e stage formativi, Mercatorum forma specialisti pronti a inserirsi in un contesto lavorativo dinamico e internazionale.",
        "Piano di studi 1": "Principi contabili (12 cfu), Statistica aziendale (10 cfu), Diritto fallimentare (10 cfu), Politica Economica (12 cfu), Programmazione e controllo (10 cfu), Business english (8 cfu)",
        "Piano di studi 2": "Organizzazione e sviluppo delle risorse umane (10 cfu), Business planning (6 cfu), Diritto tributario  (6 cfu), Diritto commerciale Avanzato (10 cfu), Insegnamento a scelta  (8 cfu), Per stages e tirocini presso imprese, enti pubblici o privati, ordini professionali  (6 cfu), Prova Finale (12 cfu)",
        "operatore 1": "Mercatorum",
        "operatore 2": "Comparacorsi",
        "operatore 3": "Altri operatori",
        "prezzo 1": "3.400€",
        "prezzo 2": "1.700€",
        "prezzo 3": "3.700€",
        "Domanda 1": "Qual è la modalità di erogazione delle lezioni per la laurea magistrale in Scienze Economiche Management di Mercatorum?",
        "Risposta 1": "Il corso di laurea magistrale online è erogato tramite una piattaforma telematica con accesso a lezioni video, materiali di studio e forum interattivi per un apprendimento flessibile e accessibile.",
        "Domanda 2": "Quali sono i requisiti di ammissione per il corso in Scienze Economiche Management?",
        "Risposta 2": "È necessario possedere una laurea triennale nelle classi L-18, L-33, L-15, L-16 o un titolo estero equipollente, oltre a una buona conoscenza dell'economia, della gestione e dell'organizzazione delle imprese.",
        "Domanda 3": "Come posso verificare se sono idoneo a iscrivermi alla laurea magistrale in Scienze Economiche Management di Mercatorum?",
        "Risposta 3": "Puoi contattare direttamente l'ufficio ammissioni di Mercatorum o consultare il sito dell'università telematica per conoscere i dettagli sui test di accesso e sui requisiti specifici.",
        "Domanda 4": "Il corso in Scienze Economiche Management prevede tirocini formativi?",
        "Risposta 4": "Sì, il corso include stage tirocinio formativi presso imprese, enti pubblici o privati e ordini professionali per offrire esperienza pratica e facilitare l'integrazione nel mercato del lavoro.",
        "Domanda 5": "Quali strumenti tecnologici sono impiegati nel corso online di Scienze Economiche Management?",
        "Risposta 5": "Mercatorum utilizza una piattaforma avanzata che supporta lezioni video, forum, dibattiti interattivi e l'analisi di case studies per garantire un apprendimento dinamico e partecipato.",
        "Sbocchi": [
            "Manager aziendale",
            "Consulente di strategia aziendale",
            "Analista di business",
            "Specialista in controllo di gestione",
            "Direttore operativo",
            "Esperto in processi di internazionalizzazione",
            "Project manager",
            "Responsabile del settore finanziario",
            "Esperto in innovazione aziendale",
            "Responsabile della pianificazione e dello sviluppo aziendale."
        ]
    },
    {
        "nome": "Management e innovazione",
        "Area": "Economia",
        "H1": "Corso di Laurea in Innovazione e Management, Università Telematica Mercatorum",
        "tag 1": "Laurea Magistrale",
        "tag 2": "Durata: 2 anni",
        "tag 3": "120 cfu",
        "tag 4": "Anno accademico 2023/2024",
        "tag 5": "LM-77",
        "Descrizione": "Il Corso di Laurea Magistrale in Innovazione e Management è un percorso formativo online dell'Università Telematica Mercatorum. Attraverso studi interdisciplinari in aree aziendale, giuridica, economica e statistica, gli studenti sviluppano competenze per identificare esigenze aziendali e proporre soluzioni innovative. Il corso enfatizza l'analisi economica e finanziaria dei fenomeni aziendali, la regolazione delle attività d'impresa e la comprensione delle dinamiche di mercato. Gli studenti usufruiscono di orientamento e supporto continuo per garantire un apprendimento efficace e una preparazione completa nel campo dell'innovazione e del management per diventare leader innovativi nel mercato globale.",
        "Piano di studi 1": "Organizzazione e sviluppo delle risorse umane (8 cfu), Business planning (6 cfu), Gestione e innovazione d'impresa (10 cfu), Principi contabili (10 cfu), Programmazione e controllo  (8 cfu), Politica Economica  (12 cfu), Psicologia delle organizzazioni  (6 cfu)",
        "Piano di studi 2": "Statistica aziendale (6 cfu), Diritto commerciale avanzato  (9 cfu), Strategia, organizzazione e marketing (9 cfu), Business english (8 cfu), Insegnamento a scelta (10 cfu), Per stages e tirocini presso imprese, enti pubblici o privati, ordini professionali (6 cfu), Prova Finale (12 cfu)",
        "operatore 1": "Mercatorum",
        "operatore 2": "Comparacorsi",
        "operatore 3": "Altri operatori",
        "prezzo 1": "3.400€",
        "prezzo 2": "1.700€",
        "prezzo 3": "3.700€",
        "Domanda 1": "Qual è la modalità di erogazione delle lezioni per la laurea magistrale in Innovazione e Management di Mercatorum?",
        "Risposta 1": "Le lezioni sono fornite completamente online attraverso la piattaforma telematica di Mercatorum, con accesso a materiali didattici, lezioni video e forum di discussione per un apprendimento flessibile e interattivo.",
        "Domanda 2": "Come posso iscrivermi al corso online in Innovazione e Management?",
        "Risposta 2": "Per iscriverti, visita il sito di Universitas Mercatorum, compila il modulo di richiesta informazioni e segui le istruzioni per l'ammissione. Assicurati di soddisfare i requisiti di laurea triennale e le competenze linguistiche.",
        "Domanda 3": "Il corso in Innovazione e Management prevede opportunità di orientamento per gli studenti?",
        "Risposta 3": "Sì, Mercatorum offre servizi di orientamento e supporto agli studenti per guidarli attraverso il loro percorso formativo e aiutarli a massimizzare il loro apprendimento e sviluppo.",
        "Domanda 4": "Quali sono i requisiti tecnici per frequentare il corso online di Innovazione e Management?",
        "Risposta 4": "Gli studenti necessitano di un computer o dispositivo compatibile con accesso a internet, oltre al software necessario per visualizzare i contenuti didattici online e partecipare a sessioni interattive.",
        "Domanda 5": "Come posso accedere ai materiali del corso per la laurea online in Innovazione e Management?",
        "Risposta 5": "Una volta iscritto, avrai accesso alla piattaforma di e-learning di Mercatorum dove potrai trovare tutti i materiali didattici, le lezioni registrate, le risorse aggiuntive e i forum di discussione.",
        "Sbocchi": [
            "Manager dell'innovazione",
            "Consulente strategico aziendale",
            "Analista di business process",
            "Specialista in gestione del cambiamento",
            "Responsabile sviluppo prodotto",
            "Esperto in digital transformation",
            "Direttore operativo (COO)",
            "Project manager per progetti innovativi",
            "Responsabile di ricerca e sviluppo (R&D)",
            "Imprenditore in start-up tecnologiche."
        ]
    },
    {
        "nome": "Economia e Management dei trasporti",
        "Area": "Economia",
        "H1": "Corso di Laurea in Economia dei Trasporti e Management, Università Telematica Unifortunato",
        "tag 1": "Laurea Magistrale",
        "tag 2": "Durata: 2 anni",
        "tag 3": "120 cfu",
        "tag 4": "Anno accademico 2023/2024",
        "tag 5": "LM-77",
        "Descrizione": "La Laurea Magistrale in Economia Aziendale con curriculum in Economia e Management dei Trasporti di Unifortunato è un corso online ideale per chi mira a specializzarsi nel settore dei trasporti. Con un focus su economia dei trasporti, il corso integra insegnamenti di strategic management, mercati finanziari e offre un'opzione per un insegnamento a scelta per personalizzare il percorso formativo. Attraverso un piano di studi ben strutturato, gli studenti acquisiscono competenze avanzate in direzione e gestione delle imprese di trasporto aereo e servizi aeroportuali. L'approccio teorico-pratico è rafforzato da opportunità di tirocinio nel secondo anno, permettendo ai laureati di Unifortunato di essere pronti ad affrontare le sfide del settore.",
        "Piano di studi 1": "Management pubblico (9 cfu), Diritto dei mercati finanziari (9 cfu), Diritto dell’economia (9 cfu), Informatica aziendale 2 INF/01 (8 cfu), Insegnamento a scelta (8 cfu), Business management (8 cfu), Analisi di bilancio e finanziaria (12 cfu), Business english (3 cfu)",
        "Piano di studi 2": "Econometria (6 cfu), Marketing dei trasporti (8 cfu), Management dei Trasporti (6 cfu), Strategic Management (6 cfu), Economia industriale e dei Trasporti (12 cfu), Laboratorio (6 cfu), Tirocini e Stage (6 cfu), Prova Finale (10 cfu)",
        "operatore 1": "Unifortunato",
        "operatore 2": "Comparacorsi",
        "operatore 3": "Altri operatori",
        "prezzo 1": "2.700€",
        "prezzo 2": "2.500€",
        "prezzo 3": "3.200€",
        "Domanda 1": "Come si svolgono le lezioni nel corso di Economia dei Trasporti di Unifortunato?",
        "Risposta 1": "Le lezioni si svolgono interamente online tramite la piattaforma telematica Unifortunato, consentendo agli studenti di accedere a contenuti didattici, video e forum di discussione in modo flessibile e interattivo.",
        "Domanda 2": "Quali sono i requisiti tecnici per partecipare al corso online in Economia e Management dei Trasporti?",
        "Risposta 2": "Avrai bisogno di un dispositivo con accesso a internet, software per visualizzare contenuti multimediali e partecipare a sessioni interattive, e competenze di base informatiche e telematiche per navigare efficacemente nella piattaforma di apprendimento.",
        "Domanda 3": "Come posso accedere ai materiali del corso di Economia dei Trasporti di Unifortunato?",
        "Risposta 3": "Una volta iscritto, potrai accedere a tutti i materiali didattici, lezioni registrate e risorse aggiuntive tramite la piattaforma online di Unifortunato, disponibile 24/7.",
        "Domanda 4": "Il corso in Economia e Management dei Trasporti offre opportunità di interazione con i docenti?",
        "Risposta 4": "Sì, Unifortunato promuove l'interazione tra studenti e docenti attraverso forum di discussione, sessioni di tutoraggio virtuale e attività collaborative online.",
        "Domanda 5": "Ci sono prerequisiti linguistici per iscriversi al corso di Economia dei Trasporti?",
        "Risposta 5": "Mentre una buona conoscenza dell'italiano è essenziale, la padronanza di una lingua straniera può essere vantaggiosa. Controlla i requisiti specifici di Unifortunato per informazioni dettagliate.",
        "Sbocchi": [
            "Manager di imprese di trasporto aereo",
            "Analista di strategie per servizi aeroportuali",
            "Consulente per la logistica e la supply chain",
            "Esperto in pianificazione e gestione del traffico aereo",
            "Responsabile operativo in aziende di trasporti pubblici",
            "Specialista in sicurezza e regolamentazione dei trasporti",
            "Coordinatore di progetti per l'infrastruttura dei trasporti",
            "Analista di mercato nel settore dei trasporti",
            "Responsabile della customer experience in compagnie di trasporto",
            "Imprenditore nel settore dei trasporti e della mobilità sostenibile."
        ]
    },
    {
        "nome": "Management e Consulenza Aziendale",
        "Area": "Economia",
        "H1": "Corso di Laurea in Consulenza Aziendale e Management, Università Telematica San Raffaele",
        "tag 1": "Laurea Magistrale",
        "tag 2": "Durata: 2 anni",
        "tag 3": "120 cfu",
        "tag 4": "Anno accademico 2023/2024",
        "tag 5": "LM-77",
        "Descrizione": "Il Corso di Laurea Magistrale in Management e consulenza aziendale dell'Università Telematica San Raffaele, offerto completamente online, è progettato per fornire agli studenti una formazione approfondita sulle dinamiche operative e gestionali nel mondo dell'economia e management. Specializzandosi in consulenza aziendale, il percorso formativo copre aree disciplinari quali economia aziendale, diritto commerciale, e analisi finanziaria, integrando anche l'insegnamento di materie come il diritto del lavoro e la gestione dei rischi finanziari. Con un forte focus su economia e management, il corso mira a sviluppare competenze avanzate per analizzare e influenzare le traiettorie di sviluppo dei mercati globali.",
        "Piano di studi 1": "Programmazione, Bilanci e Controllo delle Imprese (6 cfu), Management Strategico, imprenditorialità e Organizzazione di Impresa (12 cfu), Responsabilità sociale delle Imprese (6 cfu), Analisi dei Mercati Finanziari (6 cfu), Cyber security and Data Protection Regulation (12 cfu), Business English (6 cfu)",
        "Piano di studi 2": "Economia dell'Innovazione e dei Settori Produttivi (6 cfu), Metodologie quantitative per l'analisi d'Impresa (6 cfu), Legislazione d'Impresa e Diritto dell'Economia (12 cfu), Data Management (8 cfu), 3 Esami a scelta dello studente: Finanza aziendale (4 cfu), Diritto delle persone e della privacy (4 cfu), Disciplina del mercato mobiliare (4 cfu), Diritto del Fintech (4 cfu), Diritto della Finanza di Impresa (4 cfu), Tirocinio (16 cfu), Prova Finale (12 cfu)",
        "operatore 1": "San Raffaele",
        "operatore 2": "Comparacorsi",
        "operatore 3": "Altri operatori",
        "prezzo 1": "3.400€",
        "prezzo 2": "1.700€",
        "prezzo 3": "3.800€",
        "Domanda 1": "Come si svolgono le lezioni nel corso di Management e consulenza aziendale di San Raffaele?",
        "Risposta 1": "Le lezioni si svolgono completamente online attraverso la piattaforma telematica dell'Università San Raffaele, offrendo flessibilità e accesso a materiali didattici, lezioni video e forum di discussione in qualsiasi momento.",
        "Domanda 2": "Quali sono i requisiti tecnici per frequentare il corso online in consulenza aziendale?",
        "Risposta 2": "Per partecipare al corso di consulenza aziendale, è necessario disporre di un dispositivo con accesso a internet, software per visualizzare contenuti multimediali e competenze di base informatiche e telematiche.",
        "Domanda 3": "Come posso accedere ai materiali del corso di consulenza aziendale di San Raffaele?",
        "Risposta 3": "Una volta iscritto, potrai accedere a tutti i materiali didattici, lezioni registrate e risorse aggiuntive tramite la piattaforma online di San Raffaele, disponibile 24/7.",
        "Domanda 4": "Il corso in Management e consulenza aziendale offre tutoraggio personalizzato?",
        "Risposta 4": "Sì, l'Università San Raffaele offre tutoraggio personalizzato per supportare gli studenti nel loro percorso formativo, rispondendo a dubbi e fornendo guida accademica.",
        "Domanda 5": "È possibile interagire con altri studenti nel corso di Management e consulenza aziendale?",
        "Risposta 5": "Assolutamente, gli studenti possono interagire tra loro e con i docenti attraverso forum di discussione e gruppi di studio online, promuovendo così un ambiente di apprendimento collaborativo.",
        "Sbocchi": [
            "Consulente aziendale",
            "Analista di business",
            "Manager del cambiamento organizzativo",
            "Esperto in strategie di sviluppo aziendale",
            "Direttore delle operazioni",
            "Specialista in gestione del rischio",
            "Responsabile della pianificazione aziendale",
            "Consulente per l'ottimizzazione dei processi",
            "Analista di mercato",
            "Manager delle risorse umane."
        ]
    },
    {
        "nome": "Management e Consulenza Aziendale (Banking and Finance)",
        "Area": "Economia",
        "H1": "Corso di Laurea in Banking and Finance, Università Telematica San Raffaele",
        "tag 1": "Laurea Magistrale",
        "tag 2": "Durata: 2 anni",
        "tag 3": "120 cfu",
        "tag 4": "Anno accademico 2023/2024",
        "tag 5": "LM-77",
        "Descrizione": "Il Corso di Laurea Magistrale Banking and Finance, curriculum del corso Management e Consulenza Aziendale, dell'Università Telematica San Raffaele, è focalizzato sulla preparazione di specialisti nel settore bancario e finanziario. Il curriculum, afferente alla classe di laurea LM-77, è progettato per impartire una formazione approfondita in banking and finance, economia aziendale e strategic management, con un forte focus sulle dinamiche operative e gestionali del mondo bancario e finanziario. Gli studenti del corso avranno l'opportunità di approfondire la loro conoscenza e comprensione del settore attraverso un piano di studi strutturato e multidisciplinare, preparandosi a diventare professionisti qualificati in uno dei settori più dinamici e competitivi dell'economia globale.",
        "Piano di studi 1": "Bilanci degli operatori del mercato bancario e finanziario (12 cfu), Management and Finance (6 cfu), Organizzazione degli intermediari finanziari (6 cfu), Econometria finanziaria e finanza empirica (6 cfu), Analisi dei Mercati Finanziari (6 cfu), Tecnologia Blockchain e diritto del Fintech (12 cfu), Financial English (6 cfu)",
        "Piano di studi 2": "Finanza quantitativa e derivati (6 cfu), Diritto Bancario e Finanziario (6 cfu), Diritto della Finanza d'impresa (6 cfu), Cyber Security e Operatori Bancari e Finanziari (8 cfu), Tre Esami a scelta dello studente: Finanza aziendale (4 cfu), Disciplina del mercato mobiliare (4 cfu), Diritto delle assicurazioni (4 cfu), ESG: Legal and Finance Essentials (4 cfu), Governance bancaria (4 cfu), Tirocinio (16 cfu), Prova Finale (12 cfu)",
        "operatore 1": "San Raffaele",
        "operatore 2": "Comparacorsi",
        "operatore 3": "Altri operatori",
        "prezzo 1": "3.400€",
        "prezzo 2": "1.700€",
        "prezzo 3": "3.800€",
        "Domanda 1": "Come si svolgono le lezioni nel corso di Banking and Finance di San Raffaele?",
        "Risposta 1": "Le lezioni del corso in Banking and Finance si svolgono interamente online attraverso la piattaforma telematica dell'Università Telematica San Raffaele, offrendo flessibilità e accessibilità ai materiali didattici e ai forum di discussione.",
        "Domanda 2": "Quali sono i requisiti tecnici per partecipare al corso online in Banking and Finance?",
        "Risposta 2": "Per partecipare, avrai bisogno di un dispositivo con accesso a internet, software per visualizzare contenuti multimediali e competenze di base informatiche e telematiche per navigare efficacemente nella piattaforma di apprendimento.",
        "Domanda 3": "Come posso accedere ai materiali del corso di Banking and Finance di San Raffaele?",
        "Risposta 3": "Una volta iscritto, potrai accedere a tutti i materiali didattici, lezioni registrate e risorse aggiuntive tramite la piattaforma online di San Raffaele, disponibile 24/7.",
        "Domanda 4": "Il corso in Banking and Finance offre tutoraggio personalizzato?",
        "Risposta 4": "Sì, l'Università San Raffaele offre tutoraggio personalizzato per supportare gli studenti nel loro percorso formativo, rispondendo a dubbi e fornendo guida accademica.",
        "Domanda 5": "È possibile interagire con altri studenti nel corso di Banking and Finance?",
        "Risposta 5": "Assolutamente, gli studenti possono interagire tra loro e con i docenti attraverso forum di discussione e gruppi di studio online, promuovendo così un ambiente di apprendimento collaborativo.",
        "Sbocchi": [
          "Analista finanziario in banche e istituti di credito",
          "Consulente per investimenti e gestione patrimoniale",
          "Esperto in rischio di credito e gestione del rischio",
          "Manager di prodotti bancari e finanziari",
          "Specialista in compliance e regolamentazione finanziaria",
          "Analista di mercato per strumenti finanziari",
          "Responsabile finanziario in aziende e corporazioni",
          "Consulente per la finanza d'impresa",
          "Operatore in borsa e mercati finanziari",
          "Esperto in fintech e innovazione finanziaria."
        ]
    },
    {
        "nome": "Management e Consulenza Aziendale (Economia e Management della Sanità e dell’Innovazione Tecnologica)",
        "Area": "Economia",
        "H1": "Corso di Laurea in Management della Sanità e dell'Innovazione Tecnologica, Università Digitale San Raffaele",
        "tag 1": "Laurea Magistrale",
        "tag 2": "Durata: 2 anni",
        "tag 3": "120 cfu",
        "tag 4": "Anno accademico 2023/2024",
        "tag 5": "LM-77",
        "Descrizione": "Il Corso di Laurea Magistrale in Economia e Management della Sanità e dell'Innovazione Tecnologica, offerto dall'Università Telematica San Raffaele, è un percorso formativo avanzato che si focalizza sul management della sanità. Il curriculum, parte della laurea magistrale in Management e Economia Aziendale, è progettato per fornire agli studenti competenze specialistiche per gestire le dinamiche operative e strategiche nel settore sanitario. Il corso, della durata di 2 anni, si svolge completamente online, offrendo flessibilità e un approccio personalizzato allo studio. Con una solida base in economia aziendale e management, gli studenti esploreranno temi specifici come E-health e l'impiego delle ICT nella gestione della salute. Il percorso di studi è arricchito da esami a scelta, tirocini e attività pratiche, preparando i laureati a una carriera nell'ambito della consulenza e direzione aziendale sanitaria e tecnologica.",
        "Piano di studi 1": "Programmazione, Bilanci e Controllo delle Aziende Sanitarie (12 cfu), Economia e Gestione dell'Innovazione in Sanità  (6 cfu), Modelli organizzativi e Gestione delle risorse umane  (6 cfu), Economia Sanitaria  (6 cfu), E-health: il ruolo delle ICT nella gestione del sistema salute (12 cfu), Business and technical English  (6 cfu)",
        "Piano di studi 2": "Economia del Sistema sanitario Pubblico  (6 cfu), Statistica economica della Sanità Pubblica  (6 cfu), Diritto commerciale, Codice degli appalti e Protezione intellettuale (12 cfu), Data Management and Internet of Healts Things (8 cfu), Tre Esami a scelta dello studente: Diritto Sanitario e Responsabilità degli operatori sanitari (4 cfu), Intelligenza Artificiale e Salute Dell’Uomo (4 cfu), Diritto pubblico progredito della Sanità (4 cfu), Finanza aziendale (4 cfu), Tirocinio (16 cfu), Prova Finale (12 cfu)",
        "operatore 1": "San Raffaele",
        "operatore 2": "Comparacorsi",
        "operatore 3": "Altri operatori",
        "prezzo 1": "3.400€",
        "prezzo 2": "1.700€",
        "prezzo 3": "3.800€",
        "Domanda 1": "Come si svolgono le lezioni nel corso di Economia e Management della Sanità e dell’Innovazione Tecnologica di San Raffaele?",
        "Risposta 1": "Le lezioni si svolgono interamente online attraverso la piattaforma telematica di San Raffaele, offrendo flessibilità e accesso a contenuti didattici, lezioni video e forum di discussione.",
        "Domanda 2": "Quali sono i requisiti tecnici per partecipare al corso online in management della sanità?",
        "Risposta 2": "Per partecipare, è necessario disporre di un dispositivo con accesso a internet, software per visualizzare contenuti multimediali e competenze di base informatiche e telematiche.",
        "Domanda 3": "Come posso accedere ai materiali del corso di management della sanità di San Raffaele?",
        "Risposta 3": "Una volta iscritto, potrai accedere a tutti i materiali didattici, lezioni registrate e risorse aggiuntive tramite la piattaforma online di San Raffaele, disponibile 24/7.",
        "Domanda 4": "Il corso in Economia e Management della Sanità offre tutoraggio personalizzato?",
        "Risposta 4": "Sì, l'Università San Raffaele offre tutoraggio personalizzato per supportare gli studenti nel loro percorso formativo, rispondendo a dubbi e fornendo guida accademica.",
        "Domanda 5": "È possibile interagire con altri studenti nel corso di Economia e Management della Sanità e dell’Innovazione Tecnologica?",
        "Risposta 5": "Assolutamente, gli studenti possono interagire tra loro e con i docenti attraverso forum di discussione e gruppi di studio online, promuovendo così un ambiente di apprendimento collaborativo.",
        "Sbocchi": [
          "Manager di strutture sanitarie",
          "Consulente per l'innovazione tecnologica in ambito sanitario",
          "Analista per politiche sanitarie",
          "Responsabile di progetti di e-health",
          "Esperto in gestione del cambiamento per enti sanitari",
          "Coordinatore di programmi di sanità pubblica",
          "Specialista in gestione dati sanitari e informatica medica",
          "Direttore di servizi di assistenza sanitaria",
          "Responsabile per la qualità e l'accreditamento in ambito sanitario",
          "Consulente per l'efficienza operativa in ospedali e cliniche"
        ]
    },
    {
        "nome": "Management e Consulenza Aziendale (Economia e Management delle Organizzazioni Sportive)",
        "Area": "Economia",
        "H1": "Corso di Laurea in Management dello Sport, Università Telematica San Raffaele",
        "tag 1": "Laurea Magistrale",
        "tag 2": "Durata: 2 anni",
        "tag 3": "120 cfu",
        "tag 4": "Anno accademico 2023/2024",
        "tag 5": "LM-77",
        "Descrizione": "Il Corso di Laurea Magistrale in Management dello Sport e Economia, offerto dall'Università Digitale San Raffaele, fa parte del più ampio curriculum di Management e Economia Aziendale. Questo corso online, specializzato in management dello sport, è progettato per preparare gli studenti a comprendere e gestire le complesse dinamiche dell'industria sportiva. Attraverso un percorso formativo che integra economia aziendale, economia e management delle organizzazioni e delle attività sportive, gli studenti acquisiscono competenze chiave per analizzare e influenzare positivamente il settore sportivo. Con questa laurea magistrale, gli studenti di San Raffaele possono aspettarsi un approccio multidisciplinare che combina teoria e pratica, facilitato da un piano di studi completo e un'esperienza di apprendimento flessibile e accessibile.",
        "Piano di studi 1": "Business and Technical English (6 cfu), Accounting and Performance Measurement nello Sport (12 cfu), Economia e management delle imprese sportive (6 cfu), Organizzazione e leadership dei gruppi sportivi (6 cfu), Politiche pubbliche dello sport (6 cfu), Sport Intelligence e Diritto avanzato dello Sport (12 cfu)",
        "Piano di studi 2": "Economia dello sport e degli eventi (6 cfu), Matematica per il management e per le decisioni (6 cfu), Sport Business Law (12 cfu), Sport Communication & New Media (8 cfu), Esami a scelta dello studente: Management della sostenibilità nell'industria sportiva (4 cfu), Economia dell'Innovazione e dei Settori Produttivi (4 cfu), Finanza aziendale (4 cfu), Tirocinio (16 cfu), Prova Finale (12 cfu)",
        "operatore 1": "San Raffaele",
        "operatore 2": "Comparacorsi",
        "operatore 3": "Altri operatori",
        "prezzo 1": "3.400€",
        "prezzo 2": "1.700€",
        "prezzo 3": "3.800€",
        "Domanda 1": "Come si svolgono le lezioni nel corso di Management dello Sport e Economia di San Raffaele?",
        "Risposta 1": "Le lezioni si svolgono interamente online attraverso la piattaforma telematica dell'Università Digitale San Raffaele, offrendo flessibilità e accesso a contenuti didattici, video e forum di discussione.",
        "Domanda 2": "Quali sono i requisiti tecnici per partecipare al corso online in management dello sport?",
        "Risposta 2": "Avrai bisogno di un dispositivo con accesso a internet, software per visualizzare contenuti multimediali e competenze di base informatiche e telematiche per navigare efficacemente nella piattaforma di apprendimento.",
        "Domanda 3": "Come posso accedere ai materiali del corso di Management dello Sport e Economia di San Raffaele?",
        "Risposta 3": "Una volta iscritto, potrai accedere a tutti i materiali didattici, lezioni registrate e risorse aggiuntive tramite la piattaforma online di San Raffaele, disponibile 24/7.",
        "Domanda 4": "Il corso in Management dello Sport offre tutoraggio personalizzato?",
        "Risposta 4": "Sì, l'Università Digitale San Raffaele offre tutoraggio personalizzato per supportare gli studenti nel loro percorso formativo, rispondendo a dubbi e fornendo guida accademica.",
        "Domanda 5": "È possibile interagire con altri studenti nel corso di Management dello Sport e Economia?",
        "Risposta 5": "Assolutamente, gli studenti possono interagire tra loro e con i docenti attraverso forum di discussione e gruppi di studio online, promuovendo così un ambiente di apprendimento collaborativo.",
        "Sbocchi": [
          "Direttore di organizzazioni sportive",
          "Manager di eventi sportivi",
          "Consulente per lo sviluppo di strategie nel settore sportivo",
          "Analista finanziario per squadre e associazioni sportive",
          "Responsabile marketing per brand sportivi",
          "Coordinatore di programmi di sport e benessere aziendale",
          "Agente e rappresentante di atleti professionisti",
          "Gestore di impianti e strutture sportive",
          "Esperto in diritti mediatici e sponsorizzazioni sportive",
          "Responsabile di iniziative sportive comunitarie e sociali."
        ]
    },
    {
        "nome": "Economia e Management per l’Innovazione e la Sostenibilità",
        "Area": "Economia",
        "H1": "Corso di Laurea in Economia e Management per l’Innovazione e la Sostenibilità, Università Telematica Unimarconi",
        "tag 1": "Laurea Magistrale",
        "tag 2": "Durata: 2 anni",
        "tag 3": "120 cfu",
        "tag 4": "Anno accademico 2023/2024",
        "tag 5": "LM-77",
        "Descrizione": "Il Corso di Laurea Magistrale in Economia e Management per l’Innovazione e la Sostenibilità offerto da Unimarconi si rivolge a studenti desiderosi di operare in settori competitivi e all'avanguardia. Questa laurea in economia, totalmente online, copre tematiche cruciali come sostenibilità ambientale, responsabilità sociale e innovazione tecnologica, preparando i laureati a gestire con successo le sfide del mondo economico moderno. Gli esami e il piano di studi sono progettati per promuovere un approccio critico e innovativo, fornendo le competenze necessarie per affrontare i rapidi cambiamenti dei mercati globali. Attraverso il percorso formativo di Unimarconi, gli studenti svilupperanno una visione manageriale integrata e sostenibile, fondamentale per le organizzazioni che mirano all'eccellenza nel rispetto dei principi etici e ambientali.",
        "Piano di studi 1": "Politica Economica Europea (6 cfu), Economia della Sostenibilità e Accountability (12 cfu), Management dell’innovazione e della Sostenibilità (12 cfu), 6 CFU a scelta tra: Politiche per l’ambiente  (6 cfu), Economia e Politica dello Sviluppo  (6 cfu), Marketing Internazionale e Digitale (12 cfu), 6 CFU a scelta tra: Sistemi di Elaborazione delle Informazioni  (6 cfu), Blockchain  (6 cfu), Laboratorio: Business English  (6 cfu)",
        "Piano di studi 2": "6 CFU a scelta tra: Diritto Fallimentare  (6 cfu), Mercati, Regolamentazione e Concorrenza  (6 cfu), 6 CFU a scelta tra: Big Data e basi dati aziendali  (6 cfu), Statistica Economica  (6 cfu), Organizzazione e Gestione Delle Risorse Umane  (6 cfu), 12 CFU a scelta tra: Corporate Finance e Risk Management (6 cfu), Economia delle Istituzioni Finanziarie Internazionali (6 cfu), Innovazione Finanziaria e Fintech (6 cfu), Knowledge Management (6 cfu), * per i crediti ODCEC almeno 6 a scelta tra Controllo di Gestione (6 cfu), Revisione Aziendale (6 cfu), Scelta libera* (6+6 cfu), Laboratori/ tirocini esterni/case study (6 cfu), Tesi di laurea (15 cfu)",
        "operatore 1": "Unimarconi",
        "operatore 2": "Comparacorsi",
        "operatore 3": "Altri operatori",
        "prezzo 1": "2.760€",
        "prezzo 2": "1.500€",
        "prezzo 3": "3.100€",
        "Domanda 1": "Come si svolgono le lezioni nel corso di Economia e Management per l’Innovazione e la Sostenibilità di Unimarconi?",
        "Risposta 1": "Le lezioni si tengono online attraverso la piattaforma telematica Unimarconi, offrendo materiali didattici, video e forum per un apprendimento flessibile e interattivo.",
        "Domanda 2": "Quali sono i requisiti tecnici per partecipare al corso online in laurea in economia?",
        "Risposta 2": "È necessario avere un dispositivo con accesso internet stabile, software per contenuti multimediali e competenze informatiche base per navigare efficacemente nella piattaforma di apprendimento.",
        "Domanda 3": "Come posso personalizzare il mio percorso formativo nel corso di laurea magistrale in economia?",
        "Risposta 3": "Il corso offre esami a scelta che permettono di personalizzare il percorso in base agli interessi individuali, consentendo una maggiore specializzazione in aree specifiche di economia, innovazione o sostenibilità.",
        "Domanda 4": "Sono previste interazioni tra studenti e docenti nel corso di Economia e Management per l’Innovazione e la Sostenibilità?",
        "Risposta 4": "Sì, ci sono numerose opportunità di interazione attraverso forum di discussione, sessioni di tutoraggio virtuale e attività collaborative per promuovere lo scambio di idee e supporto accademico.",
        "Domanda 5": "Il corso di Economia e Management per l’Innovazione e la Sostenibilità offre supporto per lo studio autonomo?",
        "Risposta 5": "Unimarconi fornise risorse didattiche, materiali supplementari e tutoraggio per assistere gli studenti nel loro studio autonomo, garantendo un'esperienza di apprendimento completa e supportata.",
        "Sbocchi": [
        "Consulente in sostenibilità aziendale",
        "Manager di progetti di innovazione",
        "Analista di politiche ambientali",
        "Esperto in responsabilità sociale d'impresa",
        "Direttore di sviluppo sostenibile",
        "Consulente in gestione del cambiamento e innovazione",
        "Esperto in economia circolare",
        "Coordinatore di iniziative per l'energia rinnovabile",
        "Analista di strategie per la sostenibilità",
        "Responsabile di programmi di sostenibilità corporativa."
        ]
    },
    {
        "nome": "Economia e Management Aziendale",
        "Area": "Economia",
        "H1": "Corso di Laurea in Management Aziendale ed Economia, Università Telematica Unitelma",
        "tag 1": "Laurea Magistrale",
        "tag 2": "Durata: 2 anni",
        "tag 3": "120 cfu",
        "tag 4": "Anno accademico 2023/2024",
        "tag 5": "LM-77",
        "Descrizione": "Il Corso di Laurea in Management Aziendale ed Economia presso l'Università Telematica Unitelma Sapienza è un programma di studi interamente online che offre una formazione avanzata nel campo della gestione aziendale e dell'economia. Questa laurea magistrale è ideale per chi mira a sviluppare competenze critiche e analitiche in ambiti chiave come l'innovazione e la pianificazione strategica. Il corso copre una vasta gamma di tematiche, dalla statistica matematica alla gestione giuridica, preparando gli studenti ad affrontare le complesse sfide del mondo aziendale moderno. Unitelma si impegna a fornire un apprendimento interattivo e dinamico, con una piattaforma online accessibile e materiali didattici aggiornati, facilitando così un percorso educativo di qualità per aspiranti manager e imprenditori.",
        "Piano di studi 1": "Economia Pubblica (6 cfu), Mercati Finanziari Ed Investimenti (12 cfu), Politica Economica Europea (12 cfu), Diritto Delle Società (6 cfu), A Scelta Tra: Gestione Delle Informazioni Aziendali (6 cfu), Diritto Dell'economia - Corso Avanzato (6 cfu), A Scelta Tra: Diritto Del Lavoro (6 cfu), Diritto Tributario (6 cfu), A Scelta Tra: Marketing Internazionale (12 cfu), Programmazione E Controllo (12 cfu)",
        "Piano di studi 2": "Politica Del Credito E Finanziaria (6 cfu), Statistica Per Le Imprese (12 cfu), Diritto Internazionale Dell'ambiente (6 cfu), Ulteriori Conoscenze Linguistiche - Abilità Informatiche E Telematiche - Tirocini Formativi  (3 cfu), A Scelta Tra: Programmazione E Controllo Delle Amministrazioni Pubbliche (6 cfu), Gestione Del Portafoglio (6 cfu), Prova Finale (15 cfu)",
        "operatore 1": "Unitelma",
        "operatore 2": "Comparacorsi",
        "operatore 3": "Altri operatori",
        "prezzo 1": "2.300€",
        "prezzo 2": "1.800€",
        "prezzo 3": "2.700€",
        "Domanda 1": "Come posso accedere alle lezioni del corso di laurea in management online di Unitelma?",
        "Risposta 1": "Le lezioni sono disponibili sulla piattaforma telematica di Unitelma. Dopo l'iscrizione, riceverai le credenziali per accedere e potrai visualizzare le lezioni, materiali e partecipare a forum di discussione in qualsiasi momento.",
        "Domanda 2": "È necessaria una conoscenza pregressa per iscriversi al corso di laurea magistrale in economia?",
        "Risposta 2": "È consigliata una base di conoscenze in economia e management, ma il corso è strutturato per accompagnare tutti gli studenti, anche quelli con una preparazione iniziale diversa, attraverso un percorso formativo completo.",
        "Domanda 3": "Ci sono incontri in presenza per il corso di laurea online in management aziendale?",
        "Risposta 3": "Il corso è interamente online, ma potrebbero esserci delle occasioni di incontri virtuali o in presenza per attività specifiche. Verifica con Unitelma per gli eventi programmati.",
        "Domanda 4": "Posso seguire il corso di laurea online in economia mentre lavoro?",
        "Risposta 4": "Sì, il corso online è progettato per offrire flessibilità e permettere agli studenti di conciliare lo studio con altri impegni, come il lavoro.",
        "Domanda 5": "Come posso verificare se il mio dispositivo è compatibile con la piattaforma di Unitelma?",
        "Risposta 5": "La piattaforma di Unitelma è generalmente accessibile da qualsiasi dispositivo con connessione internet. Per specifiche tecniche, si consiglia di contattare il supporto tecnico dell'università per verificare compatibilità e requisiti.",
        "Sbocchi": [
          "Consulente aziendale per strategie di sviluppo e innovazione",
          "Analista finanziario in aziende e istituti bancari",
          "Responsabile della pianificazione e controllo di gestione",
          "Esperto in valutazione di investimenti e progetti di sostenibilità",
          "Manager delle risorse umane",
          "Specialista in marketing e comunicazione d'impresa",
          "Analista di mercato e ricercatore economico",
          "Imprenditore in settori innovativi e sostenibili"
        ]
    },
    {
        "nome": "Gestione d’impresa e tecnologie digitali",
        "Area": "Economia",
        "H1": "Corso di Laurea in Tecnologie Digitali e Gestione d'Impresa, Università Telematica Uninettuno",
        "tag 1": "Laurea Magistrale",
        "tag 2": "Durata: 2 anni",
        "tag 3": "120 cfu",
        "tag 4": "Anno accademico 2023/2024",
        "tag 5": "LM-77",
        "Descrizione": "Il Corso di Laurea in Tecnologie Digitali e Gestione d'Impresa di Uninettuno, classe LM77, è un programma avanzato che combina competenze economico-aziendali con una profonda comprensione delle tecnologie digitali. Questa laurea magistrale è progettata per formare professionisti capaci di utilizzare le tecnologie digitali come leva per accrescere la competitività aziendale. Attraverso un piano di studi diversificato, che include materie come marketing, e-commerce, diritto bancario e finanziario, e tecnologie digitali per l'impresa, gli studenti acquisiranno le competenze manageriali necessarie per eccellere in aziende dove l'innovazione tecnologica è cruciale. Con attività a scelta, tirocini e una prova finale, il corso si impegna a fornire un'educazione pratica e applicata, preparando i laureati a diventare leader nel mondo delle tecnologie digitali e della gestione aziendale.",
        "Piano di studi 1": "Economia dell'innovazione (9 cfu), Tecnologie digitali per l'impresa (6 cfu), Big Data e basi di dati aziendali (9 cfu), International financial accounting (9 cfu), IT per l'organizzazione a rete (9 cfu), Marketing, e-business, e-commerce (9 cfu), Diritto bancario e finanziario (9 cfu)",
        "Piano di studi 2": "Economia e politica della globalizzazione (9 cfu), Management pubblico ed e-government (9 cfu), Management dell'innovazione d'impresa (6 cfu), Tecnologie web avanzate (6 cfu), Altre attività (abilità informatiche, tirocini, ulteriori lingue, ecc.) (9 cfu), Prova Finale (18 cfu)",
        "operatore 1": "Uninettuno",
        "operatore 2": "Comparacorsi",
        "operatore 3": "Altri operatori",
        "prezzo 1": "2.200€",
        "prezzo 2": "2.100€",
        "prezzo 3": "2.400€",
        "Domanda 1": "Come si svolgono le lezioni nel corso online di tecnologia digitale e gestione d'impresa di Uninettuno?",
        "Risposta 1": "Le lezioni sono completamente online e possono essere seguite in qualsiasi momento tramite la piattaforma di apprendimento telematica di Uninettuno. Offre video lezioni, materiali di studio interattivi e forum per la discussione.",
        "Domanda 2": "Quali sono le modalità di esame per il corso di laurea in management di Uninettuno?",
        "Risposta 2": "Gli esami si svolgono online, generalmente sotto forma di test, progetti o presentazioni. Alcuni esami possono richiedere la presenza in centri esame affiliati. Controlla con l'università per dettagli specifici.",
        "Domanda 3": "È possibile fare tirocini durante il corso di laurea online in economia aziendale di Uninettuno?",
        "Risposta 3": "Sì, il corso incoraggia tirocini e stage per offrire esperienza pratica. Gli studenti possono organizzare tirocini presso aziende partner o altre di loro scelta, con l'approvazione dell'università.",
        "Domanda 4": "Come posso accedere ai materiali di studio per il corso di laurea online in gestione d'impresa di Uninettuno?",
        "Risposta 4": "Dopo l'iscrizione, avrai accesso alla piattaforma online di Uninettuno dove potrai trovare tutti i materiali di studio, comprese le registrazioni delle lezioni, le letture assegnate e i compiti.",
        "Domanda 5": "Posso interagire con i docenti nel corso di laurea online in economia e management di Uninettuno?",
        "Risposta 5": "Sì, gli studenti possono interagire con i docenti tramite e-mail, forum di discussione e, in alcuni casi, sessioni di videoconferenza, per discutere il materiale del corso, chiarire dubbi e ricevere supporto nello studio.",
        "Sbocchi": [
          "Analista di business nel settore delle tecnologie digitali",
          "Consulente aziendale per la trasformazione digitale",
          "Manager di progetto per l'innovazione tecnologica",
          "Specialista in strategie di e-commerce e marketing digitale",
          "Responsabile del controllo di gestione in aziende tecnologiche",
          "Esperto in sistemi informativi e gestione dati",
          "Coordinatore per lo sviluppo sostenibile e la responsabilità sociale d'impresa",
          "Imprenditore nel settore delle startup tecnologiche",
          "Specialista in cybersecurity e protezione dei dati aziendali",
          "Responsabile della pianificazione e implementazione di sistemi ERP (Enterprise Resource Planning)"
        ]
    },
    {
        "nome": "Gestione d’impresa e tecnologie digitali (Digital Marketing for Business)",
        "Area": "Economia",
        "H1": "Corso di Laurea in Digital Marketing for Business, Università Telematica Uninettuno",
        "tag 1": "Laurea Magistrale",
        "tag 2": "Durata: 2 anni",
        "tag 3": "120 cfu",
        "tag 4": "Anno accademico 2023/2024",
        "tag 5": "LM-77",
        "Descrizione": "Il corso di laurea magistrale in Digital Marketing for Business di Uninettuno, classe LM-77, mira a formare professionisti nel settore del marketing digitale. Gli studenti acquisiranno competenze avanzate in strategie di business, comunicazione, economia aziendale e gestione dell'impresa, con un focus particolare sulle tecnologie digitali. Studieranno discipline come e-business, e-commerce, marketing avanzato, digital marketing, social media management, e data science for business strategy. Il piano di studi, articolato in due anni, comprende esami specifici, tirocini e una prova finale. I laureati potranno operare come consulenti in digital marketing, manager del marketing digitale, esperti in strategie di business online, e in ruoli simili in aziende che valorizzano l'innovazione digitale. L'accesso al corso è aperto a chi possiede una laurea triennale, con una verifica iniziale delle competenze. Le iscrizioni sono aperte tutto l'anno, e il corso è erogato in modalità online.",
        "Piano di studi 1": "Experience Design (Nuova Edizione) (9 cfu), Marketing, e-business, e-commerce (9 cfu), Economics of digital goods and online markets (6 cfu), Digital marketing avanzato  (9 cfu), Marketing statistics and metrics  (9 cfu), Psicologia della comunicazione e neuromarketing  (9 cfu), Diritto delle comunicazioni nel contesto digitale (nuova edizione)  (9 cfu)",
        "Piano di studi 2": "Regolamentazione e concorrenza delle telecomunicazioni e dei mercati digitali  (9 cfu), Data science for business strategy (6 cfu), Social media marketing and management  (9 cfu), Tecnologie web avanzate (6 cfu), Altre attività (abilità informatiche, tirocini, ulteriori lingue, ecc.) (9 cfu), Prova Finale (18 cfu)",
        "operatore 1": "Uninettuno",
        "operatore 2": "Comparacorsi",
        "operatore 3": "Altri operatori",
        "prezzo 1": "2.200€",
        "prezzo 2": "2.100€",
        "prezzo 3": "2.400€",
        "Domanda 1": "Quali sono i requisiti di ammissione al Corso di Laurea in Digital Marketing for Business presso Uninettuno?",
        "Risposta 1": "Per essere ammessi al corso, è necessario possedere una laurea triennale e dimostrare competenze in discipline manageriali, economiche, giuridiche, e informatiche, insieme alla conoscenza della lingua inglese.",
        "Domanda 2": "Quali sono gli obiettivi formativi del corso?",
        "Risposta 2": "Il corso mira a formare professionisti specializzati nel digital marketing per le imprese, dotati di competenze avanzate in strategie, comunicazione, economia aziendale e tecnologie digitali.",
        "Domanda 3": "Quali sono i principali temi affrontati nel curriculum di Digital Marketing for Business?",
        "Risposta 3": "Gli studenti studieranno argomenti come marketing digitale, e-commerce, statistica del marketing, regolamentazione dei mercati digitali e neuromarketing.",
        "Domanda 4": "Quali opportunità di carriera offre questa laurea?",
        "Risposta 4": "I laureati potranno lavorare come consulenti di digital marketing, manager del marketing digitale, specialisti in strategie di business online, e in ruoli simili.",
        "Domanda 5": "Come avviene l'accesso al Corso di Digital Marketing for Business?",
        "Risposta 5": "Le iscrizioni sono aperte tutto l'anno, e non è previsto un test d'ingresso. Gli studenti dovranno superare dei test online per la verifica delle competenze richieste.",
        "Sbocchi": [
          "Digital Marketing Manager",
          "Consulente di Marketing Digitale",
          "Esperto in Social Media Marketing",
          "Analista dei Dati di Marketing",
          "Specialista in Email Marketing",
          "Responsabile delle Campagne Pubblicitarie Online",
          "Content Manager Digitale",
          "Esperto in SEO (Ottimizzazione per i Motori di Ricerca)",
          "Web Analyst",
          "Responsabile dell'E-commerce"
        ]
    },
    {
        "nome": "Gestione d’impresa e tecnologie digitali (Green Economy e Gestione Sostenibile)",
        "Area": "Economia",
        "H1": "Corso di Laurea in Green Economy e Gestione Sostenibile, Università Telematica Uninettuno",
        "tag 1": "Laurea Magistrale",
        "tag 2": "Durata: 2 anni",
        "tag 3": "120 cfu",
        "tag 4": "Anno accademico 2023/2024",
        "tag 5": "LM-77",
        "Descrizione": "Il Corso di Laurea in Green Economy e Gestione Sostenibile presso l'Università Telematica Uninettuno è un percorso di laurea magistrale online della durata di 2 anni. Questo programma si concentra sulla promozione della sostenibilità e della green economy attraverso l'utilizzo delle tecnologie digitali. Gli studenti acquisiranno conoscenze approfondite in ambito ambientale ed economico, con un'enfasi particolare sulla gestione sostenibile delle risorse. Il corso di laurea offre una solida formazione teorica e pratica, con esami dedicati all'economia verde, all'energia sostenibile e alla gestione delle risorse naturali. Gli studenti avranno l'opportunità di approfondire il comportamento organizzativo, la mobilità sostenibile e le strategie per promuovere uno sviluppo sostenibile. I laureati saranno pronti per una varietà di opportunità lavorative nei settori legati alla sostenibilità, compresi ruoli come consulenti ambientali, specialisti in energie rinnovabili e manager della sostenibilità. L'accesso al corso è aperto a chi possiede una laurea triennale, con una verifica iniziale delle competenze. Le iscrizioni sono aperte tutto l'anno, e il corso è erogato in modalità online.",
        "Piano di studi 1": "Tecnologie digitali per l'impresa  (6 cfu), Big Data e basi di dati aziendali (9 cfu), Marketing, e-business, e-commerce (9 cfu), Diritto bancario e finanziario (9 cfu), Green economy, energia ed economia circolare (9 cfu), Green economy e organizational behaviour (9 cfu), Green finance (9 cfu)",
        "Piano di studi 2": "Management pubblico ed e-government (9 cfu), Management dell'innovazione d'impresa (6 cfu), Mobilità sostenibile, strategie e strumenti (9 cfu), Geografia, ecologia e sviluppo sostenibile (6 cfu), Altre attività (abilità informatiche, tirocini, ulteriori lingue, ecc.) (9 cfu), Prova Finale (18 cfu)",
        "operatore 1": "Uninettuno",
        "operatore 2": "Comparacorsi",
        "operatore 3": "Altri operatori",
        "prezzo 1": "2.200€",
        "prezzo 2": "2.100€",
        "prezzo 3": "2.400€",
        "Domanda 1": "Quali sono i principali obiettivi formativi del Corso di Laurea in Green Economy e Gestione Sostenibile?",
        "Risposta 1": "Gli obiettivi principali includono la formazione di specialisti nella gestione sostenibile delle risorse, la promozione della green economy e l'acquisizione di competenze nell'ambito ambientale ed economico.",
        "Domanda 2": "Quali sono i vantaggi di studiare questa laurea online presso Uninettuno?",
        "Risposta 2": "Gli studenti possono seguire le lezioni da casa, gestire il proprio tempo e accedere a risorse online, rendendo lo studio flessibile e accessibile.",
        "Domanda 3": "Quante ore di studio sono richieste settimanalmente per questo corso?",
        "Risposta 3": "Il numero di ore di studio settimanali può variare, ma è importante dedicare tempo sufficiente per approfondire le materie e completare gli esami.",
        "Domanda 4": "Ci sono esami particolarmente impegnativi all'interno di questo corso?",
        "Risposta 4": "Alcuni esami potrebbero richiedere uno studio più approfondito, ma la preparazione adeguata può portare al successo.",
        "Domanda 5": "Come è strutturata la prova finale di laurea per questo corso?",
        "Risposta 5": "La prova finale consiste in un elaborato concordato con un docente relatore, che approfondisce un tema studiato durante il corso e dimostra le competenze acquisite.",
        "Sbocchi": [
          "Consulente ambientale",
          "Responsabile della sostenibilità aziendale",
          "Analista finanziario sostenibile",
          "Project manager per progetti ecologici",
          "Esperto in energie rinnovabili",
          "Gestore di programmi di riciclaggio",
          "Responsabile della gestione delle risorse naturali",
          "Esperto in politiche ambientali",
          "Consulente per la riduzione dell'impronta ecologica",
          "Analista delle politiche pubbliche ambientali"
        ]
    },
    {
        "nome": "Scienze dell'Educazione",
        "Area": "Formazione",
        "H1": "Corso di Laurea in Scienze dell'educazione, Università Telematica Unifortunato",
        "tag 1": "Laurea Triennale",
        "tag 2": "Durata: 3 anni",
        "tag 3": "180 cfu",
        "tag 4": "Anno accademico 2023/2024",
        "tag 5": "L-19",
        "Descrizione": "La Laurea Triennale online in Scienze dell'educazione dell'Università Telematica Unifortunato è ideale per chi aspira a diventare un educatore professionista, lavorando con bambini, adolescenti, anziani e persone con deficit. Il corso integra conoscenze pedagogiche, psicologiche e sociologiche con competenze pratiche e tecnologiche, preparando gli studenti a diventare educatori e tecnici in ambiti sociali e di reinserimento. Con 180 Crediti Formativi, la facoltà di scienza dell'educazione, copre materie di base e specialistiche, oltre alla pratica digitale e alla conoscenza di una lingua straniera. Il laureato in Scienze dell'educazione avrà competenze non solo teoriche, ma anche pratiche, ottenute attraverso l'applicazione delle conoscenze a casi reali e l'uso di ambienti digitali per sviluppare competenze mediali e tecnologiche. L'accesso al corso è aperto a chi possiede una laurea triennale, con una verifica iniziale delle competenze. Le iscrizioni sono aperte tutto l'anno, e il corso è erogato in modalità online.",
        "Piano di studi 1": "Informatica (6 cfu), Psicologia Dell'educazione (6 cfu), Pedagogia Generale E Sociale (7 cfu), Laboratorio (2 cfu), Storia Della Pedagogia E Delle Istituzioni Educative (7 cfu), Laboratorio (2 cfu), Didattica Generale E Tecnologie Educative (6 cfu), Lingua Inglese (6 cfu), Psicologia Generale (8 cfu), Sociologia Dei Processi Culturali E Comunicativi (8 cfu)",
        "Piano di studi 2": "Storia E Critica Della Letteratura Per L'infanzia (6 cfu), Psicologia Del Lavoro E Delle Organizzazioni (6 cfu), Metodologia E Didattica Delle Attivita' Motorie (6 cfu), Pedagogia Sperimentale E Ricerca Educativa (6 cfu), Psicologia Dello Sviluppo E Dell'educazione (6 cfu), Laboratorio (2 cfu), Pedagogia Speciale E Didattica Dell'integrazione (6 cfu), Laboratorio (2 cfu), Neuropsichiatria Infantile (6 cfu), Valutazione Educativa E Assicurazione Della Qualita' (9 cfu)",
        "Piano di studi 3": "Educazione Grafico Pittorica (6 cfu), Psicologia Dinamica (10 cfu), Storia Della Filosofia (8 cfu), Istituzioni Di Diritto Pubblico (6 cfu), Laboratorio (2 cfu), Lingua Inglese 2 (6 cfu), Tirocini E Stage (10 cfu), Prova Finale (6 cfu)",
        "operatore 1": "Unifortunato",
        "operatore 2": "Comparacorsi",
        "operatore 3": "Altri operatori",
        "prezzo 1": "2.900€",
        "prezzo 2": "2.800€",
        "prezzo 3": "3.300€",
        "Domanda 1": "Che tipo di competenze tecnologiche svilupperò nel corso di Laurea Triennale in Scienze dell'educazione?",
        "Risposta 1": "Svilupperai competenze tecnologiche avanzate, apprendendo l'uso di strumenti digitali e piattaforme online per progettare e implementare programmi educativi efficaci e interattivi, cruciali nell'ambito delle scienze dell’educazione.",
        "Domanda 2": "Come viene gestita la pratica online nell'Università Telematica Unifortunato?",
        "Risposta 2": "La pratica online è integrata nel corso attraverso attività formative digitali, simulazioni e progetti reali, permettendoti di acquisire competenze pratiche nel campo educativo direttamente da casa.",
        "Domanda 3": "Qual è il livello di conoscenza della lingua straniera richiesto per la Laurea in Scienze dell'educazione?",
        "Risposta 3": "È richiesto un livello intermedio di una lingua straniera, essenziale per ampliare le tue prospettive educative e comunicative nel contesto globale delle scienze dell'educazione.",
        "Domanda 4": "Posso iscrivermi al corso di Scienze dell'educazione in qualsiasi periodo dell'anno?",
        "Risposta 4": "Sì, l'Università Telematica Unifortunato offre la flessibilità di iscrizioni aperte durante tutto l'anno per il corso di laurea online in Scienze dell'educazione, permettendoti di iniziare quando sei pronto.",
        "Domanda 5": "Come posso testare la mia preparazione prima di iniziare il corso di laurea in Scienze dell'educazione?",
        "Risposta 5": "L'Università offre un test di valutazione iniziale per misurare le tue competenze e preparazione, aiutandoti a identificare eventuali aree che richiedono ulteriore studio prima di iniziare il percorso formativo in Scienze dell'educazione.",
        "Sbocchi": [
          "Educatore per l'infanzia",
          "Educatore per adulti e anziani",
          "Assistente sociale in ambito educativo",
          "Tecnico della formazione professionale",
          "Coordinatore di progetti educativi",
          "Operatore in servizi di integrazione sociale",
          "Consulente pedagogico per istituzioni e aziende",
          "Operatore in centri di accoglienza per minori",
          "Educatore in ambito carcerario",
          "Mediatore culturale in contesti multietnici"
        ]
    },
    {
        "nome": "Scienze dell'Educazione e della Formazione",
        "Area": "Formazione",
        "H1": "Corso di Laurea in Educazione e Formazione, Università Digitale eCampus",
        "tag 1": "Laurea Triennale",
        "tag 2": "Durata: 3 anni",
        "tag 3": "180 cfu",
        "tag 4": "Anno accademico 2023/2024",
        "tag 5": "L-19",
        "Descrizione": "La Laurea Triennale in Educazione e Formazione presso l'Università telematica eCampus è un corso online ideale per chi desidera diventare educatore, specializzandosi in processi educativi e formazione umana. Il corso mira, per definizione, a formare professionisti nell'ambito della formazione umana e mira a fornire conoscenze approfondite e capacità operative per progettare e realizzare interventi educativi in contesti diversificati. Studiando educazione e formazione online, acquisirai competenze pedagogiche, psicologiche, sociologiche e tecnologiche essenziali per la professione, apprendendo appieno il significato del concetto di 'educazione'. Con un piano di studi completo, il corso garantisce una preparazione efficace per diventare un educatore qualificato, pronto a rispondere alle esigenze di un ambiente multietnico e digitale. L'accesso al corso è aperto a chi possiede una laurea triennale, con una verifica iniziale delle competenze. Le iscrizioni sono aperte tutto l'anno, e il corso è erogato in modalità online.",
        "Piano di studi 1": "Pedagogia generale e sociale (12 cfu), Psicologia generale (9 cfu), Storia della pedagogia (12 cfu), Antropologia (6 cfu), Discipline demoetnoantropologiche (6 cfu), Geografia (6 cfu), Psicologia dello sviluppo e psicologia dell’educazione (9 cfu)",
        "Piano di studi 2": "Pedagogia sperimentale (9 cfu), Didattica e pedagogia speciale (12 cfu), Linguistica italiana (9 cfu), Linguaggi espressivi nei processi di apprendimento e crescita personale (6 cfu), Istituzioni di diritto pubblico (6 cfu), Esame a scelta (9 cfu), lingua inglese (6 cfu), Abilità informatiche e telematiche (3 cfu)",
        "Piano di studi 3": "Didattica Della Pedagogia Interculturale (6 cfu), Psicologia dello sviluppo e delle relazioni interpersonali (9 cfu), Storia contemporanea (9 cfu), Storia della filosofia (9 cfu), Psicologia dinamica (9 cfu), Esame a scelta (9 cfu), Tirocini (5 cfu), Prova finale (4 cfu)",
        "operatore 1": "eCampus",
        "operatore 2": "Comparacorsi",
        "operatore 3": "Altri operatori",
        "prezzo 1": "3.900€",
        "prezzo 2": "2.600€",
        "prezzo 3": "4.200€",
        "Domanda 1": "È possibile seguire il corso di Laurea Triennale in Educazione e Formazione di eCampus anche se lavoro a tempo pieno?",
        "Risposta 1": "Sì, la modalità online dell'Università telematica eCampus consente flessibilità per studiare educazione e formazione compatibilmente con impegni lavorativi.",
        "Domanda 2": "Quali competenze informatiche sono necessarie per iniziare il corso online in Educazione e Formazione?",
        "Risposta 2": "È consigliabile avere competenze informatiche di base per navigare su internet e utilizzare piattaforme di e-learning, essenziali per il corso online di educazione.",
        "Domanda 3": "L'Università eCampus fornisce materiali di studio per il corso di Educazione e Formazione?",
        "Risposta 3": "Sì, eCampus fornisce materiali digitali e risorse online per supportare il tuo apprendimento nel corso di educazione e formazione.",
        "Domanda 4": "È richiesta la presenza fisica per qualche esame o attività del corso di Educazione e Formazione?",
        "Risposta 4": "Generalmente no, ma potrebbero esserci eccezioni per specifiche attività o esami pratici. Controlla sempre con l'Università telematica eCampus per dettagli specifici.",
        "Domanda 5": "Come posso interagire con docenti e compagni nel corso di Laurea Triennale in Educazione e Formazione?",
        "Risposta 5": "eCampus promuove l'interazione tramite forum, chat e videoconferenze, permettendoti di discutere e collaborare con docenti e colleghi nel corso online.",
        "Sbocchi": [
          "Educatore in servizi per l'infanzia",
          "Assistente sociale in ambito educativo",
          "Coordinatore di progetti formativi",
          "Animatore socio-educativo",
          "Educatore in comunità di recupero",
          "Consulente pedagogico per enti pubblici e privati",
          "Formatore in aziende e organizzazioni",
          "Operatore in servizi di orientamento e formazione professionale",
          "Mediatore culturale in contesti multilingue e multietnici",
          "Tutor didattico in istituzioni scolastiche e formative."
        ]
    },
    {
        "nome": "Scienze dell'Educazione e della Formazione (Prima Infanzia)",
        "Area": "Formazione",
        "H1": "Corso di Laurea in Prima infanzia (Scienze dell'educazione), Università Digitale eCampus",
        "tag 1": "Laurea Triennale",
        "tag 2": "Durata: 3 anni",
        "tag 3": "180 cfu",
        "tag 4": "Anno accademico 2023/2024",
        "tag 5": "L-19",
        "Descrizione": "Il Curriculum 'Prima infanzia' della Laurea Triennale in Scienze dell'Educazione e della Formazione di eCampus è dedicato a chi mira a diventare educatore specializzato nella fascia 0-3 anni. Focalizzato su cura, educazione e socializzazione, questo corso online integra discipline pedagogico-didattiche, sociologiche, psicologiche e neuropsichiatriche. In 3 anni, il piano di studi copre conoscenze fondamentali e tecniche avanzate per interventi mirati nella prima infanzia, preparando gli studenti ad una professione delicata e fondamentale. L'Università telematica eCampus supporta gli aspiranti educatori con materiali, esami e stage specifici, rendendo il percorso completo e approfondito. L'Università telematica eCampus supporta gli aspiranti educatori con una vasta gamma di prodotti educativi e servizi, inclusi tutoraggi, stage specifici e accesso a risorse digitali, per un percorso di apprendimento completo e approfondito nel campo della prima infanzia. L'accesso al corso è aperto a chi possiede una laurea triennale, con una verifica iniziale delle competenze. Le iscrizioni sono aperte tutto l'anno, e il corso è erogato in modalità online.",
        "Piano di studi 1": "Fondamenti pedagogici per l’infanzia 0-3 anni (12 cfu), Psicologia generale (9 cfu), Storia della pedagogia (12 cfu), Antropologia (6 cfu), Sociologia dell’educazione (6 cfu), Neuropsichiatria infantile (6 cfu), Storia della filosofia (9 cfu), Psicologia dello sviluppo e psicologia dell’educazione (9 cfu)",
        "Piano di studi 2": "Pedagogia sperimentale (9 cfu), Didattica e pedagogia speciale (12 cfu), Linguistica italiana (9 cfu), Linguaggi espressivi nei processi di apprendimento e crescita personale (6 cfu), Istituzioni di diritto pubblico (6 cfu), Laboratorio su pedagogia del nido e dei servizi per l’infanzia (3 cfu), A scelta dello studente (9 cfu), Lingua inglese (3 cfu), Abilità informatiche e telematiche (3 cfu)",
        "Piano di studi 3": "Didattica della pedagogia interculturale (6 cfu), Psicologia dello sviluppo e delle relazioni interpersonali (9 cfu), Laboratorio su metodi di osservazione del comportamento infantile (4 cfu), Storia della filosofia (9 cfu), Psicologia dinamica (9 cfu), A scelta dello studente (9 cfu), Crediti Tirocinio diretto (6 cfu), Tirocinio indiretto (4 cfu), Prova finale (4 cfu)",
        "operatore 1": "eCampus",
        "operatore 2": "Comparacorsi",
        "operatore 3": "Altri operatori",
        "prezzo 1": "3.900€",
        "prezzo 2": "2.600€",
        "prezzo 3": "4.200€",
        "Domanda 1": "Che tipo di supporto offre l'Università telematica eCampus per il curriculum Prima infanzia?",
        "Risposta 1": "eCampus fornisce materiali didattici, accesso a biblioteche digitali, tutoraggio online e assistenza per facilitare l'apprendimento nel corso di educazione e formazione.",
        "Domanda 2": "È possibile conciliare gli impegni lavorativi con lo studio del curriculum Prima infanzia?",
        "Risposta 2": "Sì, la flessibilità del corso online di eCampus permette di gestire gli studi secondo i propri impegni, ideale per chi lavora.",
        "Domanda 3": "Quali competenze informatiche sono necessarie per il curriculum Prima infanzia?",
        "Risposta 3": "Competenze base di navigazione internet e utilizzo di piattaforme e-learning sono sufficienti per iniziare il corso online in Scienze dell’educazione.",
        "Domanda 4": "Come posso interagire con docenti e compagni nel curriculum Prima infanzia?",
        "Risposta 4": "eCampus promuove l'interazione tramite forum, chat, e videoconferenze, facilitando la collaborazione e il confronto nel corso online.",
        "Domanda 5": "Sono previste attività pratiche nel curriculum Prima infanzia?",
        "Risposta 5": "Sì, oltre alla teoria, il curriculum include attività pratiche e stage per applicare direttamente le conoscenze acquisite nel campo della prima infanzia.",
        "Sbocchi": [
          "Educatore nei nidi e nelle scuole dell'infanzia",
          "Coordinatore pedagogico in strutture per l'infanzia",
          "Assistente sociale specializzato in ambito infantile",
          "Operatore in centri di accoglienza per minori",
          "Consulente pedagogico per editoria e media per bambini",
          "Animatore ludico per attività educative e ricreative",
          "Formatore per operatori di servizi per l'infanzia",
          "Esperto in progettazione di spazi e attività educative",
          "Responsabile di progetti educativi in ONG e associazioni",
          "Mediatore culturale in contesti multietnici focalizzati sull'infanzia."
          ]
    },
    {
        "nome": "Scienze dell'Educazione e della Formazione (Educatore nei Servizi per l'Infanzia)",
        "Area": "Formazione",
        "H1": "Corso di Laurea in Servizi per l'infanzia (Scienze dell'educazione), Università Telematica Unimarconi",
        "tag 1": "Laurea Triennale",
        "tag 2": "Durata: 3 anni",
        "tag 3": "180 cfu",
        "tag 4": "Anno accademico 2023/2024",
        "tag 5": "L-19",
        "Descrizione": "La Laurea Triennale in Scienze dell'Educazione e della Formazione, curriculum Educatore nei Servizi per l'Infanzia dell'Università Telematica Unimarconi, è focalizzata su tecniche e competenze per l'educazione nei servizi per l'infanzia. Questo corso online, parte della classe di laurea L-19, prepara gli studenti a diventare professionisti qualificati, capaci di progettare e realizzare interventi educativi. Il percorso include studi di psicologia, pedagogia e sociologia e prevede esami specifici per formare esperti nei servizi per l'infanzia. Gli studenti devono superare ogni esame e una prova finale per dimostrare le conoscenze e le tecniche acquisite nel corso triennale. Grazie alla possibilità di accedere alle lezioni e ai materiali online attraverso la piattaforma virtuale MyUnimarconi gli studenti possono organizzare il loro programma senza dover rinunciare ai loro altri impegni. L'accesso al corso è aperto a chi possiede una laurea triennale, con una verifica iniziale delle competenze. Le iscrizioni sono aperte tutto l'anno, e il corso è erogato in modalità online.",
        "Piano di studi 1": "Pedagogia generale (12 cfu), Storia dei servizi educativi per l’infanzia (12 cfu), Sociologia generale (12 cfu), Psicologia generale (12 cfu), Storia della filosofia (12 cfu)",
        "Piano di studi 2": "A scelta tra: Istituzioni di diritto pubblico (6 cfu), Diritto privato (6 cfu), Sociologia della devianza (12 cfu), Sociologia del lavoro (6 cfu), Sociologia dell’educazione e dell’infanzia (6 cfu), A scelta tra: Geopolitica economica (6 cfu), Geografia umana (6 cfu), Organizzazione aziendale (6 cfu), Pedagogia sperimentale (6 cfu), Pedagogia speciale (6 cfu), Filosofia del linguaggio (6 cfu), Idoneità linguistica: Inglese, Francese (6 cfu)",
        "Piano di studi 3": "Processi di sviluppo ed educazione nella prima infanzia (6 cfu), Processi cognitivi della prima infanzia (6 cfu), Psicologia sociale (6 cfu), Didattica e Pedagogia del gioco (6 cfu), Progettazione e valutazione dei contesti educativi prescolari (6 cfu), Psicopatologia dello sviluppo della prima infanzia (6 cfu), Laboratori di indirizzo: Laboratorio Pedagogia dell’infanzia (1 cfu), Laboratorio di Analisi delle realtà sociali e territoriali dei servizi e degli interventi educativi per la prima infanzia (1 cfu), Laboratorio di didattica e pedagogia del gioco (1 cfu), Laboratorio di progettazione e valutazione dei contesti educativi prescolari (2 cfu), Laboratorio di osservazione del comportamento infantile (1 cfu), Tirocinio (requisiti di accesso) (12 cfu), Tesi di Laurea (6 cfu)",
        "operatore 1": "Unimarconi",
        "operatore 2": "Comparacorsi",
        "operatore 3": "Altri operatori",
        "prezzo 1": "2.760€",
        "prezzo 2": "1.500€",
        "prezzo 3": "2.900€",
        "Domanda 1": "Che tipo di materiale didattico offre Unimarconi per il corso in Educazione nei Servizi per l'Infanzia?",
        "Risposta 1": "Unimarconi fornisce una vasta gamma di materiali didattici digitali, inclusi video, letture e risorse interattive per supportare lo studio online nel campo dell'educazione e formazione.",
        "Domanda 2": "È possibile interagire con docenti e studenti nel corso online di Educazione nei Servizi per l'Infanzia?",
        "Risposta 2": "Sì, la piattaforma di Unimarconi permette interazioni regolari tramite forum, chat e videoconferenze per un apprendimento collaborativo nei servizi per l'infanzia.",
        "Domanda 3": "Quali competenze informatiche sono necessarie per iniziare il corso online di Educazione?",
        "Risposta 3": "Sono richieste competenze informatiche basilari, come navigazione internet e utilizzo di e-mail e piattaforme e-learning, per studiare efficacemente online con Unimarconi.",
        "Domanda 4": "Come viene valutata la mia preparazione iniziale per il corso di Educazione nei Servizi per l'Infanzia?",
        "Risposta 4": "Unimarconi valuta la tua preparazione attraverso un test iniziale non vincolante, per identificare aree di potenziamento e personalizzare il tuo percorso formativo nel campo dell'educazione.",
        "Domanda 5": "Posso accedere al corso di Educazione nei Servizi per l'Infanzia da dispositivi mobili?",
        "Risposta 5": "Sì, la piattaforma di Unimarconi è accessibile da vari dispositivi, inclusi smartphone e tablet, per permetterti di studiare educazione e formazione ovunque tu sia.",
        "Possibili Ruoli Professionali": [
        "Educatore in asili nido e servizi per l'infanzia",
        "Assistente sociale specializzato in ambito infantile",
        "Coordinatore di programmi educativi per l'infanzia",
        "Operatore in centri di accoglienza per minori",
        "Consulente pedagogico in editoria per l'infanzia",
        "Animatore socio-educativo in ludoteche e centri estivi",
        "Formatore per personale in strutture educative per l'infanzia",
        "Responsabile di progetti educativi in enti pubblici e privati",
        "Mediatore culturale in contesti multietnici focalizzati sull'infanzia",
        "Educatore in programmi di intervento precoce per bambini a rischio."
        ]
    },
    {
        "nome": "Scienze dell'Educazione e della Formazione (Educazione Sociale e di Comunità)",
        "Area": "Formazione",
        "H1": "Corso di Laurea in Educazione Sociale e di Comunità, Università Telematica Unimarconi",
        "tag 1": "Laurea Triennale",
        "tag 2": "Durata: 3 anni",
        "tag 3": "180 cfu",
        "tag 4": "Anno accademico 2023/2024",
        "tag 5": "L-19",
        "Descrizione": "Il Corso di Laurea Triennale in Scienze dell'Educazione e della Formazione, curriculum Educazione Sociale e di Comunità, offerto dall'Università Telematica Unimarconi, è un percorso online dedicato alla formazione di professionisti nel campo dell'educazione sociale. Mirato a coloro che desiderano operare in contesti sociali e culturali diversificati, il corso fornisce conoscenze pedagogiche, sociologiche e psicologiche fondamentali. Gli studenti acquisiranno 180 CFU attraverso studi teorici e pratici, preparandosi a gestire interventi educativi e formativi nei servizi comunitari e sociali. Questo percorso di laurea online è ideale per chi cerca una formazione flessibile, ma completa, in educazione sociale e orientamento comunitario. L'accesso al corso è aperto a chi possiede una laurea triennale, con una verifica iniziale delle competenze. Le iscrizioni sono aperte tutto l'anno, e il corso è erogato in modalità online.",
        "Piano di studi 1": "Pedagogia generale (12 cfu), Storia della pedagogia (12 cfu), Sociologia generale (12 cfu), Psicologia generale (12 cfu), Storia della filosofia (12 cfu)",
        "Piano di studi 2": "A scelta fra: Istituzioni di diritto pubblico (6 cfu), Diritto privato (6 cfu), Sociologia della devianza (12 cfu), Sociologia del lavoro (6 cfu), 12 CFU a scelta fra: Geopolitica economica (6 cfu), Geografia umana (6 cfu), Organizzazione aziendale (6 cfu), Pedagogia sperimentale (6 cfu), Pedagogia speciale (6 cfu), Filosofia del linguaggio (6 cfu), Idoneità linguistica: inglese, francese (6 cfu)",
        "Piano di studi 3": "A scelta tra: Storia moderna (12 cfu), Storia contemporanea (12 cfu), Psicologia sociale (6 cfu), Didattica (6 cfu), Tecnologie dell’istruzione e dell’apprendimento (6 cfu), Psicologia clinica (6 cfu), Abilità informatiche (6 cfu), Scelta libera* (6+6 cfu), Tesi di Laurea (6 cfu)",
        "operatore 1": "Unimarconi",
        "operatore 2": "Comparacorsi",
        "operatore 3": "Altri operatori",
        "prezzo 1": "2.760€",
        "prezzo 2": "1.500€",
        "prezzo 3": "2.900€",
        "Domanda 1": "Come viene gestita la formazione pratica nel curriculum di Educazione Sociale e di Comunità?",
        "Risposta 1": "La formazione pratica viene gestita attraverso stage, progetti di campo e simulazioni interattive online, permettendo di applicare le conoscenze teoriche in contesti reali.",
        "Domanda 2": "Quali strumenti di supporto online offre Unimarconi per il corso in Educazione Sociale e di Comunità?",
        "Risposta 2": "Unimarconi offre una piattaforma e-learning con risorse digitali, sessioni interattive, tutoraggio online e forum di discussione per supportare lo studio e l'interazione.",
        "Domanda 3": "È richiesta una presenza fisica per qualche attività del corso di Educazione Sociale e di Comunità?",
        "Risposta 3": "Alcune attività, come stage o progetti pratici, possono richiedere presenza fisica, ma la maggior parte del corso si svolge online per massima flessibilità.",
        "Domanda 4": "Come posso misurare il mio progresso nel corso di Educazione Sociale e di Comunità?",
        "Risposta 4": "Il progresso viene monitorato tramite valutazioni continue, test online, relazioni di progetto e feedback periodici dai tutor per assicurare una formazione di qualità.",
        "Domanda 5": "Posso accedere al corso di Educazione Sociale e di Comunità da qualsiasi luogo?",
        "Risposta 5": "Sì, il corso online di Unimarconi è accessibile da qualsiasi luogo con una connessione internet, offrendo flessibilità per studiare da casa o in movimento.",
        "Possibili Ruoli Professionali": [
          "Educatore in centri di assistenza sociale",
          "Coordinatore di progetti comunitari",
          "Operatore in programmi di prevenzione del disagio giovanile",
          "Mediatore culturale in contesti multietnici",
          "Assistente sociale in enti pubblici e privati",
          "Animatore in progetti di educazione non formale",
          "Operatore in servizi di reinserimento sociale",
          "Consulente per programmi di sviluppo comunitario",
          "Educatore in programmi di sostegno familiare",
          "Formatore in organizzazioni non governative (ONG) e associazioni."
        ]
    },
    {
        "nome": "Scienze dell'Educazione e della Formazione",
        "Area": "Formazione",
        "H1": "Corso di Laurea in Scienze della Formazione e dell'Educazione, Università Telematica Unipegaso",
        "tag 1": "Laurea Triennale",
        "tag 2": "Durata: 3 anni",
        "tag 3": "180 cfu",
        "tag 4": "Anno accademico 2023/2024",
        "tag 5": "L-19",
        "Descrizione": "Il Corso di Laurea Triennale in Scienze della Formazione e dell'Educazione dell'Università Telematica Unipegaso è progettato per fornire agli studenti una solida base di conoscenze pedagogiche e metodologico-didattiche. Questo corso online, afferente alla classe di laurea L-19, integra materie nei campi della scienza filosofica, psicologica, sociologica e antropologica, mirando a una comprensione completa delle questioni educative. Con una durata di tre anni, gli studenti acquisiranno abilità organizzative e gestionali, padronanza delle procedure di monitoraggio e valutazione degli interventi educativi e formativi. Inoltre, il corso offre opportunità di scelta e specializzazione, preparando gli studenti per una carriera nell'insegnamento e nell'educazione. L'accesso al corso è aperto a chi possiede una laurea triennale, con una verifica iniziale delle competenze. Le iscrizioni sono aperte tutto l'anno, e il corso è erogato in modalità online.",
        "Piano di studi 1": "Didattica generale (12 cfu), Pedagogia generale (12 cfu), Storia delle istituzioni educative (12 cfu), Pedagogia sperimentale (9 cfu), Teoria e metodologia delle attività motorie dell'età evolutiva (9 cfu), Lingua inglese (6 cfu)",
        "Piano di studi 2": "Psicologia generale (12 cfu), Didattica speciale (9 cfu), Pedagogia sociale (9 cfu), Teorie e tecniche della comunicazione di massa (6 cfu), Pedagogia interculturale (6 cfu), Filosofia della comunicazione e del linguaggio (12 cfu), Insegnamento a scelta (9 cfu)",
        "Piano di studi 3": "Tecnologie dell'istruzione e dell'apprendimento (9 cfu), Insegnamento a scelta (9 cfu), Docimologia (9 cfu), Storia moderna (12 cfu), Prova di abilità informatica (6 cfu), Prova Finale (12 cfu)",
        "operatore 1": "Unipegaso",
        "operatore 2": "Comparacorsi",
        "operatore 3": "Altri operatori",
        "prezzo 1": "3.400€",
        "prezzo 2": "1.700€",
        "prezzo 3": "3.700€",
        "Domanda 1": "Quali competenze informatiche sono richieste per il corso online in Scienze della Formazione e dell'Educazione?",
        "Risposta 1": "Sono necessarie competenze di base come la navigazione web e l'uso di piattaforme e-learning, essenziali per seguire efficacemente il corso online offerto da Unipegaso.",
        "Domanda 2": "Come posso interagire con docenti e studenti nel corso di Scienze della Formazione?",
        "Risposta 2": "Unipegaso offre forum online, chat e videoconferenze per facilitare interazioni e scambi con docenti e colleghi, arricchendo l'esperienza di apprendimento a distanza.",
        "Domanda 3": "È possibile accedere al corso di Scienze della Formazione e dell'Educazione da dispositivi mobili?",
        "Risposta 3": "Sì, la piattaforma di Unipegaso è accessibile da dispositivi mobili, consentendo agli studenti di studiare da qualsiasi luogo e in qualsiasi momento.",
        "Domanda 4": "Come vengono valutate le competenze acquisite nel corso di Scienze della Formazione?",
        "Risposta 4": "La valutazione avviene attraverso esami online, progetti, test in itinere e feedback continui, assicurando una misurazione efficace del progresso degli studenti.",
        "Domanda 5": "È necessaria una presenza fisica per alcune attività nel corso di Scienze della Formazione?",
        "Risposta 5": "Alcune attività, come le verifiche finali, possono richiedere presenza fisica nelle sedi di Unipegaso, mentre la maggior parte del corso si svolge online.",
        "Possibili Ruoli Professionali": [
          "Educatore in servizi per l'infanzia e l'adolescenza",
          "Operatore in centri di assistenza sociale",
          "Animatore socio-culturale",
          "Assistente in progetti educativi comunitari",
          "Coordinatore in programmi di educazione non formale",
          "Consulente pedagogico per enti pubblici e privati",
          "Formatore in centri di formazione professionale",
          "Mediatore familiare in servizi di sostegno alla famiglia",
          "Operatore in progetti di integrazione e inclusione sociale",
          "Educatore in strutture residenziali per minori e adulti."
        ]
    },
    {
        "nome": "Innovazione Educativa e Apprendimento Permanente nella Formazione degli Adulti in Contesti Nazionali e Internazionali",
        "Area": "Formazione",
        "H1": "Corso di Laurea Magistrale in Apprendimento Permanente e Innovazione Educativa nella Formazione degli Adulti in Contesti Nazionali e Internazionali, Università Telematica IUL",
        "tag 1": "Laurea Magistrale",
        "tag 2": "Durata: 2 anni",
        "tag 3": "120 cfu",
        "tag 4": "Anno accademico 2023/2024",
        "tag 5": "LM-57",
        "Descrizione": "Il Corso di Laurea Magistrale in Apprendimento Permanente e Innovazione Educativa nella Formazione degli Adulti in Contesti Nazionali e Internazionali, offerto dall'Università Telematica IUL, mira a formare professionisti specializzati nella programmazione, organizzazione e valutazione di servizi educativi per adulti. Questo corso, afferente alla classe LM-57, è progettato per fornire agli studenti competenze socio-psico-pedagogiche e filosofiche, fondamentali per rilevare e rispondere ai bisogni educativi in vari contesti sociali. Gli studenti acquisiranno abilità nella pianificazione e realizzazione di interventi educativi sia locali che internazionali, con un'enfasi sull'uso delle tecnologie informatiche e sulla valutazione degli interventi formativi. Il corso online di IUL prevede anche un tirocinio per completare lo studio in scienze della formazione e del lifelong learning. L'accesso al corso è aperto a chi possiede una laurea magistrale, con una verifica iniziale delle competenze. Le iscrizioni sono aperte tutto l'anno, e il corso è erogato in modalità online.",
        "Piano di studi 1": "Pedagogia della formazione degli adulti (12 cfu), Pedagogia sperimentale per un digital learning formativo (12 cfu), Psicologia dello sviluppo nell’arco della vita (6 cfu), Etiche per la società complessa (6 cfu), Informatica applicata alla formazione degli adulti (6 cfu), Progettazione e gestione di programmi europei e internazionali (12 cfu), Ulteriori conoscenze linguistiche (Laboratorio di lingua inglese) (6 cfu)",
        "Piano di studi 2": "Modelli educativi nella formazione continua (6 cfu), Teorie e pratiche della democrazia (6 cfu), Abilità informatiche e telematiche (6 cfu), Formazione linguistica e alfabetizzazione in età adulta (12 cfu), Esami a scelta dello studente* (12 cfu), Tirocini formativi e di orientamento (6 cfu), Altre abilità – Laboratorio di ricerca (4 cfu), Prova finale (8 cfu)",
        "operatore 1": "IUL",
        "operatore 2": "Comparacorsi",
        "operatore 3": "Altri operatori",
        "prezzo 1": "2.500€",
        "prezzo 2": "2.300€",
        "prezzo 3": "2.700€",
        "Domanda 1": "Come vengono gestite le interazioni studente-docente nel corso online di Apprendimento Permanente?",
        "Risposta 1": "IUL utilizza piattaforme e-learning per facilitare interazioni regolari attraverso forum, chat e videoconferenze, arricchendo l'esperienza di apprendimento a distanza.",
        "Domanda 2": "Quali competenze informatiche sono necessarie per il corso in Apprendimento Permanente e Innovazione Educativa?",
        "Risposta 2": "Sono richieste competenze di base nella navigazione web e nell'uso di piattaforme e-learning, fondamentali per partecipare attivamente al corso online offerto da IUL.",
        "Domanda 3": "È possibile accedere al corso di Apprendimento Permanente da dispositivi mobili?",
        "Risposta 3": "Sì, la piattaforma di IUL è ottimizzata per dispositivi mobili, consentendo flessibilità e accessibilità agli studenti da qualsiasi luogo.",
        "Domanda 4": "Come vengono valutate le competenze acquisite nel corso di Apprendimento Permanente?",
        "Risposta 4": "La valutazione avviene tramite test online, progetti, esami e feedback continui, garantendo una misurazione accurata del progresso dello studente.",
        "Domanda 5": "Sono previsti tirocini o esperienze pratiche nel corso di Apprendimento Permanente?",
        "Risposta 5": "Sì, il corso include un periodo di tirocinio per applicare le conoscenze teoriche in contesti reali, preparando gli studenti all'ambiente lavorativo.",
        "Sbocchi": [
          "Coordinatore di programmi educativi per adulti",
          "Esperto in progetti di formazione continua",
          "Consulente pedagogico in enti pubblici e privati",
          "Formatore in organizzazioni non governative (ONG)",
          "Specialista in educazione degli adulti in contesti internazionali",
          "Responsabile di iniziative di apprendimento permanente",
          "Mediatore culturale in programmi di integrazione",
          "Progettista di percorsi formativi personalizzati per adulti",
          "Organizzatore di attività educative in centri comunitari",
          "Facilitatore di apprendimento online e a distanza."
        ]
    },
    {
        "nome": "Ingegneria Informatica",
        "Area": "Informatica",
        "H1": "Corso di Ingegneria Informatica, Università Telematica Unifortunato",
        "tag 1": "Laurea Triennale",
        "tag 2": "Durata: 3 anni",
        "tag 3": "180 cfu",
        "tag 4": "Anno accademico 2023/2024",
        "tag 5": "L-19",
        "Descrizione": "Il Corso di Ingegneria Informatica dell'Università Telematica Unifortunato è una laurea triennale progettata per formare professionisti qualificati nell'uso dell'informatica nella gestione aziendale, con una solida preparazione ingegneristica. Il corso, che dura 3 anni e appartiene alla classe di laurea L-19, copre aree come le telecomunicazioni, l'elettronica e l'automazione, oltre a fornire competenze trasversali in informatica, analisi degli algoritmi e programmazione. Il percorso formativo è completo e altamente professionalizzante, dando agli studenti flessibilità e capacità analitiche. Gli studenti devono totalizzare 180 CFU, suddivisi tra materie di base, attività caratterizzanti e altre attività, con l'opportunità di partecipare a laboratori e progetti interdisciplinari in gruppo. Il corso online si avvale di software di team collaboration e metodologie all'avanguardia per la gestione dei progetti, rendendo i laureati pronti per le sfide del mercato del lavoro. L'accesso al corso è aperto a chi possiede una laurea magistrale, con una verifica iniziale delle competenze. Le iscrizioni sono aperte tutto l'anno, e il corso è erogato in modalità online.",
        "Piano di studi 1": "Fondamenti di Informatica (9 cfu), Analisi Matematica (9 cfu), Economia e Finanza Aziendale (6 cfu), Lingua Inglese (6 cfu), Insegnamento a scelta (6 cfu), Fisica (9 cfu), Geometria e Algebra (6 cfu), Analisi Matematica 2 (6 cfu)",
        "Piano di studi 2": "Elettrotecnica (6 cfu), Calcolatori elettronici (9 cfu), Basi di dati (6 cfu), Algoritmi e strutture dati (9 cfu), oppure Sistemi informativi sanitari (9 cfu), Teoria dei segnali (9 cfu), Controlli automatici (9 cfu), Elettronica (6 cfu), Sistemi operativi (6 cfu), oppure Qualità dei sistemi per la salute (6 cfu)",
        "Piano di studi 3": "Insegnamento a scelta (6 cfu), Sistemi di telecomunicazioni (6 cfu), Ingegneria del software (9 cfu), oppure Intelligenza artificiale per i sistemi per la salute (9 cfu), Reti di calcolatori (9 cfu), oppure Protocolli di sicurezza di rete in medicina (9 cfu), Tecnologie per il web (9 cfu), oppure Robotica medica (9 cfu), Embedded system & Internet of things (9 cfu), Laboratorio (12 cfu), Prova Finale (3 cfu)",
        "operatore 1": "Unifortunato",
        "operatore 2": "Comparacorsi",
        "operatore 3": "Altri operatori",
        "prezzo 1": "3.500€",
        "prezzo 2": "3.300€",
        "prezzo 3": "3.700€",
        "Domanda 1": "Quali competenze informatiche sono necessarie per iniziare il corso di Ingegneria Informatica?",
        "Risposta 1": "Competenze informatiche di base sono sufficienti per iniziare, ma il corso svilupperà ulteriormente queste abilità, specialmente in programmazione e sistemi.",
        "Domanda 2": "È possibile interagire con altri studenti e docenti nei corsi di laurea online?",
        "Risposta 2": "Sì, Unifortunato offre piattaforme per interazioni virtuali tra studenti e docenti, come forum di discussione, chat e videoconferenze.",
        "Domanda 3": "Come vengono valutate le competenze acquisite durante il corso di Ingegneria Informatica?",
        "Risposta 3": "La valutazione avviene tramite esami periodici, progetti di gruppo, laboratori e attività pratiche, oltre a test continui per monitorare il progresso.",
        "Domanda 4": "Sono previste attività pratiche o di laboratorio nel corso di Ingegneria Informatica?",
        "Risposta 4": "Sì, il corso include attività di laboratorio e progetti pratici per applicare le conoscenze teoriche in contesti reali di ingegneria informatica.",
        "Domanda 5": "Posso accedere al corso di Ingegneria Informatica da qualsiasi luogo?",
        "Risposta 5": "Sì, essendo un corso online, puoi accedervi da qualsiasi luogo con una connessione Internet, offrendo flessibilità e comodità nello studio.",
        "Sbocchi": [
          "Sviluppatore software",
          "Analista di sistemi informatici",
          "Ingegnere di rete",
          "Tecnico di supporto IT",
          "Consulente IT",
          "Amministratore di database",
          "Specialista in sicurezza informatica",
          "Progettista di sistemi embedded",
          "Ingegnere del software per applicazioni mobile",
          "Responsabile IT in aziende e organizzazioni."
        ]
    },
    {
        "nome": "Informatica per le Aziende Digitali",
        "Area": "Informatica",
        "H1": "Corso di Laurea in Informatica per le Aziende Digitali, Università Telematica Unipegaso",
        "tag 1": "Laurea Triennale",
        "tag 2": "Durata: 3 anni",
        "tag 3": "180 cfu",
        "tag 4": "Anno accademico 2023/2024",
        "tag 5": "L-31",
        "Descrizione": "Il Corso di Laurea in Informatica per le Aziende Digitali dell'Università Telematica Unipegaso mira a formare professionisti in tecnologie emergenti per lo sviluppo di soluzioni digitali. Questa laurea triennale online offre una formazione completa in ambiti cruciali dell'informatica, preparando laureati capaci di progettare e gestire sistemi informatici, basi di dati e reti. Il corso, che richiede l'acquisizione di 180 CFU in 3 anni, copre materie come programmazione, architettura dei calcolatori, cybersecurity, e aspetti giuridici ed economici legati alle aziende digitali. Gli studenti acquisiscono anche competenze per affrontare le sfide di sicurezza e scalabilità dei sistemi e applicazioni web, rendendoli pronti per il mercato del lavoro in contesti pubblici e privati che richiedono una preparazione specifica in informatica per le aziende digitali. L'accesso al corso è aperto a chi possiede una laurea magistrale, con una verifica iniziale delle competenze. Le iscrizioni sono aperte tutto l'anno, e il corso è erogato in modalità online.",
        "Piano di studi 1": "Matematica discreta (6 cfu), Analisi matematica (9 cfu), Calcolo delle probabilità e statistica (9 cfu), Programmazione 1 (12 cfu), Basi di dati (9 cfu), Algoritmi e strutture dati (12 cfu), A scelta dello studente (6 cfu)",
        "Piano di studi 2": "Architettura dei calcolatori (9 cfu), Reti di calcolatori e Cybersecurity (12 cfu), Programmazione 2 (12 cfu), Ingegneria del software (12 cfu), Diritto per le aziende digitali (9 cfu), A scelta dello studente (9 cfu)",
        "Piano di studi 3": "Tecnologie Web (12 cfu), Programmazione distribuita e cloud computing (12 cfu), Strategia, organizzazione e marketing (9 cfu), Corporate planning e valore d’impresa (9 cfu), Altre conoscenze utili per l’inserimento nel mondo del lavoro (6 cfu), Per la conoscenza di almeno una lingua straniera (3 cfu), Prova Finale (3 cfu)",
        "operatore 1": "Unifortunato",
        "operatore 2": "Comparacorsi",
        "operatore 3": "Altri operatori",
        "prezzo 1": "3.400€",
        "prezzo 2": "1.700€",
        "prezzo 3": "3.900€",
        "Domanda 1": "Quali competenze informatiche sono necessarie per iniziare il primo anno del corso in Informatica per le Aziende Digitali?",
        "Risposta 1": "Competenze di base in informatica sono consigliate, ma il corso è strutturato per sviluppare ulteriormente queste abilità, specialmente in ambiti specifici del settore.",
        "Domanda 2": "È possibile interagire con altri studenti e docenti nel corso online?",
        "Risposta 2": "Sì, Unipegaso fornisce piattaforme per interazioni virtuali come forum, chat e videoconferenze, facilitando la collaborazione e lo scambio di idee tra studenti e docenti.",
        "Domanda 3": "Come viene valutata la mia preparazione durante il corso di laurea online?",
        "Risposta 3": "La valutazione avviene tramite esami periodici, progetti, laboratori e test continui, per monitorare il progresso e assicurare una formazione di qualità.",
        "Domanda 4": "Sono previste attività pratiche nell'offerta formativa del corso di Informatica per le Aziende Digitali?",
        "Risposta 4": "Sì, il corso include attività di laboratorio e progetti pratici, per applicare le conoscenze teoriche in contesti reali e situazioni di lavoro.",
        "Domanda 5": "Posso accedere al corso di Informatica per le Aziende Digitali da qualsiasi luogo?",
        "Risposta 5": "Sì, essendo un corso online, è accessibile da qualsiasi luogo con una connessione internet, offrendo flessibilità agli studenti.",
        "Sbocchi": [
          "Sviluppatore software per aziende digitali",
          "Analista di sistemi informatici in settori tecnologici",
          "Consulente IT per la trasformazione digitale",
          "Gestore di progetti IT in imprese e startup",
          "Specialista in sicurezza informatica",
          "Amministratore di reti e sistemi informatici",
          "Esperto in sviluppo di applicazioni mobile",
          "Tecnico di supporto IT in aziende digitali",
          "Programmatore di database in ambienti aziendali",
          "Analista di dati per ottimizzazione aziendale."
        ]
    },
    {
        "nome": "Ingegneria informatica",
        "Area": "Informatica",
        "H1": "Corso di Laurea in Ingegneria informatica, Università Telematica Mercatorum",
        "tag 1": "Laurea Triennale",
        "tag 2": "Durata: 3 anni",
        "tag 3": "180 cfu",
        "tag 4": "Anno accademico 2023/2024",
        "tag 5": "L-08",
        "Descrizione": "Il Corso di Laurea in Ingegneria Informatica dell'Università Telematica Mercatorum si focalizza su una preparazione ingegneristica completa per sviluppare e impiegare tecnologie informatiche. Questa laurea triennale online abbraccia aree dell'ingegneria informatica e della sicurezza, formando ingegneri con una solida base culturale e capacità di utilizzare metodi e tecnologie informatiche. Il corso è interdisciplinare, coprendo anche aree come automazione, telecomunicazioni ed elettronica. Gli studenti ricevono una formazione teorica e metodologica approfondita, preparandoli sia per il mondo del lavoro sia per studi avanzati. Il piano di studi include matematica, fisica, programmazione, ingegneria dell'informazione, elettronica, elettrotecnica, cybersecurity e ingegneria del software. Il corso offre flessibilità, con iscrizioni aperte tutto l'anno e senza test d'ingresso. L'accesso al corso è aperto a chi possiede una laurea magistrale, con una verifica iniziale delle competenze. Le iscrizioni sono aperte tutto l'anno, e il corso è erogato in modalità online.",
        "Piano di studi 1": "Analisi Matematica I e geometria (12 cfu), Analisi Matematica II (6 cfu), Statistica per economia e impresa (9 cfu), Fisica (12 cfu), Programmazione (12 cfu), Ricerca operativa per ICT (9 cfu)",
        "Piano di studi 2": "Elettrotecnica (6 cfu), Architetture e reti di calcolatori (9 cfu), Fondamenti di elettronica (9 cfu), Fondamenti di automatica (9 cfu), Elaborazione dei segnali e delle informazioni di misura (9 cfu), Ingegneria dei dati e modellizzazione (9 cfu), Diritto commerciale delle imprese digitali (9 cfu)",
        "Piano di studi 3": "Sicurezza informatica (6 cfu), Fondamenti di telecomunicazioni (9 cfu), Sicurezza delle reti e Cyber Security (6 cfu), Ingegneria del software (9 cfu), Tecniche e progettazione dei sistemi di controllo (6 cfu), Insegnamento a scelta (6 cfu), Insegnamento a scelta (6 cfu), Lingua inglese (3 cfu), Tirocini formativi e di orientamento (3 cfu), Per stages e tirocini presso imprese, enti pubblici o privati (3 cfu), Prova Finale (3 cfu)",
        "operatore 1": "Mercatorum",
        "operatore 2": "Comparacorsi",
        "operatore 3": "Altri operatori",
        "prezzo 1": "3.400€",
        "prezzo 2": "1.700€",
        "prezzo 3": "3.900€",
        "Domanda 1": "Quali sono le competenze base necessarie per iniziare il Corso di Ingegneria Informatica della facoltà Mercatorum?",
        "Risposta 1": "Una comprensione fondamentale della matematica, fisica e logica è utile, ma il corso è strutturato per sviluppare queste competenze nel contesto dell'ingegneria informatica.",
        "Domanda 2": "Il Corso di Ingegneria Informatica è completamente online o prevede corsi da frequentare di persona?",
        "Risposta 2": "Il corso è principalmente online, ma può includere incontri di persona o attività pratiche a seconda dei requisiti specifici di alcuni moduli.",
        "Domanda 3": "Come vengono gestite le interazioni tra studenti e docenti nel corso online?",
        "Risposta 3": "Le interazioni sono gestite tramite piattaforme digitali che includono forum di discussione, chat e videoconferenze, promuovendo un ambiente di apprendimento collaborativo e interattivo.",
        "Domanda 4": "È possibile accedere al corso di Ingegneria Informatica da qualsiasi parte del mondo?",
        "Risposta 4": "Sì, essendo un corso online, gli studenti possono accedere da qualsiasi luogo, purché abbiano una connessione internet stabile.",
        "Domanda 5": "Ci sono progetti di gruppo o collaborazioni tra gli obiettivi formativi nel Corso di laurea online di Ingegneria Informatica?",
        "Risposta 5": "Sì, il corso incoraggia la collaborazione tra studenti attraverso progetti di gruppo e attività di laboratorio, migliorando le competenze di lavoro di squadra e problem-solving.",
        "Sbocchi": [
          "Sviluppatore software",
          "Analista di sistemi",
          "Ingegnere di rete",
          "Consulente IT",
          "Specialista in sicurezza informatica",
          "Amministratore di database",
          "Progettista di sistemi embedded",
          "Programmatore di applicazioni mobili",
          "Analista di dati",
          "Tecnico di supporto IT."
        ]
    },
    {
        "nome": "Statistica e Big Data",
        "Area": "Informatica",
        "H1": "Corso di Laurea in Statistica e Big Data, Università Telematica Mercatorum",
        "tag 1": "Laurea Triennale",
        "tag 2": "Durata: 3 anni",
        "tag 3": "180 cfu",
        "tag 4": "Anno accademico 2023/2024",
        "tag 5": "L-41",
        "Descrizione": "Il Corso di Laurea in Statistica e Big Data dell'Università Telematica Mercatorum mira a formare esperti nella gestione e analisi di grandi basi di dati in contesti aziendali e industriali. Questa laurea triennale online, per definizione, unisce insegnamenti di informatica, statistica ed economia per sviluppare competenze necessarie nella raccolta, gestione, analisi e sintesi dei Big Data. Gli studenti acquisiscono una formazione interdisciplinare, comprendendo materie economico-manageriali, giuridiche, oltre a aspetti teorico-formali e applicativi. Il percorso offre flessibilità per eventuali studi avanzati e include attività laboratoriali virtuali per applicare praticamente le conoscenze acquisite. Questo corso online prepara per carriere in settori dove l'analisi di Big Data è alla base delle competenze.",
        "Piano di studi 1": "Elementi di sistemi di elaborazione e programmazione (9 cfu), Analisi matematica e geometria (9 cfu), Calcolo delle probabilità (9 cfu), Statistica di base (9 cfu), Data mining e big data (9 cfu), Algoritmi e strutture dati (9 cfu), Per la conoscenza di almeno una lingua straniera (6 cfu)",
        "Piano di studi 2": "Programmazione per la statistica e i big data (9 cfu), Inferenza e modelli statistici (9 cfu), Indicatori spazio-temporali con i big data (9 cfu), Economia e management dell'innovazione (6 cfu), Economia aziendale (6 cfu), Basi di dati (12 cfu), Finanza aziendale (9 cfu), Altre conoscenze utili per l'inserimento nel mondo del lavoro (3 cfu)",
        "Piano di studi 3": "Elaborazione dei Big data per l'impresa (6 cfu), Modelli e dati per lo sviluppo sostenibile (9 cfu), Elementi di Economia Politica (6 cfu), Società, cambiamento e innovazione (6 cfu), Diritto della privacy e protezione dei dati personali (9 cfu), Diritto dell'informatica e delle tecnologie dell'informazione (6 cfu), Insegnamento a scelta (12 cfu), Prova Finale (3 cfu)",
        "operatore 1": "Mercatorum",
        "operatore 2": "Comparacorsi",
        "operatore 3": "Altri operatori",
        "prezzo 1": "3.400€",
        "prezzo 2": "1.700€",
        "prezzo 3": "3.900€",
        "Domanda 1": "Quali competenze informatiche sono necessarie per iniziare il corso in Statistica e Big Data?",
        "Risposta 1": "Competenze di base in informatica e matematica sono utili, ma il corso è strutturato per sviluppare ulteriormente queste abilità, specialmente in analisi dati e programmazione.",
        "Domanda 2": "Come vengono gestite le interazioni online tra studenti e docenti nel corso di Statistica e Big Data?",
        "Risposta 2": "Le interazioni sono facilitate tramite piattaforme di e-learning con forum, chat e videoconferenze, promuovendo un ambiente di apprendimento collaborativo e interattivo.",
        "Domanda 3": "È possibile accedere al corso di Statistica e Big Data da qualsiasi parte del mondo?",
        "Risposta 3": "Sì, essendo un corso online, gli studenti possono partecipare da qualsiasi luogo con una connessione internet stabile.",
        "Domanda 4": "Ci sono progetti di gruppo o collaborazioni nel corso di Statistica e Big Data?",
        "Risposta 4": "Sì, il corso incoraggia la collaborazione tramite progetti di gruppo e attività di laboratorio, migliorando le competenze di lavoro di squadra e problem-solving.",
        "Domanda 5": "Come viene valutata la mia preparazione durante il corso?",
        "Risposta 5": "La valutazione avviene tramite esami periodici, progetti, laboratori e test continui, per monitorare il progresso e assicurare una formazione di qualità.",
        "Sbocchi": [
          "Analista di Big Data",
          "Specialista in Data Science",
          "Tecnico gestore di basi di dati",
          "Consulente in analisi dati per aziende",
          "Esperto in Business Intelligence",
          "Analista di sistemi informatici",
          "Ricercatore in ambito statistico",
          "Sviluppatore di software per l'analisi dati",
          "Analista per la sicurezza informatica",
          "Project Manager in progetti di data analytics."
        ]
    },
    {
        "nome": "Ingegneria Informatica e dell’Automazione (Database)",
        "Area": "Informatica",
        "H1": "Corso di Laurea in Database (Ingegneria informatica e dell'automazione), Università Telematica eCampus",
        "tag 1": "Laurea Triennale",
        "tag 2": "Durata: 3 anni",
        "tag 3": "180 cfu",
        "tag 4": "Anno accademico 2023/2024",
        "tag 5": "L-08",
        "Descrizione": "Il Corso di Laurea online in Ingegneria Informatica e dell'Automazione, indirizzo Database, dell'Università Telematica eCampus, mira a formare ingegneri esperti nella progettazione, configurazione e gestione di sistemi di elaborazione, reti di calcolatori e database. Parte della classe di laurea L-08, il percorso triennale si concentra, per definizione, su informatica, automazione e sicurezza dell'informazione, fornendo le competenze necessarie per operare nell'Internet del futuro, inclusi Internet of Things, smart cities e droni. Il corso offre una preparazione interdisciplinare, con un focus particolare sull'analisi e gestione di Big Data e sistemi informativi aziendali, preparando gli studenti per diverse carriere nell'ICT e settori correlati. Con 180 CFU, il corso include stage formativi e l'apprendimento della lingua inglese.",
        "Piano di studi 1": "Analisi matematica (12 cfu), Fondamenti di informatica (12 cfu), Complementi di matematica (6 cfu), Elettrotecnica (9 cfu), Fisica (12 cfu), Analisi numerica (6 cfu), Lingua inglese (3 cfu)",
        "Piano di studi 2": "Calcolatori elettronici e sistemi operativi (12 cfu), Base di dati (6 cfu), Sistemi per la gestione dei dati (6 cfu), Sistemi per il supporto alle decisioni (6 cfu), Fondamenti di automatica (9 cfu), Algoritmi e strutture dati (6 cfu), Internet of things (9 cfu), Elettronica dei sistemi digitali (6 cfu)",
        "Piano di studi 3": "Automazione industriale (6 cfu), Compatibilità elettromagnetica (6 cfu), Ricerca operativa (6 cfu), Progetto di applicazioni basate su basi di dati (6 cfu), Misure meccaniche e termiche (6 cfu), A scelta dello studente (18 cfu), Tirocinio (6 cfu), Prova finale (6 cfu)",
        "operatore 1": "eCampus",
        "operatore 2": "Comparacorsi",
        "operatore 3": "Altri operatori",
        "prezzo 1": "5.900€",
        "prezzo 2": "2.600€",
        "prezzo 3": "6.000€",
        "Domanda 1": "Quali sono gli obiettivi formativi specifici del corso di Ingegneria Informatica e dell'Automazione, indirizzo Database?",
        "Risposta 1": "Gli obiettivi includono la formazione di professionisti in grado di gestire sistemi di elaborazione e database, con una forte enfasi sull'automazione e l'integrazione di nuove tecnologie come IoT e smart cities.",
        "Domanda 2": "Quali conoscenze preliminari sono raccomandate per accedere al corso?",
        "Risposta 2": "Una solida base in matematica, fisica e principi base di informatica è consigliata per affrontare con successo gli argomenti del corso.",
        "Domanda 3": "C'è una forte richiesta nel mercato per professionisti formati in questo campo?",
        "Risposta 3": "Sì, c'è una crescente domanda nel mercato del lavoro per professionisti specializzati in ingegneria informatica e dell'automazione, soprattutto in aree come database, sicurezza informatica, e sistemi intelligenti.",
        "Domanda 4": "Il corso include formazione pratica o solo teorica?",
        "Risposta 4": "Il corso offre un equilibrio tra teoria e pratica, con attività laboratoriali e progetti che simulano situazioni reali di lavoro.",
        "Domanda 5": "È necessaria una conoscenza avanzata di informatica prima di iniziare il corso?",
        "Risposta 5": "Non è richiesta una conoscenza avanzata, ma una comprensione di base dell'informatica e delle sue applicazioni è benefica per un apprendimento efficace nel corso.",
        "Sbocchi": [
          "Sviluppatore di sistemi di database",
          "Analista di sicurezza informatica",
          "Ingegnere di sistemi automatizzati",
          "Progettista di soluzioni IoT (Internet of Things)",
          "Consulente IT per automazione aziendale",
          "Analista di reti di calcolatori",
          "Specialista in sviluppo di software per smart cities",
          "Tecnico di supporto per sistemi informativi aziendali",
          "Ingegnere di droni e robotica",
          "Amministratore di sistemi e infrastrutture IT."
        ]
    },
    {
        "nome": "Ingegneria Informatica e dell’Automazione (Droni)",
        "Area": "Informatica",
        "H1": "Corso di Laurea in Droni (Ingegneria informatica e dell'automazione), Università Telematica eCampus",
        "tag 1": "Laurea Triennale",
        "tag 2": "Durata: 3 anni",
        "tag 3": "180 cfu",
        "tag 4": "Anno accademico 2023/2024",
        "tag 5": "L-08",
        "Descrizione": "Il Corso di Laurea in Ingegneria Informatica e dell'Automazione, indirizzo Droni, offerto dall'Università Telematica eCampus, si propone di formare ingegneri specializzati nel settore dei droni. Questo percorso triennale online fa parte della classe di laurea L-08 e mira a fornire le competenze necessarie per la progettazione, configurazione e gestione di sistemi di elaborazione, reti di calcolatori, applicazioni software e sistemi informativi aziendali. Il curriculum si concentra sullo sviluppo di soluzioni innovative nel campo dei droni, integrando conoscenze di robotica e elaborazione di informazioni. In totale, gli iscritti dovranno acquisire 180 CFU, suddivisi in attività di base, caratterizzanti, affini e integrative, e altri crediti formativi inclusi stage e conoscenza di lingue straniere. Questo percorso offre una formazione completa per chi desidera specializzarsi nella progettazione e gestione di droni e sistemi automatizzati.",
        "Piano di studi 1": "Analisi matematica (12 cfu), Fondamenti di informatica (12 cfu), Complementi di matematica (6 cfu), Telerilevamento da droni (9 cfu), Fisica (12 cfu), Analisi numerica (6 cfu), Lingua inglese (3 cfu)",
        "Piano di studi 2": "Calcolatori elettronici e sistemi operativi (12 cfu), Segnali e sistemi (6 cfu), Modellistica e simulazione(6 cfu), Fondamenti di automatica (9 cfu), Algoritmi e strutture dati (6 cfu), Internet of things (9 cfu), Strumenti per la progettazione di UAV (6 cfu)",
        "Piano di studi 3": "Automazione industriale (6 cfu), Compatibilità elettromagnetica (6 cfu), Ricerca operativa (6 cfu), Controllo e programmazione di sistemi (6 cfu), Misure meccaniche e termiche (6 cfu), A scelta dello studente (18 cfu), Tirocinio (6 cfu), Prova finale (6 cfu)",
        "operatore 1": "eCampus",
        "operatore 2": "Comparacorsi",
        "operatore 3": "Altri operatori",
        "prezzo 1": "5.900€",
        "prezzo 2": "2.600€",
        "prezzo 3": "6.000€",
        "Domanda 1": "Quali sono gli obiettivi formativi specifici dell'indirizzo Droni in Ingegneria Informatica e dell'Automazione?",
        "Risposta 1": "L'obiettivo è formare professionisti qualificati nell'uso e nella gestione di droni, con competenze specifiche nell'automazione e nell'informatica applicata a questo settore in rapida crescita.",
        "Domanda 2": "Quali conoscenze preliminari sono raccomandate per accedere all'indirizzo Droni?",
        "Risposta 2": "Una buona base in matematica e fisica, oltre a una comprensione fondamentale dell'informatica e dell'automazione, è raccomandata per approfittare pienamente del corso.",
        "Domanda 3": "C'è una forte richiesta nel mercato per professionisti formati in questo campo specifico dei droni?",
        "Risposta 3": "Sì, esiste una crescente domanda per professionisti con competenze specializzate nella gestione e sviluppo di droni, sia in ambito industriale che commerciale.",
        "Domanda 4": "Il corso include formazione pratica sui droni o si concentra solo sulla teoria?",
        "Risposta 4": "Il corso combina teoria e pratica, con laboratori e progetti specifici per fornire esperienza pratica nella gestione e nell'uso dei droni.",
        "Domanda 5": "È necessaria una conoscenza avanzata di ingegneria prima di iniziare il corso?",
        "Risposta 5": "Non è richiesta una conoscenza avanzata di ingegneria, ma una comprensione di base dei principi di informatica e automazione è benefica per un apprendimento efficace nel corso.",
        "Sbocchi": [
          "Progettista di droni e sistemi UAV (Unmanned Aerial Vehicles)",
          "Analista di dati raccolti da droni",
          "Ingegnere di controllo e automazione per droni",
          "Sviluppatore di software per la navigazione dei droni",
          "Tecnico per la manutenzione e il testing di droni",
          "Specialista in applicazioni di droni per la sicurezza",
          "Consulente per l'impiego di droni in ambito agricolo",
          "Ingegnere per lo sviluppo di sistemi di telemetria per droni",
          "Esperto in sistemi di sorveglianza e monitoraggio tramite droni",
          "Responsabile della conformità normativa per l'uso dei droni."
        ]
    },
    {
        "nome": "Ingegneria Informatica e dell’Automazione (Ingegneria Informatica e delle App)",
        "Area": "Informatica",
        "H1": "Corso di Laurea in Ingegneria delle app (Ingegneria informatica e dell'automazione), Università Telematica eCampus",
        "tag 1": "Laurea Triennale",
        "tag 2": "Durata: 3 anni",
        "tag 3": "180 cfu",
        "tag 4": "Anno accademico 2023/2024",
        "tag 5": "L-08",
        "Descrizione": "Il Corso di Laurea in Ingegneria Informatica e dell'Automazione, indirizzo Ingegneria delle App, offerto dall'Università Telematica eCampus, è progettato per formare esperti nella progettazione e implementazione di applicazioni su dispositivi mobili e il loro impiego nell'ambito del Future Internet, come Internet of Things e smart cities. Parte della classe di laurea L-08, il corso triennale online si concentra sulla combinazione di informatica, automazione, elettronica e telecomunicazioni. Gli studenti acquisiranno 180 CFU, studiando materie come ricerca operativa, analisi numerica e matematica. Il corso include anche stage formativi e l'apprendimento di una lingua straniera, fornendo una formazione completa per aspiranti ingegneri informatici specializzati in app mobile e tecnologie correlate.",
        "Piano di studi 1": "Analisi matematica (12 cfu), Fondamenti di informatica (12 cfu), Complementi di matematica (6 cfu), Telerilevamento da droni (9 cfu), Fisica (12 cfu), Analisi numerica (6 cfu), Lingua inglese (3 cfu)",
        "Piano di studi 2": "Calcolatori elettronici e sistemi operativi (12 cfu), Segnali e sistemi (6 cfu), Modellistica e simulazione(6 cfu), Fondamenti di automatica (9 cfu), Algoritmi e strutture dati (6 cfu), Internet of things (9 cfu), Strumenti per la progettazione di UAV (6 cfu)",
        "Piano di studi 3": "Automazione industriale (6 cfu), Compatibilità elettromagnetica (6 cfu), Ricerca operativa (6 cfu), Controllo e programmazione di sistemi (6 cfu), Misure meccaniche e termiche (6 cfu), A scelta dello studente (18 cfu), Tirocinio (6 cfu), Prova finale (6 cfu)",
        "operatore 1": "eCampus",
        "operatore 2": "Comparacorsi",
        "operatore 3": "Altri operatori",
        "prezzo 1": "5.900€",
        "prezzo 2": "2.600€",
        "prezzo 3": "6.000€",
        "Domanda 1": "Quali sono gli obiettivi formativi principali dell'indirizzo Ingegneria delle App?",
        "Risposta 1": "L'obiettivo è formare ingegneri specializzati nella progettazione e sviluppo di applicazioni per dispositivi mobili, integrandole con le tecnologie di Internet of Things e smart cities.",
        "Domanda 2": "Quali conoscenze base sono necessarie per iniziare il corso in Ingegneria delle App?",
        "Risposta 2": "Una solida comprensione di matematica, fisica, e principi di informatica è raccomandata per sfruttare al meglio il programma di studi.",
        "Domanda 3": "Come vengono gestite le lezioni e le interazioni in questo corso online?",
        "Risposta 3": "Le lezioni e le interazioni studente-docente avvengono tramite piattaforme digitali, con videoconferenze, forum di discussione e materiali didattici interattivi.",
        "Domanda 4": "Il corso offre opportunità di lavoro di gruppo e progetti pratici?",
        "Risposta 4": "Sì, il corso include progetti di gruppo e laboratori virtuali per applicare la teoria in contesti pratici e realistici.",
        "Domanda 5": "È richiesta una conoscenza avanzata di programmazione per accedere al corso?",
        "Risposta 5": "Non è necessaria una conoscenza avanzata, ma una familiarità di base con la programmazione e i concetti di informatica è utile.",
        "Sbocchi": [
          "Sviluppatore di applicazioni mobili",
          "Specialista in Internet of Things (IoT)",
          "Ingegnere del software per smart cities",
          "Analista di sistemi e reti telematiche",
          "Consulente IT per soluzioni digitali innovative",
          "Progettista di sistemi di automazione",
          "Esperto in sicurezza informatica e protezione dei dati",
          "Tecnico di supporto e manutenzione per sistemi informatici",
          "Specialista in user experience (UX) e interfaccia utente (UI)",
          "Responsabile IT in aziende tecnologiche."
        ]
    },
    {
        "nome": "Ingegneria Informatica e dell’Automazione (Sistemi di Elaborazione e Controllo)",
        "Area": "Informatica",
        "H1": "Corso di Laurea in Sistemi di controllo ed elaborazione (Ingegneria informatica e dell'automazione), Università Telematica eCampus",
        "tag 1": "Laurea Triennale",
        "tag 2": "Durata: 3 anni",
        "tag 3": "180 cfu",
        "tag 4": "Anno accademico 2023/2024",
        "tag 5": "L-08",
        "Descrizione": "Il Corso di Laurea online in Ingegneria Informatica e dell'Automazione, indirizzo Sistemi di Controllo ed Elaborazione di eCampus, prepara professionisti qualificati per progettare, simulare, realizzare, gestire e mantenere sistemi hardware e software complessi. Questo indirizzo integra competenze legate al concetto di sistema di controllo, dispositivi avanzati, tecnologie e metodologie della teoria del controllo, informatica, ottimizzazione e telecomunicazioni. Gli studenti apprendono come applicare metodi matematici e scientifici, progettare componenti e sistemi, svolgere esperimenti e simulazioni, analizzare e interpretare dati. Attività pratiche come tirocini formativi completano il percorso accademico, preparando gli studenti ad affrontare con successo le sfide professionali nel campo dell'ingegneria informatica e dell'automazione.",
        "Piano di studi 1": "Analisi matematica (12 cfu), Fondamenti di informatica (12 cfu), Complementi di matematica (6 cfu), Elettrotecnica (9 cfu), Fisica (12 cfu), Analisi numerica (6 cfu), Lingua inglese (3 cfu)",
        "Piano di studi 2": "Calcolatori elettronici e sistemi operativi (12 cfu), Segnali e sistemi (6 cfu), Modellistica e simulazione(6 cfu), Fondamenti di automatica (9 cfu), Algoritmi e strutture dati (6 cfu), Elettronica dei Sistemi Digitali (9 cfu), Basi di Dati (6 cfu)",
        "Piano di studi 3": "Automazione industriale (6 cfu), Reti di Telecomunicazioni (6 cfu), Ricerca operativa (6 cfu), Ingegneria del Software (6 cfu), Misure meccaniche e termiche (6 cfu), A scelta dello studente (18 cfu), Tirocinio (6 cfu), Prova finale (6 cfu)",
        "operatore 1": "eCampus",
        "operatore 2": "Comparacorsi",
        "operatore 3": "Altri operatori",
        "prezzo 1": "5.900€",
        "prezzo 2": "2.600€",
        "prezzo 3": "6.000€",
        "Domanda 1": "Quali competenze specifiche acquisirò nel corso di studi in Ingegneria Informatica e dell'Automazione, indirizzo Sistemi di Elaborazione e Controllo?",
        "Risposta 1": "Questo corso fornisce competenze avanzate nella progettazione e gestione di sistemi hardware e software complessi, integrando conoscenze di teoria del controllo, informatica, ottimizzazione e telecomunicazioni.",
        "Domanda 2": "Come si integra la teoria con la pratica nel corso?",
        "Risposta 2": "Oltre allo studio teorico, il corso include esercitazioni pratiche, seminari, e progetti individuali o di gruppo. Le attività pratiche permettono di applicare le conoscenze teoriche in contesti reali, migliorando le capacità di problem solving e di lavoro di squadra.",
        "Domanda 3": "In quali settori posso lavorare dopo aver completato questo corso?",
        "Risposta 3": "I laureati possono trovare opportunità di lavoro in vari settori, inclusi la produzione hardware e software, automazione industriale e robotica, sistemi informativi e reti di calcolatori, servizi informatici pubblici, progettazione e realizzazione di infrastrutture telematiche.",
        "Domanda 4": "Quali metodi didattici vengono utilizzati nel corso?",
        "Risposta 4": "Il corso utilizza un mix di metodi didattici che comprendono lezioni teoriche, esercitazioni pratiche, laboratori telematici, seminari e progetti. Questo approccio fornisce una solida base teorica mentre sviluppa competenze pratiche essenziali.",
        "Domanda 5": "Come il corso prepara gli studenti per le sfide professionali nel settore?",
        "Risposta 5": "Attraverso un curriculum che combina conoscenze ingegneristiche e informatiche, il corso prepara gli studenti a affrontare complessi problemi tecnologici. I laureati saranno pronti a lavorare in ambienti dinamici e ad alta tecnologia.",
        "Sbocchi": [
          "Ingegnere informatico in aziende di produzione hardware e software.",
          "Specialista in automazione industriale e robotica.",
          "Tecnico di sistemi informativi e reti di calcolatori.",
          "Professionista in servizi informatici per la pubblica amministrazione.",
          "Progettista di infrastrutture telematiche.",
          "Esperto in sviluppo e gestione di sistemi di controllo automatico.",
          "Analista di sistemi per l'ottimizzazione di processi produttivi.",
          "Consulente tecnico in imprese di ingegneria elettronica e elettromeccanica.",
          "Sviluppatore di soluzioni software per il settore aeronautico e spaziale.",
          "Responsabile della sicurezza informatica in ambienti industriali complessi."
        ]
    },
    {
        "nome": "Ingegneria Informatica",
        "Area": "Informatica",
        "H1": "Laurea Triennale in Ingegneria Informatica - Unimarconi",
        "tag 1": "Laurea Triennale",
        "tag 2": "Durata: 3 anni",
        "tag 3": "180 cfu",
        "tag 4": "Anno accademico 2023/2024",
        "tag 5": "L-08",
        "Descrizione": "Il Corso di Laurea Triennale in Ingegneria Informatica dell'Università Telematica Guglielmo Marconi, classe L-8, offre una formazione ingegneristica avanzata e multidisciplinare, mirata allo sviluppo e all'utilizzo delle tecnologie dell'informatica. Un percorso distintivo che combina basi scientifiche solide in materie come matematica e fisica, con una preparazione concreta e specifica in informatica. Questo corso si distingue per il suo approccio all'ingegneria dell'informazione, integrando studi in elettronica e telecomunicazioni, oltre a competenze in ingegneria gestionale quali economia e organizzazione aziendale. Questo percorso formativo online offre una preparazione completa e flessibile, adatta a chi cerca una formazione ingegneristica di qualità nell'ambito dell'informatica.",
        "Piano di studi 1": "Matematica I – video lezione introduttiva (6 cfu), Matematica II – video lezione introduttiva (6 cfu), Fisica generale (12 cfu), Fondamenti di informatica (12 cfu), Reti logiche (6 cfu), Economia e organizzazione aziendale (6 cfu), Elettrotecnica (6 cfu), Idoneità linguistica: inglese, francese (6 cfu)",
        "Piano di studi 2": "Elettronica digitale (6 cfu), Matematica III – video lezione introduttiva (6 cfu), Calcolatori e sistemi operativi (12 cfu), Programmazione orientata agli oggetti (6 cfu), Reti e Internet (12 cfu), Gestione aziendale (6 cfu), Basi di dati e di conoscenza (12 cfu)",
        "Piano di studi 3": "Modelli di sistemi di produzione (6 cfu), Fondamenti di ingegneria del software (6 cfu), Linked data e Web semantico (6 cfu), a scelta tra: Sistemi di elaborazione delle informazioni (6 cfu), Metodi e strategie di comunicazione digitale (6 cfu), Scelta libera* (6+6 cfu), Ulteriori attività formative (18 cfu), Tesi di Laurea (6 cfu)",
        "operatore 1": "Unimarconi",
        "operatore 2": "Comparacorsi",
        "operatore 3": "Altri operatori",
        "prezzo 1": "2.760€",
        "prezzo 2": "1.500€",
        "prezzo 3": "3.200€",
        "Domanda 1": "Quali sono i requisiti di accesso per la laurea triennale in Ingegneria Informatica all'Università Telematica Unimarconi?",
        "Risposta 1": "Per iscriversi è necessario possedere un diploma di scuola superiore, con una preferenza per corsi orientati verso materie scientifiche e tecniche. Non è previsto un test di ingresso.",
        "Domanda 2": "Come si svolge l'apprendimento online nel corso di Ingegneria Informatica?",
        "Risposta 2": "Il corso si basa su una piattaforma di apprendimento online, offrendo lezioni virtuali, materiali di studio digitali e supporto costante da parte dei docenti.",
        "Domanda 3": "È possibile interagire con altri studenti nel corso di laurea telematico in Ingegneria Informatica?",
        "Risposta 3": "Sì, la piattaforma online favorisce la collaborazione e l'interazione tra studenti attraverso forum di discussione, gruppi di studio e progetti di gruppo.",
        "Domanda 4": "Quali sono le competenze principali che svilupperò con la laurea in Ingegneria Informatica?",
        "Risposta 4": "Si acquisiranno competenze in programmazione, analisi dei dati, sviluppo software, elettronica, telecomunicazioni e principi di ingegneria gestionale.",
        "Domanda 5": "Ci sono opportunità di tirocinio durante il corso di laurea in Ingegneria Informatica?",
        "Risposta 5": "L'università offre opportunità di tirocinio, aiutando gli studenti a applicare le competenze teoriche in contesti lavorativi reali, anche se non è un requisito obbligatorio del corso.",
        "Sbocchi": [
          "Sviluppatore Software",
          "Ingegnere di Sistema",
          "Analista di Sicurezza Informatica",
          "Progettista di Reti di Telecomunicazioni",
          "Consulente IT",
          "Esperto in Automazione e Robotica",
          "Ingegnere Hardware",
          "Analista di Big Data",
          "Programmatore di Sistemi Embedded",
          "Tecnico di Supporto e Manutenzione IT"
        ]
    },

]